import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Badged from '../HelperComponents/Badged/Badged';
import PopperInfo from '../HelperComponents/PopperInfo/PopperInfo';
import moment from 'moment';

import { getNotifications } from "../../actions/notificationsActions";
import { isArray } from "../../helpers/functions";

import NotificationIcon from '../../assets/image/empty_notif_icon.png';
import NotificationCommentIcon from '../../assets/image/notif_comment.png';
import NotificationReviewIcon from '../../assets/image/notif_review.png';
import NotificationStatusIcon from '../../assets/image/notif_status.png';

import './Notifications.scss';

import 'moment/locale/ru';
import {bindActionCreators} from "redux";
moment.locale('ru');

class Notifications extends Component {
    state = {
        anchorEl: null
    };

    componentDidMount() {
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        const { location } = this.props;
        if(location.pathname !== nextProps.location.pathname) {
            this.getData();
        }
    }

    getData = () => {
        const { getNotifications } = this.props;
        getNotifications();
    };

    handleClick = event => {
        const { currentTarget } = event;
        this.setState({
            anchorEl: currentTarget,
        });
    };

    clickAway = () => {
        this.setState({
            anchorEl: null,
        });
    };

    getNotification({id, unread, order, date, company, company_id}) {
        switch (unread) {
            case "comment":
                return (
                    <Link to={`/main/order/${order}`} className="notification_item_link">
                        <div className="notification_title_wrapper">
                            <img src={NotificationReviewIcon} alt="notification"/>
                            <p className="notification_title">Новый комментарий по заказу <span>#{order}</span></p>
                        </div>
                        <span className="notification_date">{moment(date).fromNow()}</span>
                    </Link>
                );
            case "status":
                return (
                    <Link to={`/main/order/${order}`} className="notification_item_link" key={id}>
                        <div className="notification_title_wrapper">
                            <img src={NotificationStatusIcon} alt="notification"/>
                            <p className="notification_title">Изменение статуса заказа <span>#{order}</span></p>
                        </div>
                        <span className="notification_date">{moment(date).fromNow()}</span>
                    </Link>
                );
            case "review_response":
                return (
                    <Link to={`/main/supplier-information/${company_id}`} className="notification_item_link" key={id}>
                        <div className="notification_title_wrapper">
                            <img src={NotificationCommentIcon} alt="notification"/>
                            <p className="notification_title">Ответ на Ваш отзыв о компании <span>«{company}»</span></p>
                        </div>
                        <span className="notification_date">{moment(date).fromNow()}</span>
                    </Link>
                );
            default:
                return null;
        }
    }

    render(){
        const { notifications:{notifications_list} } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <Fragment>
                <Badged
                    invisible={notifications_list.load && !isArray(notifications_list.list)}
                >
                    <IconButton
                        classes={{
                            root: 'notification_btn'
                        }}
                        onClick={this.handleClick}
                    >
                        <img src={NotificationIcon} alt="notifications"/>
                    </IconButton>
                </Badged>
                <div style={{zIndex: "10000"}}>
                    <PopperInfo
                        open={open}
                        anchorEl={anchorEl}
                        clickAway={this.clickAway}
                    >
                        <ul className="notification_list">
                            {
                                notifications_list.load ?
                                    isArray(notifications_list.list) ?
                                        notifications_list.list.map(notification => {
                                            return <li className="notification_item"
                                                       key={notification.id}
                                                       onClick={this.clickAway}
                                            >
                                                {this.getNotification(notification)}
                                            </li>
                                        })
                                        :
                                        <li className="notification_item">Нет уведомлений</li>
                                    :
                                    'loading'
                            }
                        </ul>
                    </PopperInfo>
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        notifications: state.notifications
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getNotifications
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);