import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateBasket, getTotalCount } from "../../../actions/basketActions";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import Shape from "../../../assets/image/Shape.png";
import Close from "../../../assets/image/Mask.png";
import Success from "../../../assets/image/success_icon.png";

import "./BuyButton.scss";

const classes = {
    root: 'favorites_dialog_root buy_error_dialog',
    paper:'paper_custom_dialog',
}

class BuyButton extends Component {
  state = {
    open_modal: false,
    success_buy: false
  };

  stateModal = () =>
    this.setState(prevState => ({ open_modal: !prevState.open_modal }));

  stateBuy = () => this.setState(prevState => ({ success_buy: true }));
  stateBuyFalse = () => this.setState(prevState => ({ success_buy: false }));

  controlBuy = () => {
    const { id, count, user: { profile }, updateBasket } = this.props;
    if (!!profile.id){
      updateBasket({[id]: count}).then(data => {
        if (data.payload.data.error) this.stateModal();
        else if (!data.payload.data.error) this.stateBuy();
      });
    } else this.writingLocal(id);
  };

  writingLocal = id => {
    let result = JSON.parse(localStorage.getItem("parts_cart"));
    if (result != null) {
      if(result[id] === this.props.amount){
          this.stateModal();
      }
      else {
        result[id] ? ++result[id] : result[id] = 1;
        localStorage.setItem("parts_cart", JSON.stringify(result));
        this.totalCount();
        this.stateBuy();
      }
    } else {
      localStorage.setItem("parts_cart", JSON.stringify({[id]: 1}));
      this.totalCount();
      this.stateBuy();
    }
  };

  totalCount = () => {
    let local_count = localStorage.getItem("total_count");
    let total = local_count != null ? ++local_count : 1;
    localStorage.setItem("total_count", total);
      this.props.getTotalCount();
  };

  render() {
      const cls = ["btn-buy", 'disabled'];
    return (
      <Fragment>
        <DialogComponent open={this.state.open_modal} close={this.stateModal} classes={classes}>
          <ErrorMessage stateModal={this.stateModal} />
        </DialogComponent>
        {this.state.success_buy ? (
          <SuccessMessage
            close={this.stateBuyFalse}
            message={this.props.message}
          />
        ) : null}
        <button className={cls.join(' ')} onClick={this.controlBuy} disabled={this.props.disabled} id={this.props.id}>
          <img src={Shape} alt="Search" /> КУПИТЬ
        </button>
      </Fragment>
    );
  }
}

export const ErrorMessage = ({ stateModal, type = "update" }) => {
  return (
    <div className="buy-error">
      <span onClick={stateModal}>
        <img src={Close} alt="Close" />
      </span>
      {type === "update" ? (
        <h1>Предложение по данному товару более недоступно</h1>
      ) : (
        <h1>Некоторые товары более недоступны</h1>
      )}
      <DefaultButton variant="contained" onClick={stateModal}>
        ok
      </DefaultButton>
    </div>
  );
};

export const SuccessMessage = ({ close, message}) => {
  setTimeout(close, 3000);
  return (
    <div className="info-success-buy">
      <div>
        <span>
          <img src={Success} alt="Success" />
        </span>
        {/*<p>Товар добавлен в корзину</p>*/}
        {message}
      </div>
      <span onClick={close} className="close">
        <img src={Close} alt="Close" />
      </span>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    basket: state.basket
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateBasket,
      getTotalCount
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyButton);
