import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import AuthReducer from './reduceAuth';
import UserReducer from './reducerUser';
import OrdersReducer from './reducerOrders';
import NotificationsReducer from './reducerNotifications';
import BasketReducer from './reducerBasket';
import CatalogReducer from './reducerCatalog';
import UpdateReducer from './reducerUpdate';
import {reducer as formReducer} from 'redux-form';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth: AuthReducer,
    user: UserReducer,
    orders: OrdersReducer,
    notifications: NotificationsReducer,
    basket: BasketReducer,
    catalog: CatalogReducer,
    update: UpdateReducer,
});

export default rootReducer;