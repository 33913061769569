import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './InfoRepresentation.scss';

class InfoRepresentation extends Component {

    render(){
        return (
            <div className="address-block">
                <div  className="address">
                    ул. Крещатик, 52, Киев, 02000
                    <IconButton>

                        <ExpandLessIcon/>
                        {/*<ExpandMoreIcon/>*/}
                    </IconButton>
                </div>
                <div className="info-supplier">
                    <div className="painting">
                        <div className="descriptions">График работы</div>
                        <div className="painting-day">
                            <span>Пн</span>
                            <p>07:00 - 19:00</p>
                        </div>
                        <div className="painting-day">
                            <span>Вт</span>
                            <p>07:00 - 19:00</p>
                        </div>
                        <div className="painting-day">
                            <span>Ср</span>
                            <p>07:00 - 19:00</p>
                        </div>
                        <div className="painting-day">
                            <span>Чт</span>
                            <p>07:00 - 19:00</p>
                        </div>
                        <div className="painting-day">
                            <span>Пт</span>
                            <p>07:00 - 19:00</p>
                        </div>
                        <div className="painting-day">
                            <span className="Output">Сб</span>
                            <p className="Output">Выходной</p>
                        </div>
                        <div className="painting-day">
                            <span className="Output">Вс</span>
                            <p className="Output">Выходной</p>
                        </div>
                    </div>
                    <div className="schedule_map_wrapper">
                        <YMaps>
                            <Map
                                width={530}
                                height={250}
                                defaultState={{ center: [55.75, 37.57], zoom: 9 }}
                            >
                                <Placemark />
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        //name: state.name
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //login
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoRepresentation);