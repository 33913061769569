import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import "./DashboardFooter.scss";
import LogoFootter from "../../../assets/image/logo_fotter.png";

class DashboardFooter extends Component {
    render() {
        // const { windowlocation } = this.props;
        return (
            <div className="dashboard-footer ">
                <div className="container">
                    <div className="footer">
                        <div className="footer-logo">
                            <img src={LogoFootter} alt="LogoFootter" />
                        </div>
                        <div className="nav-info">
                            <a href="https://supplier.mexcar.ru">Поставщикам</a>
                            <Link
                                to={`/main/about-project`}
                                className={
                                    window.location.pathname.split("/")[2] ===
                                    "about-project"
                                        ? "active"
                                        : ""
                                }
                            >
                                О проекте
                            </Link>
                            <Link
                                to={`/main/contact-details`}
                                className={
                                    window.location.pathname.split("/")[2] ===
                                    "contact-details"
                                        ? "active"
                                        : ""
                                }
                            >
                                Контактные данные
                            </Link>
                        </div>
                    </div>
                    <div className="info">
                        © Mexcar {new Date().getFullYear()}. Все права защищены.
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        //name: state.name
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            //login
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardFooter);
