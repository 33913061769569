import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from "../../HelperComponents/Loader/Loader";
import PartChart from "./PartChart";

import { getTreeNodeParts } from "../../../actions/catalogActions";

import './SpecificPart.scss';

class SpecificPart extends Component {

    state ={
        loading: true
    };

    componentDidMount() {
        const { getTreeNodeParts, match:{ params }} = this.props;
        const link = params.modelLink;
        const ssd = params.ssd;
        const group = params.group;

        getTreeNodeParts(link, ssd, group).then(res => {
            if ( res.payload && res.payload.status && res.payload.status === 200 ) {
                this.setState({ loading: false })
            }
        });
    }

    render(){
        const { match:{ params }, catalog:{ tree_node_parts }, history} = this.props;
        const { loading } = this.state;
        const id = params.catalog_code;
        const type = params.tab;
        const encodedSeria = params.seria;
        const encodedIndex = params.modifModel;
        const encodedName = params.name;
        const ssd = params.ssd;
        const modelLink = params.modelLink;
        const seria = decodeURIComponent(encodedSeria);
        const index = decodeURIComponent(encodedIndex);
        const name = decodeURIComponent(encodedName);

        if (loading || !tree_node_parts.parts) return <Loader/>;

        return (
            <div className="catalog-fifth-page">
                <div className="catalog-hierarchy">
                    <Link
                        to="/main"
                        className="catalog-link"
                    >
                        Каталог
                    </Link> &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}`}
                        className="catalog-link"
                    >
                        {name}
                    </Link> &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}/${encodedSeria}`}
                        className="catalog-link"
                    >
                        {seria.replace(name, '')}
                    </Link> &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}/${encodedSeria}/${encodedIndex}`}
                        className="catalog-link"
                    >
                        {index}
                    </Link> &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}/${encodedSeria}/${encodedIndex}/${ssd}/${modelLink}/${encodedName}`}
                        className="catalog-link"
                    >
                        {name} {seria.replace(name, '')} [{index}]
                    </Link> &nbsp;&nbsp;/&nbsp;&nbsp;

                    {tree_node_parts.parts.part_name}

                </div>

                <div className="title-wrapper">{name} {seria.replace(name, '')} {index}</div>
                <div className="model-info-wrapper">
                    {Object.values(tree_node_parts.model_info).map((info, idInfo) => (
                        <Fragment key={idInfo}>
                            {info !== name && info !== seria && info !== index && info !== "" && info !== undefined ?
                                <Fragment>
                                    <div className="model-info"> {info} </div>
                                    <span>•</span>
                                </Fragment>
                                : null
                            }
                        </Fragment>
                    ))}
                </div>
                <hr className="hr-line"/>

                <PartChart tree_node_parts = {tree_node_parts} history = {history} />

            </div>
        );
    }
}

const mapStateToProps = ({catalog}) => ({
    catalog
});

const mapDispatchToProps = {
    getTreeNodeParts
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecificPart);

