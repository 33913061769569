import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Preloader from "../../HelperComponents/Preloader/Preloader";
import Loader from "../../HelperComponents/Loader/Loader";

import { searchTreeNode } from "../../../actions/catalogActions";

import './PartsOfCar.scss';

class PartsComponent extends Component {

    state ={
        loading: true
    };

    componentDidUpdate(prevProps) {
        if (prevProps.path !== this.props.path) {
            this.doRequest();
        }
    }

    doRequest = () => {
        const { modelLink, ssd, path, leaf, searchTreeNode } = this.props;
        if (leaf === 1) {
            this.setState({ loading: true });
            searchTreeNode(modelLink, ssd, path).then(res => {
                if ( res.payload && res.payload.status && res.payload.status === 200 ) {
                    this.setState({ loading: false })
                }
            });
        }
    };


    render(){
        const { catalog:{ tree_node }, modelLink, ssd, node, leaf, nodeName, type, id, encodedSeria, encodedIndex, encodedName, handleClick } = this.props;
        const { loading } = this.state;

        if ( tree_node.tree === {} && leaf === 1) return <Preloader/>;

        return (
            <div className="catalog-parts-wrapper">
                { nodeName === '' ? null : <div className="title-catalog"> {nodeName} </div> }
                <div className="parts-icons">
                    {tree_node.tree && tree_node.tree !== {} && leaf === 1 ?
                        !loading ?
                            tree_node.tree.map((nodeTree, idNodeTree) => {
                                const chart = encodeURIComponent(nodeTree.node);
                                return (<Paper key={idNodeTree}>
                                        <Link
                                            to={`/main/catalogs/${type}/${id}/${encodedSeria}/${encodedIndex}/${ssd}/${modelLink}/${encodedName}/${chart}`}
                                            className="image-block"
                                            style={{background: `url(${nodeTree.image}) no-repeat`}}
                                        >
                                            {nodeTree.header.slice(-1) === ',' ?
                                                <span>{nodeTree.header.slice(0, -1)}</span>
                                                :
                                                <span>{nodeTree.header}</span>
                                            }
                                        </Link>
                                    </Paper>
                                )
                            }) :
                            <div className="loader-in-container-wrapper">
                                <Loader />
                            </div>
                        :
                        null
                    }
                    {Object.entries(node).map((treeItem, idItem) => (
                        <Paper key={idItem}>
                            <div className="detail-box" onClick={(e) => handleClick(treeItem[1].name)}>
                                <span>{treeItem[1].name}</span>
                            </div>
                        </Paper>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({catalog}) => ({
    catalog
});

const mapDispatchToProps = {
    searchTreeNode
};


export default connect(mapStateToProps, mapDispatchToProps)(PartsComponent);

