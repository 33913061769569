import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom';
import RenderField from '../../HelperComponents/RenderField/RenderField';
import DefaultButton from '../../Buttons/DefaultButton/DefaultButton';

import { postLogin, postLoginGoogle } from "../../../actions/authActions";

import IconGoogle from '../../../assets/image/icon-google.png'

class Login extends Component {
    state = {
        loading: false
    };

    componentWillMount() {
        sessionStorage.clear();
        localStorage.token = '';
    }

    signup = (response) => {
        const { postLoginGoogle, history } = this.props;
        let obj = {access_token: response.accessToken};
        postLoginGoogle(obj).then((res) => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.token = res.payload.data.key;
                history.push('/main');
            } else {
                this.setState({loading: false});
            }
        });
    };

    submitForm = data => {
        const { postLogin, history } = this.props;
        this.setState({loading: true});
        postLogin(data).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.token = res.payload.data.token;
                history.push('/main');
            } else {
                this.setState({loading: false});
                // throw new SubmissionError({...res.error.response.data, _error: res.error.response.data.detail});
            }
        });
    };

    render(){
        const { handleSubmit, submitting, pristine, valid, auth: { error_auth } } = this.props;
        const { loading } = this.state;
        const responseGoogle = (response) => {
            this.signup(response);
        };
        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.submitForm)}  className="auth_content_form">
                    <h3 className="auth-block_head">Вход в аккаунт</h3>
                    <Field name="login" type="text" component={RenderField} label="Электронный адрес"/>
                    <Field name="password" type="password" component={RenderField} label="Пароль" password/>
                    <DefaultButton
                        variant="contained"
                        classes="full_btn_width"
                        disabled={submitting || pristine || !valid}
                        loading={loading}
                        formAction
                    >
                        Войти
                    </DefaultButton>
                    <div className="default_button_wrapper default_button_wrapper_relative">
                        <GoogleLogin
                             // clientId="404485370777-o5fvauqq0j5o5p3niohcuuno59252727.apps.googleusercontent.com"
                            clientId="507960300312-6v4bcqnba0i24lgpuuk7vd2h4dstjdb2.apps.googleusercontent.com"
                            render={renderProps => (
                                <button onClick={renderProps.onClick} className="default_button full_btn_width google-btn">
                                    <img src={IconGoogle} alt="IconGoogle"/><span>Войти с помощью Google</span>
                                </button>
                            )}
                            buttonText="Войти с помощью Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                    {error_auth && Object.keys(error_auth).length !== 0  && error_auth.hasOwnProperty("error") ?
                        null
                        :
                        error_auth && Object.keys(error_auth).length !== 0 ?
                            <div className="auth_error error_after_button">
                                <span>{error_auth[Object.keys(error_auth)[0]]}</span>
                            </div>
                            :
                            null
                    }
                </form>
                <Link
                    to="/auth/password-recovery"
                    className="auth_link"
                >
                    Забыли пароль?
                </Link>
            </Fragment>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.login) {
        errors.login = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.login)) {
        errors.login = 'Invalid email'
    }
    if (!values.password) {
        errors.password = 'Required'
    } else if (values.password.length < 8) {
        errors.password = 'Must be 8 characters or more'
    }
    return errors
};

Login = reduxForm({
    form: 'LoginForm',
    validate
})(Login);

function mapStateToProps(state) {
    return{
        auth: state.auth
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postLogin,
        postLoginGoogle,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);