import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { getStatusColor, getStatus } from "../../../helpers/functions";

import ClockIcon from "../../../assets/image/time.png";
import Chevron from "../../../assets/image/chevron.png";
import Rating from "../../HelperComponents/Rating/Rating";

import "./OrderItem.scss";

const OrderItem = ({ data, history }) => {
    let color = getStatusColor(data.status);

    return (
        <div
            className="dashboard_block order-item"
            onClick={() => {
                history.push(`/main/order/${data.id}/`);
            }}
        >
            <div className="status_block" style={{ backgroundColor: color }} />
            <div className="flex-center-btw">
                <p className="date_wrapper">
                    <img src={ClockIcon} alt="clock" />
                    {moment(data.date).format("DD.MM.YYYY HH:mm")}
                </p>
                <p className="status_title">
                    <span style={{ backgroundColor: color }} />
                    {getStatus(data.status)}
                </p>
            </div>
            <div className="order_title">
                <Link to={`/main/orders/${data.id}`}>
                    <h5>{data.id}</h5>
                </Link>
                {data.orderreviews > 0 ? (
                    <div
                        className="comment"
                        style={{ backgroundColor: "#48AAF4" }}
                    >
                        {data.orderreviews}
                    </div>
                ) : null}
            </div>
            <div className="block_info_item">
                <span className="block_label">Поставщик</span>
                <span className="block_value">{data.company}</span>
            </div>
            <div className="reviews-wrapper reviews-orders">
                <Rating
                    value={data.company_rating}
                    rating_info
                    ratingSize={10}
                />
                <div className="commends">
                    {data.total_reviews} отзыв
                    {Number(data.total_reviews) === 1
                        ? null
                        : Number(data.total_reviews) > 1 &&
                          Number(data.total_reviews) < 5
                        ? "a"
                        : "ов"}
                </div>
            </div>
            <div className="block_info_item">
                <span className="block_label">
                    Товар{data.orderproducts.length > 1 ? "ы" : null}
                </span>
                <div
                    className="flex-center-btw handler-hover-area"
                    style={
                        data.orderproducts.length < 2
                            ? { justifyContent: "space-between" }
                            : null
                    }
                >
                    <p className="block_value">
                        {data &&
                            data.orderproducts &&
                            data.orderproducts.length > 0 &&
                            data.orderproducts[0].name}
                    </p>
                    <div
                        className="block_value_currency"
                        style={
                            data.orderproducts.length < 2
                                ? { justifyContent: "flex-end" }
                                : null
                        }
                    >
                        {data.orderproducts.length > 1 ? (
                            <p className="block_value_all_wrapper">
                                <span>
                                    и еще {data.orderproducts.length - 1}{" "}
                                    <img src={Chevron} alt="Chevron" />{" "}
                                </span>
                            </p>
                        ) : (
                            data &&
                            data.orderproducts &&
                            data.orderproducts.length > 0 &&
                            data.orderproducts[0].price + " ₽"
                        )}
                    </div>
                    {data.orderproducts.length > 1 ? (
                        <div className="block_value_all">
                            {data.orderproducts.map((el, i) => (
                                <p key={"itemOrder" + el.customer_name + i}>
                                    <span>
                                        <i>{el.name}</i>
                                        <i>
                                            {el.producer}, {el.quantity} шт.
                                        </i>
                                    </span>
                                    <span>
                                        {data &&
                                            data.orderproducts &&
                                            data.orderproducts.length > 0 &&
                                            data.orderproducts[0].price}{" "}
                                        ₽
                                    </span>
                                </p>
                            ))}
                        </div>
                    ) : null}
                </div>
                <span className="block_sub_value">
                    {data &&
                        data.orderproducts &&
                        data.orderproducts.length > 0 &&
                        data.orderproducts[0].producer}
                    ,{" "}
                    {data &&
                        data.orderproducts &&
                        data.orderproducts.length > 0 &&
                        data.orderproducts[0].quantity}{" "}
                    шт.
                </span>
                {/*{data.orderproducts.length === 1 ?*/}
                {/*<Fragment>*/}
                {/*<div className="flex-center-btw">*/}
                {/*<p className="block_value">data.orderproducts[0]</p>*/}
                {/*<p className="block_value_currency">163.00 ₽</p>*/}
                {/*</div>*/}
                {/*<span className="block_sub_value">Toyota, 3 шт.</span>*/}
                {/*</Fragment>*/}
                {/*:*/}
                {/*<Fragment>*/}
                {/*<div className="flex-center-btw">*/}
                {/*<p className="block_value">Свеча зажигания</p>*/}
                {/*<p className="block_value_currency">163.00 ₽</p>*/}
                {/*</div>*/}
                {/*<span className="block_sub_value">Toyota, 3 шт.</span>*/}
                {/*</Fragment>*/}
                {/*}*/}
            </div>
            <hr />
            <div className="order_bottom_info">
                <div>
                    <span className="block_label">Всего</span>
                    <span className="block_sub_value">
                        {data.orderproducts.length} товар
                        {data.orderproducts.length === 1
                            ? null
                            : data.orderproducts.length > 1 &&
                              data.orderproducts.length < 5
                            ? "a"
                            : "ов"}
                    </span>
                </div>
                <p className="block_value_currency">{data.total} ₽</p>
            </div>
        </div>
    );
};

export default OrderItem;
