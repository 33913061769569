import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import CompareOffers from '../OffersPage/CompareOffers/CompareOffers';
import FiltersOffers from '../OffersPage/FiltersOffers/FiltersOffers';
import TableOffers from '../OffersPage/TableOffers/TableOffers';
import {getPartDetail} from "../../actions/userActions";

import './OffersPage.scss';
import Preloader from "../HelperComponents/Preloader/Preloader";

class OffersPage extends Component {
    state = {
        position: {},
        distance: 100,
        valueSearch: false,
        stateMainSearch: null,
        filteredMainSearch: null,
        dataFilter: {
            price: {getMin: '', getMax: '', setMin: false, setMax: 0},
            delivery_day: {getMin: '', getMax: '', setMin: false, setMax: 0},
            amount: {getMin: '', getMax: '', setMin: false, setMax: 0},
        },
    };

    componentDidMount = () => {
        const { user: {profile}} = this.props;
        if (!!profile.id && !!profile.location) this.setState({position: profile.location});
        else this.userLocation();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {position, distance, stateMainSearch, dataFilter} = this.state;
        if (prevState.position !== position || prevState.distance !== distance) this.fetchData();
        if (prevState.stateMainSearch !== stateMainSearch) this.getMaxMinFilter();
        if (prevState.dataFilter !== dataFilter) this.getDataFilter();
    }

    fetchData = () => {
        const {position, distance} = this.state;
        const {getPartDetail, match: {params}} = this.props;
        let data = `?latitude=${position.latitude}&longtitude=${position.longitude}&distance=${distance}&ordering=-distance`;
        getPartDetail(params.articul, data).then((res) => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({
                    stateMainSearch: res.payload.data
                });
            }
        });
    }

    userLocation = () => {
        this.setState({position: JSON.parse(localStorage.location)});
        // navigator.geolocation.getCurrentPosition(
        //     (position) => this.setState({
        //         position: {
        //             latitude: position.coords.latitude, longitude: position.coords.longitude
        //         }
        //     }),
        //     (error) => this.setState({
        //         position: {
        //             latitude: 55.753960, longitude: 37.620393
        //         }
        //     })
        // );
    };

    getMaxMinFilter = () => {
        const {stateMainSearch, dataFilter} = this.state;
        const {offer_products, analog_products} = stateMainSearch;
        let newValueFilter = {...dataFilter};
        if (offer_products.length !== 0 && analog_products.length !== 0) {
            let list = [];
            list.push([...offer_products]);
            list.push(...analog_products.map(val => val.offer_products));
            Object.keys(newValueFilter).map(key => {
              newValueFilter[key].setMax = list.flat().reduce((x,y) => (x > y[key]) ? x : y[key]);
              newValueFilter[key].setMin = list.flat().reduce((x,y) => (x < y[key]) ? x : y[key]);
              return newValueFilter;
            });
        } else {
            newValueFilter.price = {getMin: '', getMax: '', setMin: 1, setMax: 9999999};
            newValueFilter.delivery_day = {getMin: '', getMax: '', setMin: 1, setMax: 99};
            newValueFilter.amount = {getMin: '', getMax: '', setMin: 1, setMax: 9999};
        }
        this.setState({dataFilter: newValueFilter});
    }

    getDataFilter = () => {
        const {stateMainSearch: {offer_products, analog_products}} = this.state;
        let main_list = [], analog_list = [];
        main_list = offer_products.filter(value => this.filters(value));

        analog_products.map((value, key) => {
            analog_list[key] = {...analog_products[key], offer_products: []};
            analog_list[key].offer_products = analog_products[key].offer_products.filter(val => this.filters(val));
            return analog_list;
        })
        this.updateFilteredList(main_list, analog_list);
    }

    filters = (data) => {
        const {dataFilter} = this.state;
        let check = true;
        Object.keys(dataFilter).map((key) => {
            if (check) {
                if (dataFilter[key].getMin === '' && dataFilter[key].getMax === '') check = true;
                else if (dataFilter[key].getMin <= data[key] && data[key] <= dataFilter[key].getMax) check = true;
                else if (dataFilter[key].getMin === '' && data[key] <= dataFilter[key].getMax) check = true;
                else if (dataFilter[key].getMax === '' && dataFilter[key].getMin <= data[key]) check = true;
                else check = false;
            }
            return dataFilter;
        })
        return check;
    }

    updateFilteredList = (main_list, analog_list) => {
        const {stateMainSearch} = this.state;
        const newList = {...stateMainSearch};
        newList.offer_products = main_list;
        newList.analog_products = analog_list;
        this.setState({filteredMainSearch: newList});
    }

    setMaxMinFilter = (type, value) => {
        const {dataFilter} = this.state;
        const data = {...dataFilter};
        data[type].getMin = value.min;
        data[type].getMax = value.max;
        this.setState({dataFilter: data});
    }

    newDistance = (distance) => this.setState({distance: distance});

    search = (value) => {
      const updateValue = (value.toLowerCase().trim().length !== 0) ? value.toLowerCase().trim() : false;
      this.setState({valueSearch: updateValue});
    }

    render(){
      const { user } = this.props;
      const { filteredMainSearch, dataFilter, valueSearch} = this.state;
      if (filteredMainSearch === null) return <Preloader/>;
      return (
          <div className="container offers-page">
              <CompareOffers user={user}/>
              <div className="offers-block">
                  <FiltersOffers data={filteredMainSearch} dataFilter={dataFilter} setMaxMinFilter={this.setMaxMinFilter} search={this.search}
                                  filteredMainSearch={filteredMainSearch}/>
                  <TableOffers data={filteredMainSearch} updateDistance={this.newDistance} distance={this.state.distance} valueSearch={valueSearch}/>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPartDetail
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OffersPage);
