import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {Link} from "react-router-dom";
import moment from 'moment';

import Rating from '../../HelperComponents/Rating/Rating';
import DialogComponent from '../../HelperComponents/DialogComponent/DialogComponent';

import {getSupplierInfo} from '../../../actions/ordersActions';
import {updateBasket} from '../../../actions/basketActions';

import Time from '../../../assets/image/time.png';

import ModalMap from '../../BasketPage/DialogModalRender';
import BuyButton from '../../Buttons/BuyButton/BuyButton';

const classes = {
    root: 'favorites_dialog_root',
    paper:'paper_custom_dialog'
}

class ItemOffset extends Component{
  state = {
    open_modal: false,
    chosenLocation: null
  }

  stateModal = () => this.setState(({open_modal}) => ({open_modal: !open_modal}));

  render(){
    const {product, key} = this.props;
    const {id, company, distance, amount, price, date, company_id,
            avg_company_rating, company_reviews, delivery_day, address} = product;
    return(
      <React.Fragment>
        <DialogComponent open={this.state.open_modal} close={this.stateModal} classes={classes} style={{height: '540px'}}>
            <ModalMap id={company_id} address={address} onClose={this.stateModal} />
        </DialogComponent>
        <div className="table-row">
            <div className="product">
                <Link to={`/main/supplier-information/${company_id}?offers=${document.location.pathname.split('/')[3]}`} className="name">
                    {!company ? null : company}
                </Link>
                <div className="reviews-wrapper">
                  {!avg_company_rating ? null : <Rating value={avg_company_rating} rating_info/>}
                  <span className="review">{!company_reviews ? null : `${company_reviews} отзыва`}</span>
                </div>
            </div>
            <div className="time">
                <img src={Time} alt="time"/>
                {moment(date).startOf('hour').fromNow()}
            </div>
            {(!distance) ? null:
              <div className="distance">
                <span>Растояние</span>
                <span>{distance.toFixed(1)} км</span>
              </div>
            }
            <div className="availability">
                <span>Наличие</span>
                {
                    amount !== 0 ?  <span>{amount} шт.</span> : <span>Нет в наличии</span>
                }
            </div>
            <div className="delivery-time" onClick={this.stateModal}>
                <span>Срок поставки</span>
                <span>{delivery_day ? `${delivery_day} дн.` : "В наличии"} </span>
            </div>
            <div className="price">{price}<span> ₽</span></div>
            <div>
              <BuyButton id={id} count={1} amount={amount} key={key} message="Товар добавлен в корзину" disabled={amount !== 0 ? null : 'disabled'}/>
            </div>
          </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
    return{
        orders: state.orders,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      updateBasket,
      getSupplierInfo
        //login
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemOffset);
