import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import RenderField from "../../../HelperComponents/RenderField/RenderField";
import DefaultButton from '../../../Buttons/DefaultButton/DefaultButton';

import { postResetPasswordSecond } from "../../../../actions/authActions";


class SecondStep extends Component {
    state = {
        success: false,
        loading: false
    };

    submitForm = data => {
        const { postResetPasswordSecond, location:{search} } = this.props;
        let params = new URLSearchParams(search.substring(1));
        let obj = {
            user_id: params.get('user_id'),
            timestamp: params.get('timestamp'),
            signature: params.get('signature'),
            password: data.password
        };
        this.setState({loading: true});
        return postResetPasswordSecond(obj).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({success: true});
            } else {
                this.setState({loading: false});
                throw new SubmissionError({...res.error.response.data});
            }
        });
    };

    render(){
        const { handleSubmit, location:{search}, submitting, pristine, valid} = this.props;
        const { success, loading } = this.state;
        if(search !== '') return (
            <div>
                {!success ?
                    <form onSubmit={handleSubmit(this.submitForm)}>
                        <h3 className="auth-block_head">Восстановление пароля</h3>
                        <Field name="password" type="password" component={RenderField} label="Новый пароль" autoFocus/>
                        <Field name="repeat_password" type="password" component={RenderField} label="Подтвердите пароль"/>
                        <DefaultButton
                            variant="contained"
                            classes="full_btn_width"
                            disabled={submitting || pristine || !valid}
                            loading={loading}
                            formAction
                        >
                            Сменить пароль
                        </DefaultButton>
                    </form>
                    :
                    <div className="auth-block_wrapper form_start">
                        <h3 className="auth-block_head">Восстановление пароля</h3>
                        <p>Ваш пароль успешно восстановлен! <br/> Теперь вы можете войти в свою учетную запись.</p>
                        <DefaultButton
                            variant="contained"
                            classes="full_btn_width"
                            type="link"
                            to="/auth"
                        >
                            Войти
                        </DefaultButton>
                    </div>
                }
            </div>

        );
        else return (
            <div className="auth-block_wrapper form_start">
                <h3 className="auth-block_head">Восстановление пароля</h3>
                <p>Мы отправили Вам письмо с ссылкой для смены пароля на Ваш электронный адрес</p>
            </div>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Required'
    } else if (values.password.length < 8) {
        errors.password = 'Must be 8 characters or more'
    }
    if (!values.repeat_password) {
        errors.repeat_password = 'Required'
    } else if (values.repeat_password.length < 8) {
        errors.repeat_password = 'Must be 8 characters or more'
    } else if (values.password !== values.repeat_password) {
        errors.repeat_password = 'Passwords do not match'
    }
    return errors
};

SecondStep = reduxForm({
    form: 'SecondStepForm',
    validate
})(SecondStep);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postResetPasswordSecond
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(SecondStep);