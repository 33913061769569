import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import Preloader from "../../HelperComponents/Preloader/Preloader";

import { getCatalogModel } from "../../../actions/catalogActions";

import './ChoosingModification.scss';

class ChoosingModification extends Component {

    state ={
        loading: true
    };

    componentDidMount() {
        const { getCatalogModel, match:{ params }} = this.props;
        let id = params.catalog_code;
        let type = params.tab;
        getCatalogModel(id, type).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200 ) {
                this.setState({ loading: false });
            }
        })
    }

    render(){
        const { match:{ params }, catalog: {families}} = this.props;
        const { loading } = this.state;
        const id = params.catalog_code;
        const type = params.tab;
        const encodedSeria = params.seria;
        const seria = decodeURIComponent(encodedSeria);
        let varChar = '';

        if (loading) return <Preloader/>;
        const name = families.catalog.name;

        return (
            <div className="catalog-second-page">
                <div className="catalog-hierarchy">
                    <Link
                        to="/main"
                        className="catalog-link"
                    >
                        Каталог
                    </Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}`}
                        className="catalog-link"
                    >
                        {name}
                    </Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    {seria.replace(name, '')}
                </div>
                <div className="title-wrapper">Выберите модификацию</div>
                <div className={`${Object.keys(families.families).length < 50 ? "catalog-fp" : "catalog-fp-big"}`}>
                    {families.families[seria].models.map((modif, idx) => {
                        let encodedModif = encodeURIComponent(modif.model);
                        if(modif.model[0] !== varChar){
                            varChar = modif.model[0];
                            return (
                                <Link
                                    className="catalog-info"
                                    to={`/main/catalogs/${type}/${id}/${encodedSeria}/${encodedModif}`}
                                    key={idx}
                                >
                                    <p>{modif.model}</p>
                                    <div className="letter">{modif.model[0]}</div>
                                </Link>
                            )
                        } else {
                            return (
                                <Link
                                    className="catalog-info"
                                    to={`/main/catalogs/${type}/${id}/${seria}/${encodedModif}`}
                                    key={idx}
                                >
                                    <p>{modif.model}</p>
                                </Link>
                            )
                        }
                    })
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        catalog: state.catalog
        //name: state.name
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCatalogModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoosingModification);