import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Redirect} from "react-router-dom";

import AllList from '../../components/FavoritesPage/AllList';
// import FavoritesItem from '../../components/FavoritesPage/FavoritesItem';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import Preloader from "../HelperComponents/Preloader/Preloader";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import DialogComponentForm from "../HelperComponents/DialogComponentForm/DialogComponentForm";
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock';

import AddIcon from '../../assets/image/add.png';
import CloseDialogIcon from '../../assets/image/Mask.png';

import { getListnameChosenProducts, postListnameChosenProducts, changeListnameChosenProducts,
    getChosenProducts,  postNewChosenProduct, patchComment, deleteSelectedItemFromFavorite,
    deleteListnameChosenProducts}
    from '../../actions/userActions';
import { isArray } from "../../helpers/functions";

import Button from '@material-ui/core/Button';
import './FavoritesPage.scss';
import DialogFavorites from "../HelperComponents/DialogFavorites/DialogFavorites";

const classes = {
    root: 'favorites_dialog_root',
    paper: "paper_custom_dialog",
}

class FavoritesPage extends Component {

    state = {
        open: false,
        openFavorites: false,
        openComment: false,
        openChangeFavorites: false,
        openDeleteFavorites: false,
        loading: false,
        tab: 0,
        idComment: 0,
        objProduct: {},
        count: null,
        emptyForm: false,
        activeList: 0,
        updateList: false,
        updateMileage: false,
        errorMessage: ''
    };

    componentDidMount = () => {
        if(!!this.props.user.profile.id){
            this.update();
        }
    };

    componentDidUpdate = (prevProps,prevState) => {
        // const {user: {favorite_lists}} = this.props;
        if(this.state.loading) this.update();
        if(prevState.open !== this.state.open && this.state.open) this.setState({updateList: false})
    }

    update = () => {
        const {getListnameChosenProducts} = this.props;
        getListnameChosenProducts();
        this.setState({loading: false});
    };

    activeList = (id) => {
        localStorage.removeItem("listNumber");
        this.setState({activeList: id});
    };

    createList = (id) => {
        const { getChosenProducts, history } = this.props;
        getChosenProducts(id).then(res => {
            if (this.props.location.state) {
                this.setState({tab: this.props.location.state.tab})
            } else {
                if(res.payload && res.payload.status && res.payload.status === 200) {
                    if(res.payload.data.length === 0) this.setState({tab: res.payload.data});
                    else this.setState({tab: res.payload.data[0].id})
                } else {
                    this.setState({tab: []})
                }
                if(res.error && res.error.response.status === 401) {
                    localStorage.clear();
                    history.push('/auth');
                }
            }
        });
    }

    changeTab = tab => {
        const {getChosenProducts} = this.props;
        getChosenProducts(tab);
        this.setState({tab});
    };

    toggleDialog = (product) => {
        this.setState(({open, idComment}) => ({
            open: !open,
            idComment: !!product.id ? product.id : 0,
            objProduct: !!product ? product : {}
        }));
    };

    toggleAllItems = (count) => {
        this.setState({open: true, count: count});
    };

    toggleListsDialog = () => {
        this.setState(({openDeleteFavorites}) => ({
            openDeleteFavorites: !openDeleteFavorites
        }));
    };

    openDialogFavorites = () => {
        this.setState(({openFavorites}) => ({
            openFavorites: !openFavorites
        }));
    };

    deleteItem = () => {
        const { deleteSelectedItemFromFavorite } = this.props;
        const { idComment } = this.state;
        deleteSelectedItemFromFavorite(idComment).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 204) {
                this.setState(({open}) => ({open: !open, updateList: true}));
            }
        });
    };


    // ***************   COMMENTS   ***************

    openDialogComment = (product) => {
        this.setState(({openComment, idComment}) => ({
            openComment: !openComment,
            idComment: !!product.id ? product.id : 0,
            objProduct: !!product ? product : {}
        }));
    };

    closeDialogComment = () => {
        this.setState(({openComment}) => ({
            openComment: !openComment,
        }));
    };

    changeComment = data => {
        const { patchComment } = this.props;
        const { idComment } = this.state;
        let id = idComment;
        let objComment = {
            "comment" : data.comment
        };
        patchComment(id, objComment).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({
                    openComment: false
                });
            }
        });
    };

    // ***************   CHANGE FAVORITE LIST   ***************

    openDialogChangeFavoriteList = () => {
        const {user} = this.props;
        const page = (this.state.activeList === null) ? 0 : this.state.activeList;
        this.setState(({openChangeFavorites}) => ({
            openChangeFavorites: !openChangeFavorites,
            updateMileage: (user.favorite_lists[page].mileage != null)
        }));
    };

    deleteFavoriteList = () => {
        this.setState(({openDeleteFavorites}) => ({
            openDeleteFavorites: !openDeleteFavorites
        }));
        const { deleteListnameChosenProducts, user } = this.props;
        let idList = Number(user.favorite_lists[this.state.activeList].id);
        deleteListnameChosenProducts(idList).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 204) {
                this.setState(({openDeleteFavorites, loading}) => ({
                    loading: !loading,
                    activeList: 0
                }));
            }
        });
    };

    submitForm = (data) => {
        const { postListnameChosenProducts } = this.props;
        this.setState({ loading: true });
        let obj = {
            name: data.name,
            mileage: data.mileage,
            vin: data.vin //TODO
        };
        postListnameChosenProducts(obj).then(res => {
            if (res.payload && res.payload.status === 201) {
                this.setState({
                    openFavorites: false,
                    loading: false,
                });
            }
        });
    };

    submitFormList = data => {
        if(!data.name && !data.mileage) this.setState({emptyForm: true, errorMessage: 'Заполните форму!'});
        else {
            this.setState({emptyForm: false, errorMessage: ''});
            const { changeListnameChosenProducts, user } = this.props;
            let id = Number(user.favorite_lists[this.state.activeList].id);
            let obj = {
                name: data.name,
                mileage: (data.mileage === "") ? null : data.mileage
            };
            changeListnameChosenProducts(id, obj).then(res => {
                if (res.payload && res.payload.status === 200) {
                    this.setState(({loading, openChangeFavorites}) => ({
                        openChangeFavorites: !openChangeFavorites,
                        loading: !loading,
                    }));
                }
            });
        }
    };

    stateMileage = (value) => this.setState({updateMileage: (value.length !== 0)})

    render(){
        const { loading, tab, objProduct, activePage, activeList, updateMileage,
            open, openFavorites, openComment, openChangeFavorites, openDeleteFavorites} = this.state;
        const { user, history } = this.props;
        let page = (activeList === null) ? 0 : activeList;
        if (localStorage.listNumber) {
            page = Number(localStorage.listNumber);
        }
        let currentTab = Number(tab);
        if (loading) return <Preloader/>;
        if(!user.profile.id) return <Redirect to="/main" />
        return (
            <TransitionedBlock>
                <div className="container favorites-page">
                    <div className="title">Избранное</div>
                    <div className="favorites">
                        <div className="favorites-tabs">
                            <div className="tabs-block">
                                <div className="title-list-favorites scrollbar scrollbar-primary">
                                    {(!isArray(user.favorite_lists)) ? null :
                                        user.favorite_lists.map((list, i) => {
                                            return(
                                                <span className="tab-button" key = {i}>
                                      <button className={`${page === i ? 'active' : ''}`}
                                              onClick={() => this.activeList(i)}>{list.name}</button>
                                    </span>
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    <Button
                                        onClick={this.openDialogFavorites}
                                        disableRipple ={true}
                                        classes={{
                                            root: 'add_btn'
                                        }}
                                    >
                                        Добавить
                                        <img src={AddIcon} alt="edit icon"/>
                                    </Button>
                                </div>
                            </div>
                            {(!isArray(user.favorite_lists)) ? null :
                                <AllList openDialogFavorites={this.openDialogFavorites} openDialogChangeFavoriteList={this.openDialogChangeFavoriteList}
                                         openDialogComment={this.openDialogComment} toggleDialog={this.toggleDialog} toggleListsDialog={this.toggleListsDialog}
                                         currentTab={currentTab} update={this.update} activePage={activePage} updateList={this.state.updateList} page={page}
                                         activeList={user.favorite_lists[page] && user.favorite_lists[page].id} additionalInfo={user.favorite_lists[page]}
                                         history={history}
                                />
                            }
                        </div>
                    </div>

                    <DialogComponent
                        style={{height: '800px'}}
                        open={false}
                        onClose={this.toggleDialog}
                        classes={classes}
                    >
                        <div className="dialog-delete-wrapper">
                            <div className="title">Удалить выбранные товары</div>
                            <div className="descriptions">Вы уверены, что хотите удалить {this.state.count} выбранных товара из избранных?</div>
                            <div className="dialog-button-wrapper">
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={this.toggleDialog}
                                >
                                    ОТМЕНА
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.toggleDialog}
                                >
                                    УДАЛИТЬ
                                </DefaultButton>

                            </div>
                        </div>
                        <span className="dialog_favorite_close_button" onClick={this.toggleDialog}>
                          <img className="icon_close" src={CloseDialogIcon} alt="close icon"/>
                        </span>
                    </DialogComponent>

                    <DialogFavorites
                        openFavorites={openFavorites}
                        openDialogFavorites={this.openDialogFavorites}
                        onSubmitAction={this.submitForm}
                        loading={loading}
                    />

                    {!!user.favorite_lists[page] ?
                        <DialogComponent classes={classes}
                                         style={{height: '415px'}}
                                         open={openChangeFavorites}
                                         onClose={this.openDialogChangeFavoriteList}
                        >
                            <div className="favorites-dialog">
                                <DialogComponentForm onSubmitAction={this.submitFormList} form="DialogFormList" initialValues={{name: user.favorite_lists[page].name, mileage: user.favorite_lists[page].mileage}}>
                                    <div className="title">Изменить список</div>
                                    <div className="block-info">
                                        <div className="textarea-block-update">
                                            <div className="name-update-list-favorites">
                                                <span>Название автомобиля</span>
                                                <Field name="name"  rows='2' component="input" type="text" />
                                            </div>
                                            <div className="mileage-update-list-favorites">

                                                {updateMileage ? <span>Пробег</span> : null}
                                                <div className="input-mileage">
                                                    <Field name="mileage"  rows='2' component="input" type="number" placeholder="Пробег" onChange={(e) => this.stateMileage(e.target.value)}/>
                                                    <span>км</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dialog-button-wrapper">
                                        <DefaultButton
                                            variant="outlined"
                                            classes="cancel_btn"
                                            onClick={this.openDialogChangeFavoriteList}
                                        >
                                            ОТМЕНА
                                        </DefaultButton>
                                        <DefaultButton
                                            variant="contained"
                                            onClick={this.changeFavoriteList}
                                            formAction
                                        >
                                            СОХРАНИТЬ
                                        </DefaultButton>
                                        {!!user.error_text && !!user.error_text.name ? <span className="error-text">{user.error_text.name[0]}</span> : null}
                                        {(this.state.emptyForm) ? <span className="error-text">{this.state.errorMessage}</span> : null}
                                    </div>
                                </DialogComponentForm>
                            </div>
                            <span className="dialog_favorite_close_button" onClick={this.openDialogChangeFavoriteList} >
                              <img className="icon_close" src={CloseDialogIcon} alt="close icon"/>
                            </span>
                        </DialogComponent>
                        :
                        null
                    }
                    {(!!user.favorite_lists[page]) ?
                        <DialogComponent classes={classes}
                                         style={{height: '415px'}}
                                         open={openComment}
                                         onClose={this.openDialogComment}
                        >
                            <div className="favorites-dialog">
                                <DialogComponentForm onSubmitAction={this.changeComment} form="DialogComment" initialValues={{comment: objProduct.comment}}>
                                    <div className="title">Изменить комментарий</div>
                                    <div className="block-info">
                                        <span className="label-block">Комментарий</span>
                                        <div className="textarea-comment">
                                            <Field className="comments" name="comment" component="textarea" type="text" placeholder="Ваше сообщение..."/>
                                        </div>
                                    </div>
                                    <div className="dialog-button-wrapper">
                                        <DefaultButton
                                            variant="outlined"
                                            classes="cancel_btn"
                                            onClick={this.closeDialogComment}
                                        >
                                            ОТМЕНА
                                        </DefaultButton>
                                        <DefaultButton
                                            variant="contained"
                                            formAction
                                        >
                                            СОХРАНИТЬ
                                        </DefaultButton>
                                        {!!user.error_text && !!user.error_text.comment ? <span className="error-text">{user.error_text.comment[0]}</span> : null}
                                    </div>
                                </DialogComponentForm>
                            </div>
                            <span className="dialog_favorite_close_button" onClick={this.openDialogComment} >
                              <img className="icon_close" src={CloseDialogIcon} alt="close icon"/>
                            </span>
                        </DialogComponent>
                        :
                        null
                    }
                    {(!!user.favorite_lists[page]) ?
                        <DialogComponent classes={classes}
                                         style={{height: '365px'}}
                                         open={open}
                                         onClose={this.toggleDialog}
                        >
                            <div className="dialog-delete-wrapper">
                                <div className="title">Удалить товар</div>
                                <div className="descriptions">Вы уверены?</div>
                                <div className="dialog-button-wrapper">
                                    <DefaultButton
                                        variant="outlined"
                                        classes="cancel_btn"
                                        onClick={this.toggleDialog}
                                    >
                                        ОТМЕНА
                                    </DefaultButton>
                                    <DefaultButton
                                        variant="contained"
                                        onClick = {this.deleteItem}
                                    >
                                        УДАЛИТЬ
                                    </DefaultButton>

                                </div>
                            </div>
                            <span className="dialog_favorite_close_button" onClick={this.toggleDialog}>
                              <img className="icon_close" src={CloseDialogIcon} alt="close icon"/>
                            </span>
                        </DialogComponent>
                        :
                        null
                    }{(!!user.favorite_lists[0]) ?
                    <DialogComponent classes={classes}
                                     style={{height: '365px'}}
                                     open={openDeleteFavorites}
                                     onClose={this.toggleListsDialog}
                    >
                        <div className="dialog-delete-wrapper">
                            <div className="title">Удалить список</div>
                            <div className="descriptions">Вы уверены, что хотите удалить данный список и все добавленные в него товары?</div>
                            <div className="dialog-button-wrapper">
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={this.toggleListsDialog}
                                >
                                    ОТМЕНА
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick = {this.deleteFavoriteList}
                                >
                                    УДАЛИТЬ
                                </DefaultButton>
                            </div>
                        </div>
                        <span className="dialog_favorite_close_button" onClick={this.toggleListsDialog}>
                          <img className="icon_close" src={CloseDialogIcon} alt="close icon"/>
                        </span>
                    </DialogComponent>
                    :
                    null
                }
                </div>
            </TransitionedBlock>
        );
    }
}

export const DeleteAllItems = ({open, count, close, confirm}) => {
    return(
        <DialogComponent classes={classes}
                         style={{height: '365px'}}
                         open={open}
                         onClose={close}
        >
            <div className="dialog-delete-wrapper">
                <div className="title">Удалить выбранные товары</div>
                <div className="descriptions">Вы уверены, что хотите удалить {count} выбранных товара из избранных?</div>
                <div className="dialog-button-wrapper">
                    <DefaultButton
                        variant="outlined"
                        classes="cancel_btn"
                        onClick={close}
                    >
                        ОТМЕНА
                    </DefaultButton>
                    <DefaultButton
                        variant="contained"
                        onClick={confirm}
                    >
                        УДАЛИТЬ
                    </DefaultButton>

                </div>
            </div>
            <span className="dialog_favorite_close_button" onClick={close}>
              <img className="icon_close" src={CloseDialogIcon} alt="close icon"/>
            </span>
        </DialogComponent>
    )
};

function mapStateToProps(state) {
    return{
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getListnameChosenProducts,
        postListnameChosenProducts,
        getChosenProducts,
        postNewChosenProduct,
        patchComment,
        deleteSelectedItemFromFavorite,
        changeListnameChosenProducts,
        deleteListnameChosenProducts
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesPage);
