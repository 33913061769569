import React, {Component, Fragment} from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {  Redirect } from 'react-router-dom';

import RenderField from "../HelperComponents/RenderField/RenderField";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import IconButton from '@material-ui/core/IconButton';
// import Button from '@material-ui/core/Button';
import { changePasswordProfile } from "../../actions/userActions";

import CloseDialogIcon from '../../assets/image/Mask.png';
import SuccessIcon from '../../assets/image/success_icon.png';
import ErrorIcon from '../../assets/image/field_error_icon.png';

class ChangePassword extends Component {
    state = {
        loading: false
    };

    componentWillUnmount() {
        this.props.user.error_user={};
    }

    componentDidMount() {
        setTimeout( () => {
            this.submitFormPassword();
        },0);
    }

    submitFormPassword = data => {
        const { changePasswordProfile, handleSuccessPass, reset } = this.props;
        this.setState({loading: true});
        return changePasswordProfile(data).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                reset();
                this.setState({ loading: false});
                handleSuccessPass();
                localStorage.clear();
            } else {
                this.setState({ loading: false});
                // throw new SubmissionError({...res.error.response.data, _error: res.error.response.data});
            }
        });
    };

    render(){
        const { handleSubmit, submitting, pristine, valid, user: { error_user }, toggleDialog, open, successPass } = this.props;
        const { loading } = this.state;
        if(!localStorage.token) return <Redirect to="/main" />;
        return (
            <Fragment>

                <DialogComponent
                    open={open}
                    onClose={toggleDialog}
                >
                    <div className="title-dialog">
                        Сменить пароль
                    </div>
                    {successPass ?
                        <div className="block-change-info">
                            <div className="text-info">
                                <img src={SuccessIcon} alt="success icon"/>
                                Ваш пароль успешно изменен!
                            </div>
                            <div className="btn-wrapper">
                                <DefaultButton
                                    onClick={toggleDialog}
                                >
                                    ОК
                                </DefaultButton>
                            </div>
                        </div>
                        :
                        <form className="dialog-form" onSubmit={handleSubmit(this.submitFormPassword)} >
                            <div className="dialog-field-change change_password_fields">
                                <Field
                                    name="old_password"
                                    type="password"
                                    component={RenderField}
                                    label="Текущий пароль"
                                    password
                                />
                                <Field
                                    name="new_password"
                                    type="password"
                                    component={RenderField}
                                    label="Новый пароль"
                                    password
                                />
                            </div>

                            <div className="info_pass_google">Если Вы авторизованы с помощью Google-аккаунта, то можете задать пароль через форму восстановления пароля на странице авторизации</div>

                            <div className="btn-wrapper">
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={toggleDialog}
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    disabled={submitting || pristine || !valid}
                                    loading={loading}
                                    formAction
                                >
                                    Подтвердить
                                </DefaultButton>
                                {error_user && Object.keys(error_user).length !== 0 && (error_user.hasOwnProperty("old_password") || error_user.hasOwnProperty("new_password"))
                                    ?  <div className="error_after_button">
                                        <img src={ErrorIcon} alt="icon_success"/>
                                        <span>{error_user.hasOwnProperty("old_password") ? error_user.old_password.old_password :  error_user.new_password}</span>

                                    </div>
                                    : null
                                }
                            </div>

                        </form>
                    }
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </Fragment>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.old_password) {
        errors.old_password = 'Required'
    } else if (values.old_password.length < 8) {
        errors.old_password = 'Должно быть 8 и больше символов'
    }
    if (!values.new_password) {
        errors.new_password = 'Required'
    } else if (values.new_password.length < 8) {
        errors.new_password = 'Должно быть 8 и больше символов'
    } else if (values.old_password === values.new_password) {
        errors.new_password = 'Новый пароль не должен совпадать со старым'
    }
    return errors
};

ChangePassword = reduxForm({
    form: 'ChangePasswordForm',
    validate
})(ChangePassword);

function mapStateToProps(state) {
    return{
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changePasswordProfile
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
