import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import DialogComponent from "../DialogComponent/DialogComponent";
import VinFrameForm from "./VinFrameForm";
import NameMileageForm from "./NameMileageForm";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";

import { searchByVin } from '../../../actions/userActions';

import CloseDialogIcon from '../../../assets/image/Mask.png';
import ChosenModelIcon from '../../../assets/image/active_choice.svg';

import './DialogFavorites.scss';


class DialogFavorites extends Component {
    state = {
        addingOption: "by_name",
        vin: "",
        notFoundByVin: false,
        autoName: "",
        models: [],
        loading: false,
        chosenModel: {}
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.openFavorites !== prevProps.openFavorites) && !this.props.openFavorites) {
            this.resetState();
        }
    }

    changeAddingOption = addingOption => this.setState({ addingOption });

    selectModel = () => {
        const { chosenModel } = this.state;
        this.setState({ autoName: `${chosenModel["Марка"]} ${chosenModel["Модель"]}`, models: [] });
    };

    submitVinForm = ({ vin }) => {
        const { searchByVin } = this.props;
        this.setState({ loading: true });
        searchByVin(vin).then((res) => {
            const { models } = res.payload.data;
            console.log(models);
            if (models && models.length) {
                if (models.length === 1) {
                    this.setState({ vin, autoName: `${models[0]["Марка"]} ${models[0]["Модель"]}`, notFoundByVin: false, loading: false });
                } else {
                    this.setState({ vin, models, notFoundByVin: false, loading: false });
                }
            } else {
                this.setState({ notFoundByVin: true, loading: false });
            }
        });
    };

    resetState = () => this.setState({
        addingOption: "by_name",
        vin: "",
        notFoundByVin: false,
        autoName: "",
        models: [],
        loading: false,
    });

    render() {
        const { openFavorites, openDialogFavorites, onSubmitAction } = this.props;
        const { addingOption, notFoundByVin, models, autoName, vin, loading, chosenModel } = this.state;
        const addingByName = addingOption === "by_name";
        return(
            <DialogComponent
                open={openFavorites}
                onClose={openDialogFavorites}
                classes={{
                    root: 'favorites_dialog_root',
                    paper: "paper_custom_dialog",
                }}
            >
                <div className="dialog-favorites">
                    <div className="title">Добавить в избранное</div>
                    {autoName ?
                        <Fragment>
                            <div className="adding_options">
                                <div className="vin_num">
                                    {vin}
                                </div>
                            </div>
                        <NameMileageForm
                            onSubmitAction={onSubmitAction}
                            openFavorites={openFavorites}
                            openDialogFavorites={openDialogFavorites}
                            name={autoName}
                            vin={vin}
                        />
                        </Fragment>
                        :
                        models.length > 0 ?
                            <Fragment>
                                <h4>Выберите модификацию автомобиля</h4>
                                <div className="table_container table_header">
                                    <div className="row">
                                        <div className="row_item">Производитель</div>
                                        <div className="row_item">Модель</div>
                                        <div className="row_item">Рынок</div>
                                        <div className="row_item" />
                                    </div>
                                </div>
                                <div className="table_container table_body">
                                    {models.map((el, key) => (
                                        <div className='row' key={key} onClick={() => this.setState({ chosenModel: el })}>
                                            <div className="row_item">{el["Марка"]}</div>
                                            <div className="row_item">{el["Модель"]}</div>
                                            <div className="row_item">{el["Рынок"]}</div>
                                            <div className="row_item">
                                                {chosenModel === el ?
                                                    <img src={ChosenModelIcon} alt="chosen"/>
                                                    :
                                                    <div className="not_chosen" />
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="dialog-button-wrapper margin_top_10">
                                    <DefaultButton
                                        variant="outlined"
                                        classes="cancel_btn"
                                        onClick={openDialogFavorites}
                                    >
                                        ОТМЕНА
                                    </DefaultButton>
                                    <DefaultButton
                                        variant="contained"
                                        disabled={!chosenModel.hasOwnProperty("Марка")}
                                        onClick={this.selectModel}
                                    >
                                        ПРОДОЛЖИТЬ
                                    </DefaultButton>
                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                <div className="adding_options">
                                    <p
                                        onClick={() => this.changeAddingOption("by_name")}
                                        className={addingByName ? "active" : ""}
                                    >
                                        По названию
                                    </p>
                                    <p
                                        onClick={() => this.changeAddingOption("by_VIN")}
                                        className={addingByName ? "" : "active"}
                                    >
                                        По VIN-номеру
                                    </p>
                                </div>
                                {addingByName ?
                                    <NameMileageForm
                                        onSubmitAction={onSubmitAction}
                                        openFavorites={openFavorites}
                                        openDialogFavorites={openDialogFavorites}
                                    />
                                    :
                                    <VinFrameForm
                                        openDialogFavorites={openDialogFavorites}
                                        submitVinForm={this.submitVinForm}
                                        notFoundByVin={notFoundByVin}
                                        loading={loading}
                                    />
                                }
                            </Fragment>
                    }
                </div>
                <span className="dialog_favorite_close_button" onClick={openDialogFavorites} >
                    <img className="icon_close" src={CloseDialogIcon} alt="close icon"/>
                </span>
            </DialogComponent>
        )
    }
}

const mapDispatchToProps = {
    searchByVin
};

export default connect(null, mapDispatchToProps)(DialogFavorites);