import * as types from "../actions/constants";

const INITIAL_STATE = {
    basket_list: {},
    total: 0,
    buy: false,
    actions: false,
    load: false,
    update: false,
    error: {},
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case 'GET_TOTAL_COUNT':
            return {...state, total: action.payload};
        case types.GET_BASKET :
            return {...state, load: false, buy: false, basket_list: {}};
        case types.GET_BASKET_SUCCESS :
            return {...state, basket_list: action.payload.data, load: true, buy: false, actions: false};
        case types.GET_BASKET_FAIL :
            return {...state, error : action.error.response.data, load: true};
        case types.GET_ORDER_BASKET_SUCCESS:
            return {...state, order: action.payload.data};
        case types.BUY_ORDER_BASKET :
            return {...state, load: false};
        case types.BUY_ORDER_BASKET_SUCCESS :
            return {...state, load: true, buy: true, basket_list: action.payload.data};
        case types.BUY_ORDER_BASKET_FAIL :
            return {...state, error : action.error.response.data, load: true};
        case types.BUY_ALL_BASKET :
            return {...state, buy: false, load: false};
        case types.BUY_ALL_BASKET_SUCCESS :
            return {...state, buy: true, load: true, basket_list: action.payload.data};
        case types.BUY_ALL_BASKET_FAIL :
            return {...state, error : action.error.response.data, load: true};
        case types.DELETE_ALL_BASKET :
            return {...state, actions: false, load: false};
        case types.DELETE_ALL_BASKET_SUCCESS :
            return {...state, actions: true, load: true, basket_list: action.payload.data};
        case types.DELETE_ALL_BASKET_FAIL :
            return {...state, error : action.error.response.data, load: true};
        case types.UPDATE_OFFERT :
            return {...state, actions: false, load: false, update: false};
        case types.UPDATE_OFFERT_SUCCESS :
            return {...state, buy: false, actions: true, load: true, basket_list: action.payload.data};
        case types.UPDATE_OFFERT_FAIL :
            return {...state, error : action.error.response.data, load: true};
        default:
            return state;
    }
}
