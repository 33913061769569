import React, {Component } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import './SpecificPart.scss';

class PartImage extends Component {

    onMouseOver = (id) => {
        const idx = `table_row ${id}&`;
        const elements = document.getElementsByClassName(idx);
        for(let i = 0, len = elements.length; i < len; i++) {
            elements[i].style['border-bottom'] = '1px solid rgba(47, 0, 232, 0.6)';
        }
    };

    onMouseLeave = (id) => {
        const idx = `table_row ${id}&`;
        const elements = document.getElementsByClassName(idx);
        setTimeout(() => {
            for(let i = 0, len = elements.length; i < len; i++) {
                elements[i].style['border-bottom'] = '';
            }
        }, 2000);
    };

    handleClick = (id) => {
        const idx = `table_row ${id}&`;
        const element = document.getElementsByClassName(idx);
        if (!!element[0]) {
            element[0].scrollIntoView({block: "center", behavior: "smooth"})
        }
    };

    render(){
        const { tree_node_parts } = this.props;

        return (
            <div className="part-chart-scheme">
                <div className="part-image-block">
                    <img className="part-image" src={tree_node_parts.parts.image} alt="PartsImage"/>
                    <div className="responsive-content">
                        {tree_node_parts.parts.coord[0].map((block, idBlock) => {
                            let style = {
                                height: `${block.height}%`,
                                marginLeft: `${block['margin-left']}%`,
                                marginTop: `${block['margin-top']}%`,
                                width: `${block.width}%`
                            };
                            return (<Tooltip title={document.getElementById(block.name) !== null && !!document.getElementById(block.name) ?
                                document.getElementById(block.name).innerHTML : " "} placement="top" key={idBlock}>
                                <div style={style} className={block.name} onMouseOver={(e) => this.onMouseOver(block.name)}
                                     onMouseLeave={(e) => this.onMouseLeave(block.name)} onClick={(e) => this.handleClick(block.name)} />
                            </Tooltip>)
                        })
                        }
                    </div>
                </div>
            </div>
        );
    }
}


export default PartImage;