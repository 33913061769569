import * as types from './constants.jsx';

//user actions

export function getInfo() {
    return {
        type: types.INFO,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/totalinfo/`,
                method: "get"
            }
        }
    };
}

export function patchInfo(data) {
    return {
        type: types.CHANGE_INFO,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/totalinfo/`,
                method: "patch",
                data
            }
        }
    };
}

export function getProfile() {
    return {
        type: types.GET_PROFILE,
        payload: {
            client: 'default',
            request: {
                url: `/customer/profile/`,
                method: "get"
            }
        }
    };
}

export function putProfile(data) {
    return {
        type: types.PUT_PROFILE,
        payload: {
            client: 'default',
            request: {
                url: `/customer/profile/`,
                method: "put",
                data
            }
        }
    };
}

export function patchProfile(data) {
    return {
        type: types.PATCH_PROFILE,
        payload: {
            client: 'default',
            request: {
                url: `/customer/profile/`,
                method: "patch",
                data
            }
        }
    };
}

export function changePasswordProfile(data) {
    return {
        type: types.CHANGE_PASSWORD_PROFILE,
        payload: {
            client: 'default',
            request: {
                url: `/customer/profile/change-password/`,
                method: "post",
                data
            }
        }
    };
}

export function postSetLocation(data) {
    return {
        type: types.SET_LOCATION,
        payload: {
            client: 'default',
            request: {
                url: `/customer/profile/set-location/`,
                method: "post",
                data
            }
        }
    };
}

export function initialFavouritesList(data) {
    return {
        type: types.INIT_FAVOURITES_LIST,
        payload: {data}
    };
}

export function getMainPageChosenLists() {
    return {
        type: types.GET_MAIN_PAGE_CHOSEN_LISTS,
        payload: {
            client: 'default',
            request: {
                url: `/customer/mainpagechosenlists/`,
                method: "get"
            }
        }
    };
}

export function getListnameChosenProducts() {
    return {
        type: types.GET_LISTNAME_CHOSEN_PRODUCTS,
        payload: {
            client: 'default',
            request: {
                url: `/customer/listnamechosenproducts/`,
                method: "get"
            }
        }
    };
}

export function postListnameChosenProducts(data) {
    return {
        type: types.POST_NEW_CHOSEN_PRODUCT,
        payload: {
            client: 'default',
            request: {
                url: `/customer/listnamechosenproducts/`,
                method: "post",
                data
            }
        }
    };
}

export function changeListnameChosenProducts (id, data) {
    return {
        type: types.CHANGE_LISTNAME_CHOSEN_PRODUCTS,
        payload: {
            client: 'default',
            request: {
                url: `/customer/listnamechosenproduct/${id}`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteListnameChosenProducts (id) {
    return {
        type: types.DELETE_LISTNAME_CHOSEN_PRODUCTS,
        payload: {
            client: 'default',
            request: {
                url: `/customer/listnamechosenproduct/${id}`,
                method: "delete"
            }
        }
    };
}

export function getChosenProducts(page, id) {
    let data = '';
    if (!!id) {
        data = `product_list_id=${id}`;
    }
    return {
        type: types.GET_CHOSEN_PRODUCTS,
        payload: {
            client: 'default',
            request: {
                url: `/customer/chosenproducts/?page=${page}&${data}`,
                method: "get"
            }
        }
    };
}

export function postNewChosenProduct(id, data) {
    return {
        type: types.POST_NEW_CHOSEN_PRODUCT,
        payload: {
            client: 'default',
            request: {
                url: `/customer/product/${id}/`,
                method: "post",
                data
            }
        }
    };
}

export function getPartDetail(articul, data) {
    return {
        type: types.GET_PART_DETAIL,
        payload: {
            client: 'default',
            request: {
                url: `/customer/product/${articul}/${data}`,
                method: "get"

            }
        }
    };
}

export function getSearchParts(search) {
     let data = '';
    if (!!search) {
        data = `?search=${search}`;
    }
    return {
        type: types.GET_SEARCH_PARTS,
        payload: {
            client: 'default',
            request: {
                url: `customer/product/search/${data}`,
                method: "get"
            }
        }
    };
}



export function patchComment(id, data) {
    return {
        type: types.CHANGE_COMMENT,
        payload: {
            client: 'default',
            request: {
                url: `/customer/chosenproduct/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteItemsFromFavorite(data) {
    return {
        type: types.DELETE_ITEMS_FROM_FAVORITE,
        payload: {
            client: 'default',
            request: {
                url: `/customer/chosenproductsdelete/${data}`,
                method: "delete"
            }
        }
    };
}

export function deleteSelectedItemFromFavorite(id) {
    return {
        type: types.DELETE_SELECTED_ITEM_FROM_FAVORITE,
        payload: {
            client: 'default',
            request: {
                url: `/customer/chosenproduct/${id}/`,
                method: "delete"
            }
        }
    };
}

export function deleteOfferFromFavorite(id) {
    return {
        type: types.DELETE_OFFER_FROM_FAVORITE,
        payload: {
            client: 'default',
            request: {
                url: `/customer/product/${id}/`,
                method: "delete"
            }
        }
    };
}

export function searchByVin(vin) {
    return {
        type: types.SEARCH_BY_VIN,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=VinFind&lang=ru&vin=${vin}`,
                method: "get"
            }
        }
    };
}


export function contactsData(data) {
    return {
        type: types.POST_SENDMESAGE_CONTACTS,
        payload: {
            client: 'default',
            request: {
                url: `/feedback/`,
                method: "post",
                data
            }
        }
    };
}
