import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Link } from 'react-router-dom';
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import DialogComponent from '../HelperComponents/DialogComponent/DialogComponent';
import Loader from '../HelperComponents/Loader/Loader';
import {Field, reduxForm} from 'redux-form';
import {putProfile} from '../../actions/userActions';

import {ErrorMessage, SuccessMessage} from '../Buttons/BuyButton/BuyButton';

import BasketOrders from './BasketList';
import {ModalAutorization, ConfirmModal} from './DialogModalRender';

import { getBasket, showBasket, updateBasket, buyOrderBasket, buyAllBasket, deleteAllBasket, getTotalCount } from "../../actions/basketActions";

import './BasketPage.scss';
import RenderField from "../HelperComponents/RenderField/RenderField";
import IconButton from "@material-ui/core/IconButton";
import CloseDialogIcon from "../../assets/image/Mask.png";

const classes = {
    root: 'favorites_dialog_root',
    paper:'paper_custom_dialog',
};

const classes_def = {
    root: 'default_dialog_root',
    paper:'paper_custom_dialog basket-dialog',
};

class BasketPage extends Component {
    state = {
        open_modal: false,
        load: false,
        action: null,
        type_modal: null,
        empty: false,
        update: false,
        count: null,
        price: null,
        itemLoad: false,
        itemId: null,
        success_buy: false,
        count_buy: '',
        dataBuyOrders: {modalNumebrPhone: false, callbackFunc: null},
        id: null
    };

    componentDidMount = () => {
        this.controlerGetBasket();
    };

    componentDidUpdate = (prevProps, prevState) => {
        const {basket} = this.props;
        if(prevProps.basket.load !== basket.load) {
            if(basket.load) this.setState({itemLoad: false});
            this.setState({load: basket.load});
        }
        if(prevState.update !== this.state.update && this.state.update) this.controlerGetBasket();
        if(prevProps.basket.basket_list.error !== basket.basket_list.error){
            if(basket.basket_list.error) {
                if(this.state.action === 'new_dowload') this.controlerModalWindow('get');
                else {
                    this.updateLocalStorage();
                    this.controlerModalWindow('update');
                }
            } else this.setState({action: null});
        }
        if(prevProps.basket.basket_list.total_info !== basket.basket_list.total_info && !!basket.basket_list.total_info){
            this.setState({count: basket.basket_list.total_info.quantity, price: basket.basket_list.total_info.price});
            localStorage.setItem("total_count", basket.basket_list.total_info.quantity);
            this.props.getTotalCount();
        }
    };

    controlerGetBasket = () => {
        const { profile, getBasket, updateBasket, showBasket } = this.props;
        const cart = localStorage.getItem('parts_cart');
        if(!profile.id && !cart) this.setState({empty: true, load: true});
        else if (!profile.id && !!cart) showBasket(cart);
        else if (!cart && !!profile.id) getBasket();
        else {
            updateBasket(JSON.parse(cart));
            this.clearLocalStorage();
        }
        this.setState({action: 'new_dowload', update: false});
        this.props.getTotalCount();
    }

    updateLocalStorage = () => {
        const {orders, total_info} = this.props.basket_list;
        let result = {};
        orders.map((value) => value.offers.map((val) => result[val.id] = val.quantity))
        localStorage.setItem("parts_cart", JSON.stringify(result));
        localStorage.setItem("total_count", total_info.quantity);
    }

    clearLocalStorage = () => {
        localStorage.removeItem("parts_cart");
        localStorage.removeItem("total_count");
    };

    updateData = (newCount, newPrice) => {
        const {count, price} = this.state;
        if(newCount === 'update') this.controlerGetBasket();
        this.setState({count: count + newCount, price: price + newPrice});
    };

    stateModal = () => this.setState(prevState => ({open_modal: !prevState.open_modal, action: null, type_modal: null}));

    controlerModalWindow = (type) => {
        if(type === 'get' || type === 'update') this.setState({open_modal: true, type_modal: 'error', action: type});
        else if(!this.props.profile.id && type !== 'delete') this.setState({open_modal: true, type_modal: 'auth', action: type});
        else this.setState({open_modal: true, type_modal: 'checked', action: type});
    };

    confirmAction = () => {
        if (this.state.action === 'buy') {
            this.props.buyAllBasket().then(res => {
                this.setState({action: 'buy'});
                if(res.payload && res.payload.status && res.payload.status === 201){
                    this.setState({count_buy: 'all', success_buy: true});
                    this.setState({success_buy: true});
                }else{
                    this.setState({dataBuyOrders: {modalNumebrPhone: true, callbackFunc: this.confirmAction}, action: 'buy'})
                }
            });
        } else {
            if(!!this.props.profile.id) this.props.deleteAllBasket();
            else {
                this.clearLocalStorage();
                this.controlerGetBasket();
            }
        }
        this.stateModal();
    };

    localUpdate = () => this.setState({update: true, load: false});

    itemLoad = (item) => this.setState({itemLoad: true, itemId: item});

    buy = (id) => {
        this.setState({id: id});
        if(!!this.props.profile.id) {
          this.props.buyOrderBasket(id).then(res => {
              if(res.payload && res.payload.status && res.payload.status === 201){
                  this.setState({success_buy: true});
              }else{
                  this.setState({dataBuyOrders: {modalNumebrPhone: true, callbackFunc: () => this.buy(id)} })
              }
          });
        }
        else this.stateModal();
    };

    stateBuyFalse = () => this.setState({success_buy: false});


    toggleDialog = () => {
        this.setState(({dataBuyOrders: {modalNumebrPhone}}) => ({dataBuyOrders: {
            modalNumebrPhone: !modalNumebrPhone
        }}));
        this.props.reset();
    };



    submitForm = data => {
        const {dataBuyOrders} = this.state;
        const { putProfile, reset} = this.props;
        let obj = {"phone": data.phone};




        putProfile(obj).then(res => {
             if(res.payload && res.payload.status && res.payload.status === 200) {
                 this.setState({dataBuyOrders: {modalNumebrPhone: false, callbackFunc: null }});
                 if (dataBuyOrders.callbackFunc) dataBuyOrders.callbackFunc();
                 reset();
             }
        })
    };

    render(){
        const {count, price, type_modal, action, itemLoad} = this.state;
        const {load, basket_list: {orders, total_info}, buy} = this.props.basket;
        const {submitting, pristine, handleSubmit, valid} = this.props;

        if(!this.state.load && !itemLoad) return <Loader size={80} thickness={2.5}/>;
        if(buy && orders.length === 0) {
          return <Redirect to={{
              pathname: "/main/orders",
              state: {success_buy : true}
          }}/>
        }
        const style = (type_modal === 'error') ? {height: '345px'} :
            (type_modal === 'checked') ? {height: '375px'} :  {height: '270px'}


        return(
            <div className="basket-page container">
                {this.state.success_buy ? <SuccessMessage close={this.stateBuyFalse} message={"Товар успешно оформлен"} /> : null}
                <DialogComponent open={this.state.open_modal} close={this.stateModal} classes={classes} style={style}>
                    {(this.state.open_modal) ? <ViewModal type_modal={type_modal} action={action} count={orders.length}
                                                          stateModal={this.stateModal} confirm={this.confirmAction} /> : null}
                </DialogComponent>
                <DialogComponent open={this.state.dataBuyOrders.modalNumebrPhone} classes={classes_def}>
                    <div className="basket-dialog-number">
                        <p>Введите номер телефона</p>
                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <span>Пожалуйста, введите номер вашего телефона для завершения заказа.</span>
                            <Field name="phone" type="number" component={RenderField} label="Телефон"/>
                            <div className="dialog-button-wrapper">
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={this.toggleDialog}
                                >
                                    ОТМЕНА
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    formAction
                                    disabled={submitting || pristine || !valid}
                                >
                                    Сохранить
                                </DefaultButton>
                            </div>
                        </form>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
                <div className="title">Корзина</div>
                {this.state.empty || !orders || orders.length === 0 ? <EmptyOrders /> :
                    <div className="basket">
                        {orders.map((element, index) => <BasketOrders key={index} orders={element}
                                                                      updateData={this.updateData} localUpdate={this.localUpdate} buy={this.buy}
                                                                      load={load} loader={this.itemLoad} auth={!!this.props.profile.id}/>
                        )
                        }
                        {(orders.length > 1) ? <ProductTotal {...total_info} count={count} price={price} checkWindow={this.controlerModalWindow}/> : null}
                    </div>
                }
            </div>
        )
    }
}

const ViewModal = ({stateModal, type_modal, action, confirm, count}) => {
    if(type_modal === 'checked') {
        const type = (action === 'buy') ? 'buy_all' : 'delete_all';
        return <ConfirmModal onClose={stateModal} confirm={confirm} type={type} count={count}/>;
    }
    else if(type_modal === 'error') return <ErrorMessage stateModal={stateModal} type={action}/>;
    else return <ModalAutorization onClose={stateModal} />;
}

const ProductTotal = ({count, price, checkWindow}) => {
    return(
        <div className="product-total-wrapper">
            <p>Всего: <span>{count} товаров</span></p>
            <span className="info-price">{price} <span>₽</span></span>
            <div className="btn-wrapper">
                <DefaultButton
                    variant="outlined"
                    classes="delete_btn"
                    onClick={() => checkWindow('delete')}
                > УДАЛИТЬ ВСЕ </DefaultButton>
                <button className="green-btn" onClick={() => checkWindow('buy')} >КУПИТЬ ВСЕ</button>
            </div>
        </div>
    )
};

const EmptyOrders = () => {
    return(
        <div className="no-item-basket">
            Здесь пока пусто. Воспользуйтесь <Link to="/" className="">поиском</Link>
            ,чтобы найти нужные запчасти, и добавьте их в корзину.
        </div>
    )
};


const validate = values => {
    const errors = {};
    if (!values.phone) {
        errors.phone = 'Required'
    } else if (values.phone.length < 8) {
        errors.phone = 'Must be 8 characters or more'
    }
    return errors;

};

BasketPage = reduxForm ({
    form: 'phone',
    validate
}) (BasketPage);


function mapStateToProps({basket, user}) {
    return{
        basket:{...basket},
        basket_list: basket.basket_list,
        ...user
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBasket,
        updateBasket,
        showBasket,
        buyAllBasket,
        buyOrderBasket,
        deleteAllBasket,
        getTotalCount,
        putProfile
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketPage);
