import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import DialogComponent from '../../HelperComponents/DialogComponent/DialogComponent';
import Collapse from '@material-ui/core/Collapse';
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import moment from 'moment';
import 'moment/locale/ru';
import ItemOffset from './ItemOffset';
import ModalFavorites from '../../SearchPage/ModalFavorites';
import arrowup from '../../../assets/image/arrowup.png';
import arrowdown from '../../../assets/image/arrowdown.png';

import up_arrow from '../../../assets/image/up_arrow.png';
import down_arrow from '../../../assets/image/down_arrow.png';

import Close from '../../../assets/image/Mask.png';
import Up from '../../../assets/image/up.png';

import '../../BasketPage/ModalPage.scss';
import './TableOffers.scss';

moment.locale('ru');

const classes = {
    root: 'favorites_dialog_root',
    paper:'paper_custom_dialog'
}

export const ContextModalFavorite = React.createContext();

class TableOffers extends Component {
    //type order: toMore, tolesser
    state = {
        typeOrder: '-distance',
        lengthRadius: 100,
        open_list: false,
        open_modal: false,
        searchValue: false,
        showAnalog: true,
        chosenList: {},
        showButton: false
    }

    componentDidMount = () => {
        this.updateChosen(this.props.data);
        document.addEventListener('scroll',(e) => {
            if(Math.floor(window.pageYOffset) > 600) this.scrollSite(true);
            else this.scrollSite(false);
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.valueSearch !== this.props.valueSearch) this.dataSearch();
        if(this.state.lengthRadius !== this.props.distance) this.setState({lengthRadius: this.props.distance});
        if(prevProps.data !== this.props.data){
            const {data} = this.props;
            if(!!this.props.valueSearch) this.dataSearch();
            else {
                const showAnalog = data.analog_products.some(value => value.offer_products.length !== 0);
                this.setState({showAnalog: showAnalog});
            }
        }
        if(prevProps.user.main_search !== this.props.user.main_search) this.updateChosen(this.props.user.main_search);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.scrollSite, false);
    }

    dataSearch = () => {
        const {data, valueSearch} = this.props;
        if(!valueSearch) this.setState({searchValue: false, showAnalog: true});
        else {
            const newList = {...data};
            if(!newList.article_number.toLowerCase().trim().includes(valueSearch) && !newList.producer_name.toLowerCase().trim().includes(valueSearch)){
                newList.offer_products = this.filterSearch(newList.offer_products, valueSearch);
            }
            let show_analog = false;
            let analog_list = [];
            newList.analog_products = newList.analog_products.map((value, key) => {
                analog_list[key] = {...value};
                if(!value.article_number.toLowerCase().trim().includes(valueSearch) && !value.producer_name.toLowerCase().trim().includes(valueSearch)){
                    analog_list[key].offer_products = this.filterSearch(value.offer_products, valueSearch);
                    if(analog_list[key].offer_products.length !== 0) show_analog = true;
                } else show_analog = true;
                return analog_list[key];
            })
            this.setState({searchValue: newList, showAnalog: show_analog});
        }
    }

    filterSearch = (data, valueSearch) => {
        const poles = ['name', 'company'];
        let result = [];
        data.map((value) => {
            let check = false;
            if(!check) check = poles.some(pole => value[pole].toLowerCase().trim().includes(valueSearch));
            if(check) result.push(value);
            return result;
        });
        return result;
    }

    updateChosen = (data) => {
        let chosenList = {...this.state.chosenList};
        chosenList[data.id] = data.chosen;
        data.analog_products.map((value) => chosenList[value.id] = value.chosen);
        this.setState({chosenList: chosenList});
    }

    deleteChosen = (id) => {
        const newList = {...this.state.chosenList};
        newList[id] = !newList[id];
        this.setState({chosenList: newList});
    }

    close_list = () => this.setState(({open_list}) => ({open_list: !open_list}));

    stateModal = () => this.setState(({open_modal}) => ({open_modal: !open_modal}));

    checked = (value) => this.setState({typeOrder: value, open_list: false});

    dynamicSort = (property) => {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
        }
        property = property.substr(1);
        return (a,b) => {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    chosenRadius = (value) => {
        this.stateModal();
        this.props.updateDistance(value);
    }

    ScrollToReviews = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    scrollSite = (value) => this.setState({showButton: value});

    render(){
        const { user } = this.props;
        const {typeOrder, showAnalog, chosenList, searchValue, showButton} = this.state;
        const authorized = !!(user.profile.id);
        const data = (!searchValue) ? this.props.data : searchValue;
        let empty = Boolean(data.offer_products.length === 0 && (data.analog_products.length === 0 || !showAnalog));
        return (
            <div className="table-offers">
                <DialogComponent open={this.state.open_modal} close={this.stateModal} classes={classes}>
                    <ConfirmModal close={this.stateModal} confirm={this.chosenRadius} radius={this.state.lengthRadius}/>
                </DialogComponent>
                <div className="special-block">
                    <BlockRadius stateModal={this.stateModal} lengthRadius={this.state.lengthRadius} />
                    {(!empty) ? <SortList open={this.state.open_list} close={this.close_list} checked={this.checked} chosen={this.state.typeOrder}/> : null}
                </div>
                <ContextModalFavorite.Provider value={this.deleteChosen}>
                    <div className="block-list-offer">
                        {(data.offer_products.length !== 0 || empty) ? <div className="title">Предложения</div> : null}
                        {!!data &&  data.offer_products.length !== 0 ?
                            <ListOffers main_search={data} count={6} authorized={authorized} chosen={chosenList}
                                        offer_products={data.offer_products.sort(this.dynamicSort(typeOrder))} />
                            : null
                        }
                        {(empty) ? <p>По Вашему запросу не найдено ни одного предложения. Попробуйте изменить выбранные фильтры или радиус поиска.</p> : null}
                    </div>
                    {(showAnalog && data.analog_products.length !== 0) ?
                        <div className="block-list-offer">
                            <div className="title">Аналоги</div>
                            {data.analog_products.map((products, index) => {
                                if(products.offer_products.length !== 0) {
                                    return(
                                        <ListOffers key={index} main_search={products} count={3} authorized={authorized} chosen={chosenList}
                                                    offer_products={products.offer_products.sort(this.dynamicSort(typeOrder))} />
                                    )
                                }
                                return null;
                            })}
                        </div> : null
                    }
                    {(showButton) ? <button className="up-btn" onClick={this.ScrollToReviews}>
                        НАВЕРХ  <img src={Up} alt="Up"/>
                    </button> : null}
                </ContextModalFavorite.Provider>
            </div>
        );
    }
}

class ListOffers extends Component{
    state = {
        showItems: false,
        chosen: true,
        open_modal: false
    }

    stateModal = () => this.setState(({open_modal}) => ({open_modal: !open_modal}));

    controlShow = () => this.setState(({showItems}) => ({showItems: !showItems}));

    render(){
        const {main_search, offer_products, count, authorized} = this.props;
        return(
            <React.Fragment>
                <TitleHeader title={main_search} chosen={this.props.chosen} authorized={authorized} />
                <div className="table">
                    {
                        offer_products.slice(0, count).map((product, index) => (
                            <ItemOffset key={index} product={product} />
                        ))
                    }
                    <Collapse in={this.state.showItems} timeout="auto" unmountOnExit>
                        {
                            offer_products.slice(count).map((product, index) => (
                                <ItemOffset key={index} product={product} />
                            ))
                        }
                    </Collapse>
                    <div>
                        {offer_products.length > count ?
                            <button className="all-product" onClick={this.controlShow}>
                                <span>• • •</span>{(this.state.showItems) ? " скрыть" : " показать все"}
                            </button>
                            : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

class TitleHeader extends Component {
    state = {
        open_modal: false,
        id: null
    }

    stateModal = (id) => this.setState(prevState => ({open_modal: !prevState.open_modal, id: id}));

    render(){
        const {title, chosen, authorized} = this.props;
        const {producer_name, article_number, name, id} = title;
        const type = 'offers_list';
        return(
            <div className="name">
                {!!id ? <ModalFavorites open={this.state.open_modal} stateModal={this.stateModal}
                                        id={this.state.id} title={title} chosen={chosen[id]} type={type}/> : null}
                <span style={{color: "#0253B3", fontWeight: "600"}}>{producer_name}</span>
                <p>•</p>
                {article_number}
                <p>•</p>
                {name}
                {authorized ?
                    <button className={(chosen[id]) ? "favourites_chosen_btn" : "favourites_btn"} onClick={() => this.stateModal(id)}></button>
                    : null
                }
            </div>
        )
    }
}

const SortList = ({open, close, checked, chosen}) => {
    const dataSort = {
        'distance': {name: 'Растояние'},'price': {name: 'Цена'},
        'amount': {name: 'Наличие'},'delivery_day': {name: 'Срок Поставки'}
    };
    const valueSort = [
        '+distance', '-distance','+price', '-price',
        '+amount', '-amount','+delivery_day', '-delivery_day',
    ];

    return(
        <div className="setting-block">
            <li className="li-option" onClick={close}>
                <div>
                    {(chosen[0] === '+') ? <img src={up_arrow} alt="up_arrow" /> : <img src={down_arrow} alt="down_arrow" />}
                    <span className="name-option">{dataSort[chosen.substr(1)].name}</span>
                </div>
                {(open) ? <img src={arrowup} alt="arrowup" /> : <img src={arrowdown} alt="arrowdown" />}
            </li>
            {(!open) ? null :
                <ul className="dropdown-options">
                    {
                        valueSort.map((val, index) => (
                            <li key={index}  className={(chosen === val) ? "active" : null} onClick={() => checked(val)}>
                                {(val[0] === '+') ? <img src={up_arrow} alt="up_arrow" /> : <img src={down_arrow} alt="down_arrow" />}
                                <span className="name-option">
                    {dataSort[val.substr(1)].name}
                  </span>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}

class ConfirmModal extends Component{
    state = {
        value: this.props.radius,
        error: false
    }

    handlerRadius = (event) => {
        if(0 > event.target.value || event.target.value > 250) this.setState({error: true})
        else this.setState({value: event.target.value, error: false});
    }

    render(){
        const { close, confirm} = this.props;
        return(
            <div className="modal-container confirm">
                <span className="close" onClick={close}><img src={Close} alt="close"/></span>
                <div className="main-block radius">
                    <h1>Радиус результатов поиска</h1>
                    <span>Укажите радиус, в пределах которого Вы хотите видеть результаты поиска (до 250 км).</span>
                    <div className="state-new-distance">
                        <span>Радиус поиска</span>
                        <div>
                            <input type="number" value={this.state.value} onChange={this.handlerRadius}/>
                            <span>км</span>
                        </div>
                    </div>
                </div>
                <div className="list-button">
                    <DefaultButton
                        variant="outlined"
                        classes="cancel_btn"
                        onClick={close}
                    >Отмена</DefaultButton>
                    <DefaultButton
                        variant="outlined"
                        classes="default_button_contained"
                        onClick={(!this.state.error) ? () => confirm(this.state.value) : null }
                    >
                        Сохранить
                    </DefaultButton>
                </div>
            </div>
        );
    }
}

const BlockRadius = ({lengthRadius, stateModal}) => {
    return(
        <div className="control-length-radius">Показать в радиусе:
            <span className="length-radius" onClick={stateModal}>{lengthRadius} км</span>
        </div>
    )
}

function mapStateToProps(state) {
    return{
        user: state.user,
        orders: state.orders
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //login
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TableOffers);
