import React, { Component } from "react";
import { Link } from "react-router-dom";

import Rating from "../../HelperComponents/Rating/Rating";
import LabelGreen from "../../../assets/image/green_label.png";
import LabelBlue from "../../../assets/image/blue_label.png";

import "./CompareOffers.scss";
import BuyButton from "../../Buttons/BuyButton/BuyButton";

const CompareOferrsBlock = ({ type, data, producer_name, article_number }) => {
    const {
        id,
        name,
        producer,
        amount,
        price,
        company,
        avg_company_rating,
        delivery_day,
        company_reviews,
        company_id,
    } = data;
    return (
        <div className="compare">
            <div className="compare-wrapper">
                <div className="compare-info">
                    <div className="price">САМАЯ НИЗКАЯ ЦЕНА</div>
                    <Link
                        to={`/main/supplier-information/${company_id}?offers=${
                            document.location.pathname.split("/")[3]
                        }`}
                        className="name"
                    >
                        {!company ? null : company}
                    </Link>
                    <div className="reviews-wrapper">
                        {!avg_company_rating ? null : (
                            <Rating value={avg_company_rating} rating_info />
                        )}
                        <span className="review">
                            {!company_reviews
                                ? null
                                : `${company_reviews} отзыва`}
                        </span>
                    </div>
                </div>
                <Label type={type} />
            </div>
            <div className="product-name">
                <span className="product"> {name}</span>
                <span className="product-code">{article_number}</span>
            </div>
            <div className="firm-product">{producer_name}</div>
            <div className="stock">
                <div className="stock-info">
                    <div>
                        <span>В наличии</span>
                        {amount !== 0 ? (
                            <p>{amount} шт.</p>
                        ) : (
                            <span>Нет в наличии</span>
                        )}
                    </div>
                    {!delivery_day ? null : (
                        <div>
                            <span>Срок поставки</span>
                            <p>{delivery_day} дн.</p>
                        </div>
                    )}
                </div>
                <div className="product-buy">
                    <div className="price">
                        <p>{price}</p>₽
                    </div>
                    <BuyButton
                        id={id}
                        count={1}
                        amount={amount}
                        message="Товар добавлен в корзину"
                        disabled={amount === 0}
                    />
                </div>
            </div>
        </div>
    );
};

const Label = ({ type }) => {
    let info = { label: "", state: "" };
    if (type === "origin") info = { label: LabelGreen, state: "ОРИГИНАЛ" };
    else if (type === "analog") info = { label: LabelBlue, state: "АНАЛОГ" };
    return (
        <React.Fragment>
            <div className="state">{info.state}</div>
            <div className="label">
                <img src={info.label} alt="Label" />
            </div>
        </React.Fragment>
    );
};

class CompareOffers extends Component {
    render() {
        const {
            user: { main_search },
        } = this.props;
        // let cheapest_offer = main_search.offer_products[0];
        //
        // main_search.offer_products.forEach((product) => {
        //     if(cheapest_offer.price > product.price) {
        //         cheapest_offer = product;
        //     }
        // });
        //
        // let cheapest_analog =  main_search.analog_products[0].offer_products[0];
        //
        // main_search.analog_products[0].offer_products.forEach((analog) => {
        //     if(cheapest_analog.price > analog.price) {
        //         cheapest_analog = analog;
        //     }
        // });

        return (
            <div className="compare-offers">
                {!main_search.cheapest_offer ? null : (
                    <CompareOferrsBlock
                        type="origin"
                        data={main_search.cheapest_offer}
                        producer_name={main_search.producer_name}
                        article_number={main_search.article_number}
                    />
                )}
                {!main_search.cheapest_analog_offer ? null : (
                    <CompareOferrsBlock
                        type="analog"
                        data={main_search.cheapest_analog_offer}
                        producer_name={
                            main_search.cheapest_analog_product.producer_name
                        }
                        article_number={
                            main_search.cheapest_analog_product.article_number
                        }
                    />
                )}
                {/*{!cheapest_offer ? null :*/}
                {/*<CompareOferrsBlock type="origin" data={cheapest_offer} />*/}
                {/*}*/}
                {/*{!cheapest_analog ? null :*/}
                {/*<CompareOferrsBlock type="analog" data={cheapest_analog} cheapest_analog_product={main.search.cheapest_analog_product}/>*/}
                {/*}*/}
            </div>
        );
    }
}

export default CompareOffers;
