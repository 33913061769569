import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import PartImage from "./PartImage";
import IconButton from "@material-ui/core/IconButton";

import { getSearchParts } from "../../../actions/userActions";

import AlertIcon from "../../../assets/image/catalog-alert-icon.png";
import SearchIcon from "../../../assets/image/catalog_search_icon.png";
import CloseDialogIcon from "../../../assets/image/Mask.png";
import "./SpecificPart.scss";

const classes_def = {
    root: "default_dialog_root",
    paper: "paper_custom_dialog dialog-wrapper-mini",
};

class PartChart extends Component {
    state = {
        open: false,
        info: {},
        additional_info: {},
    };

    componentDidMount = () => {
        this.setState({ loading: false });
    };

    toggleDialog = (info, additional_info) => {
        this.setState(({ open }) => ({
            open: !open,
        }));
        if (info) {
            this.setState({ info });
        } else {
            this.setState({ info: {} });
        }
        if (additional_info) {
            this.setState({ additional_info });
        } else {
            this.setState({ additional_info: {} });
        }
    };

    toggleSearch = (value) => {
        const { getSearchParts, history } = this.props;
        if (!!value && value !== null) {
            getSearchParts(value).then((res) => {
                if (
                    res.payload &&
                    res.payload.status &&
                    res.payload.status === 200
                ) {
                    // history.push(`/main/search/${value}`);
                    window.open(
                        `${window.location.origin}/main/search/${value}`,
                        "_blank"
                    );
                }
            });
        }
    };

    onMouseOver = (id) => {
        const elements = document.getElementsByClassName(id);
        for (let i = 0, len = elements.length; i < len; i++) {
            elements[i].style.border = "2px solid #0253B3";
        }
    };

    onMouseLeave = (id) => {
        const elements = document.getElementsByClassName(id);
        setTimeout(() => {
            for (let i = 0, len = elements.length; i < len; i++) {
                elements[i].style.border = "";
            }
        }, 1000);
    };

    handleClick = (id) => {
        const element = document.getElementsByClassName(id);
        if (!!element[0]) {
            element[0].scrollIntoView({ block: "center", behavior: "smooth" });
        }
    };

    render() {
        const { tree_node_parts } = this.props;
        const { open, info, additional_info } = this.state;

        return (
            <div className="part-chart-wrapper">
                <div className="part-chart-info">
                    <div className="part-chart-title">
                        {tree_node_parts.parts.part_name}
                    </div>
                    <div className="part-table">
                        {Object.values(tree_node_parts.parts.parts).map(
                            (item, idItem) => {
                                return (
                                    <div
                                        key={idItem}
                                        className={`table_row ${
                                            item.standart.part_number
                                        }&`}
                                        onMouseOver={(e) =>
                                            this.onMouseOver(
                                                item.standart.part_number
                                            )
                                        }
                                        onMouseLeave={(e) =>
                                            this.onMouseLeave(
                                                item.standart.part_number
                                            )
                                        }
                                    >
                                        <div
                                            className="row_item"
                                            onClick={(e) =>
                                                this.handleClick(
                                                    item.standart.part_number
                                                )
                                            }
                                        >
                                            {item.standart.part_number}
                                        </div>
                                        <div
                                            className="row_item"
                                            id={item.standart.part_number}
                                            onClick={(e) =>
                                                this.handleClick(
                                                    item.standart.part_number
                                                )
                                            }
                                        >
                                            {item.standart.part_name}
                                        </div>
                                        <div className="row_item">
                                            {item.standart.part_code !== null &&
                                            item.standart.part_code
                                                .split("&nbsp;")
                                                .join("")
                                                .trim() !== "" ? (
                                                <img
                                                    className="catalog-part-info-icons"
                                                    src={AlertIcon}
                                                    alt="AlertIcon"
                                                    onClick={(e) =>
                                                        this.toggleDialog(
                                                            item.standart,
                                                            item.add
                                                        )
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                        <div className="row_item">
                                            {item.standart.part_code !== null &&
                                            item.standart.part_code
                                                .split("&nbsp;")
                                                .join("")
                                                .trim() !== "" ? (
                                                <img
                                                    className="catalog-part-info-icons"
                                                    src={SearchIcon}
                                                    alt="AlertIcon"
                                                    onClick={(e) =>
                                                        this.toggleSearch(
                                                            item.standart.part_code
                                                                .split("&nbsp;")
                                                                .join("")
                                                                .replace(
                                                                    / +/g,
                                                                    ""
                                                                )
                                                        )
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                );
                            }
                        )}
                        <DialogComponent
                            open={open}
                            onClose={this.toggleDialog}
                            classes={classes_def}
                        >
                            {info !== {} && !!info.part_code ? (
                                <Fragment>
                                    <div className="part-dialog-wrapper">
                                        <div className="title">
                                            {info.part_name}
                                        </div>
                                        <div className="part-info-wrapper">
                                            <div>
                                                <div className="part-description">
                                                    Код детали
                                                </div>
                                                <div className="part-information">
                                                    {info.part_code
                                                        .split("&nbsp;")
                                                        .join(" ")}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="part-description">
                                                    Количество
                                                </div>
                                                <div className="part-information">
                                                    {info.part_quantity}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="part-description">
                                                    Номер на схеме
                                                </div>
                                                <div className="part-information">
                                                    {info.part_number}
                                                </div>
                                            </div>
                                        </div>
                                        {additional_info !== {}
                                            ? Object.entries(
                                                  additional_info
                                              ).map((info, infoId) => (
                                                  <div
                                                      className="part-additional-info-wrapper"
                                                      key={infoId}
                                                  >
                                                      <div>
                                                          <div className="part-description">
                                                              {info[0] ===
                                                              "info"
                                                                  ? "Дополнительная информация"
                                                                  : info[0]}
                                                          </div>
                                                          <div className="part-information">
                                                              {info[1] === null
                                                                  ? "-"
                                                                  : info[1]}
                                                          </div>
                                                      </div>
                                                  </div>
                                              ))
                                            : null}
                                    </div>
                                    <IconButton
                                        classes={{
                                            root: "dialog_close_button",
                                        }}
                                        onClick={this.toggleDialog}
                                    >
                                        <img
                                            src={CloseDialogIcon}
                                            alt="close icon"
                                        />
                                    </IconButton>
                                </Fragment>
                            ) : null}
                        </DialogComponent>
                    </div>
                </div>
                <PartImage tree_node_parts={tree_node_parts} />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSearchParts,
        },
        dispatch
    );
}

export default connect(
    null,
    mapDispatchToProps
)(PartChart);
