//auth actions

export const REGISTER           = 'REGISTER';
export const REGISTER_SUCCESS   = 'REGISTER_SUCCESS';
export const REGISTER_FAIL      = 'REGISTER_FAIL';

export const LOGIN_GOOGLE           = 'LOGIN_GOOGLE';
export const LOGIN_GOOGLE_SUCCESS   = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL      = 'LOGIN_GOOGLE_FAIL';

export const POST_REGISTER_GOOGLE           = 'POST_REGISTER_GOOGLE';
export const POST_REGISTER_GOOGLE_SUCCESS   = 'POST_REGISTER_GOOGLE_SUCCESS';
export const POST_REGISTER_GOOGLE_FAIL      = 'POST_REGISTER_GOOGLE_FAIL';

export const LOGIN           = 'LOGIN';
export const LOGIN_SUCCESS   = 'LOGIN_SUCCESS';
export const LOGIN_FAIL      = 'LOGIN_FAIL';

export const ACTIVATION = 'ACTIVATION';

export const CLEAN_ERROR = 'CLEAN_ERROR';

export const RESET_PASSWORD_FIRST = 'RESET_PASSWORD_FIRST';

export const RESET_PASSWORD_SECOND = 'RESET_PASSWORD_SECOND';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

//user actions

export const INFO = 'INFO';
export const INFO_SUCCESS = 'INFO_SUCCESS';
export const INFO_FAIL = 'INFO_FAIL';

export const CHANGE_INFO = 'CHANGE_INFO';
export const CHANGE_INFO_SUCCESS = 'CHANGE_INFO_SUCCESS';
export const CHANGE_INFO_FAIL = 'CHANGE_INFO_FAIL';

export const GET_PROFILE            = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS    = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL       = 'GET_PROFILE_FAIL';

export const PUT_PROFILE            = 'PUT_PROFILE';
export const PUT_PROFILE_SUCCESS    = 'PUT_PROFILE_SUCCESS';
export const PUT_PROFILE_FAIL       = 'PUT_PROFILE_FAIL';

export const PATCH_PROFILE            = 'PATCH_PROFILE';
export const PATCH_PROFILE_SUCCESS    = 'PATCH_PROFILE_SUCCESS';
export const PATCH_PROFILE_FAIL       = 'PATCH_PROFILE_FAIL';

export const CHANGE_PASSWORD_PROFILE            = 'CHANGE_PASSWORD_PROFILE';
export const CHANGE_PASSWORD_PROFILE_SUCCESS    = 'CHANGE_PASSWORD_PROFILE_SUCCESS';
export const CHANGE_PASSWORD_PROFILE_FAIL       = 'CHANGE_PASSWORD_PROFILE_FAIL';

export const SET_LOCATION            = 'SET_LOCATION';
export const SET_LOCATION_SUCCESS    = 'SET_LOCATION_SUCCESS';
export const SET_LOCATION_FAIL       = 'SET_LOCATION_FAIL';

export const GET_MAIN_PAGE_CHOSEN_LISTS             =   'GET_MAIN_PAGE_CHOSEN_LISTS';
export const GET_MAIN_PAGE_CHOSEN_LISTS_SUCCESS     =   'GET_MAIN_PAGE_CHOSEN_LISTS_SUCCESS';
export const GET_MAIN_PAGE_CHOSEN_LISTS_FAIL        =   'GET_MAIN_PAGE_CHOSEN_LISTS_FAIL';

export const GET_LISTNAME_CHOSEN_PRODUCTS             =   'GET_LISTNAME_CHOSEN_PRODUCTS';
export const GET_LISTNAME_CHOSEN_PRODUCTS_SUCCESS     =   'GET_LISTNAME_CHOSEN_PRODUCTS_SUCCESS';
export const GET_LISTNAME_CHOSEN_PRODUCTS_FAIL        =   'GET_LISTNAME_CHOSEN_PRODUCTS_FAIL';

export const POST_LISTNAME_CHOSEN_PRODUCTS             =   'POST_LISTNAME_CHOSEN_PRODUCTS';
export const POST_LISTNAME_CHOSEN_PRODUCTS_SUCCESS     =   'POST_LISTNAME_CHOSEN_PRODUCTS_SUCCESS';
export const POST_LISTNAME_CHOSEN_PRODUCTS_FAIL        =   'POST_LISTNAME_CHOSEN_PRODUCTS_FAIL';

export const CHANGE_LISTNAME_CHOSEN_PRODUCTS             =   'CHANGE_LISTNAME_CHOSEN_PRODUCTS';
export const CHANGE_LISTNAME_CHOSEN_PRODUCTS_SUCCESS     =   'CHANGE_LISTNAME_CHOSEN_PRODUCTS_SUCCESS';
export const CHANGE_LISTNAME_CHOSEN_PRODUCTS_FAIL        =   'CHANGE_LISTNAME_CHOSEN_PRODUCTS_FAIL';

export const DELETE_LISTNAME_CHOSEN_PRODUCTS             =   'DELETE_LISTNAME_CHOSEN_PRODUCTS';
export const DELETE_LISTNAME_CHOSEN_PRODUCTS_SUCCESS     =   'DELETE_LISTNAME_CHOSEN_PRODUCTS_SUCCESS';
export const DELETE_LISTNAME_CHOSEN_PRODUCTS_FAIL        =   'DELETE_LISTNAME_CHOSEN_PRODUCTS_FAIL';

export const INIT_FAVOURITES_LIST             =   'INIT_FAVOURITES_LIST';

export const GET_CHOSEN_PRODUCTS             =   'GET_CHOSEN_PRODUCTS';
export const GET_CHOSEN_PRODUCTS_SUCCESS     =   'GET_CHOSEN_PRODUCTS_SUCCESS';
export const GET_CHOSEN_PRODUCTS_FAIL        =   'GET_CHOSEN_PRODUCTS_FAIL';

export const POST_NEW_CHOSEN_PRODUCT             =   'POST_NEW_CHOSEN_PRODUCT';
export const POST_NEW_CHOSEN_PRODUCT_SUCCESS     =   'POST_NEW_CHOSEN_PRODUCT_SUCCESS';
export const POST_NEW_CHOSEN_PRODUCT_FAIL        =   'POST_NEW_CHOSEN_PRODUCT_FAIL';

export const GET_PART_DETAIL             =   'GET_PART_DETAIL';
export const GET_PART_DETAIL_SUCCESS     =   'GET_PART_DETAIL_SUCCESS';
export const GET_PART_DETAIL_FAIL        =   'GET_PART_DETAIL_FAIL';

export const DELETE_ITEMS_FROM_FAVORITE             =   'DELETE_ITEMS_FROM_FAVORITE';
export const DELETE_ITEMS_FROM_FAVORITE_SUCCESS     =   'DELETE_ITEMS_FROM_FAVORITE_SUCCESS';
export const DELETE_ITEMS_FROM_FAVORITE_FAIL        =   'DELETE_ITEMS_FROM_FAVORITE_FAIL';

export const DELETE_SELECTED_ITEM_FROM_FAVORITE             =   'DELETE_SELECTED_ITEM_FROM_FAVORITE';
export const DELETE_SELECTED_ITEM_FROM_FAVORITE_SUCCESS     =   'DELETE_SELECTED_ITEM_FROM_FAVORITE_SUCCESS';
export const DELETE_SELECTED_ITEM_FROM_FAVORITE_FAIL        =   'DELETE_SELECTED_ITEM_FROM_FAVORITE_FAIL';

export const DELETE_OFFER_FROM_FAVORITE             =   'DELETE_OFFER_FROM_FAVORITE';
export const DELETE_OFFER_FROM_FAVORITE_SUCCESS     =   'DELETE_OFFER_FROM_FAVORITE_SUCCESS';
export const DELETE_OFFER_FROM_FAVORITE_FAIL        =   'DELETE_OFFER_FROM_FAVORITE_FAIL';

export const CHANGE_COMMENT             =   'CHANGE_COMMENT';
export const CHANGE_COMMENT_SUCCESS     =   'CHANGE_COMMENT_SUCCESS';
export const CHANGE_COMMENT_FAIL        =   'CHANGE_COMMENT_FAIL';

export const GET_SEARCH_PARTS             =   'GET_SEARCH_PARTS';
export const GET_SEARCH_PARTS_SUCCESS     =   'GET_SEARCH_PARTS_SUCCESS';
export const GET_SEARCH_PARTS_FAIL        =   'GET_SEARCH_PARTS_FAIL';

export const PATCH_COMMENT             =   'PATCH_COMMENT';
export const PATCH_COMMENT_SUCCESS     =   'PATCH_COMMENT_SUCCESS';
export const PATCH_COMMENT_FAIL        =   'PATCH_COMMENT_FAIL';

export const SEARCH_BY_VIN             =   'SEARCH_BY_VIN';
export const SEARCH_BY_VIN_SUCCESS     =   'SEARCH_BY_VIN_SUCCESS';
export const SEARCH_BY_VIN_FAIL        =   'SEARCH_BY_VIN_FAIL';

// orders actions

export const GET_ORDERS            = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS    = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL       = 'GET_ORDERS_FAIL';

export const GET_ORDER            = 'GET_ORDER';
export const GET_ORDER_SUCCESS    = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL       = 'GET_ORDER_FAIL';

export const GET_SUPPLIER_INFO              = 'GET_SUPPLIER_INFO';
export const GET_SUPPLIER_INFO_SUCCESS      = 'GET_SUPPLIER_INFO_SUCCESS';
export const GET_SUPPLIER_INFO_FAIL         = 'GET_SUPPLIER_INFO_FAIL';

export const GET_SUPPLIER_REVIEWS           = 'GET_SUPPLIER_REVIEWS';
export const GET_SUPPLIER_REVIEWS_SUCCESS   = 'GET_SUPPLIER_REVIEWS_SUCCESS';
export const GET_SUPPLIER_REVIEWS_FAIL      = 'GET_SUPPLIER_REVIEWS_FAIL';


export const POST_SUPPLIER_REVIEWS          = 'POST_SUPPLIER_REVIEWS';
export const POST_SUPPLIER_REVIEWS_SUCCESS  = 'POST_SUPPLIER_REVIEWS_SUCCESS';
export const POST_SUPPLIER_REVIEWS_FAIL     = 'POST_SUPPLIER_REVIEWS_FAIL';


export const CANCEL_ORDER           = 'CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS   = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAIL      = 'CANCEL_ORDER_FAIL';

export const POST_ORDER_COMMENT           = 'POST_ORDER_COMMENT';
export const POST_ORDER_COMMENT_SUCCESS   = 'POST_ORDER_COMMENT_SUCCESS';
export const POST_ORDER_COMMENT_FAIL      = 'POST_ORDER_COMMENT_FAIL';

// notifications actions

export const GET_NOTIFICATIONS            = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS    = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL       = 'GET_NOTIFICATIONS_FAIL';

// update actions

export const CURRENT_URL            = 'CURRENT_URL';

//basket actions

export const GET_BASKET                 = 'GET_BASKET';
export const GET_BASKET_SUCCESS         = 'GET_BASKET_SUCCESS';
export const GET_BASKET_FAIL            = 'GET_BASKET_FAIL';

//GET_ORDER_BASKET
export const GET_ORDER_BASKET             = 'GET_ORDER_BASKET';
export const GET_ORDER_BASKET_SUCCESS     = 'GET_ORDER_BASKET_SUCCESS';
export const GET_ORDER_BASKET_FAIL        = 'GET_ORDER_BASKET_FAIL';

export const BUY_ALL_BASKET             = 'BUY_ALL_BASKET';
export const BUY_ALL_BASKET_SUCCESS     = 'BUY_ALL_BASKET_SUCCESS';
export const BUY_ALL_BASKET_FAIL        = 'BUY_ALL_BASKET_FAIL';

export const BUY_ORDER_BASKET            = 'BUY_ORDER_BASKET';
export const BUY_ORDER_BASKET_SUCCESS    = 'BUY_ORDER_BASKET_SUCCESS';
export const BUY_ORDER_BASKET_FAIL       = 'BUY_ORDER_BASKET_FAIL';

export const DELETE_ALL_BASKET             = 'DELETE_ALL_BASKET';
export const DELETE_ALL_BASKET_SUCCESS     = 'DELETE_ALL_BASKET_SUCCESS';
export const DELETE_ALL_BASKET_FAIL        = 'DELETE_ALL_BASKET_FAIL';

export const UPDATE_OFFERT            = 'UPDATE_OFFERT';
export const UPDATE_OFFERT_SUCCESS    = 'UPDATE_OFFERT_SUCCESS';
export const UPDATE_OFFERT_FAIL       = 'UPDATE_OFFERT_FAIL';

//catalog actions

export const GET_CATALOGS_AUTO            = 'GET_CATALOGS_AUTO';
export const GET_CATALOGS_AUTO_SUCCESS    = 'GET_CATALOGS_AUTO_SUCCESS';
export const GET_CATALOGS_AUTO_FAIL       = 'GET_CATALOGS_AUTO_FAIL';

export const GET_CATALOGS_TRUCK            = 'GET_CATALOGS_TRUCK';
export const GET_CATALOGS_TRUCK_SUCCESS    = 'GET_CATALOGS_TRUCK_SUCCESS';
export const GET_CATALOGS_TRUCK_FAIL       = 'GET_CATALOGS_TRUCK_FAIL';

export const GET_CATALOGS_MOTO            = 'GET_CATALOGS_MOTO';
export const GET_CATALOGS_MOTO_SUCCESS    = 'GET_CATALOGS_MOTO_SUCCESS';
export const GET_CATALOGS_MOTO_FAIL       = 'GET_CATALOGS_MOTO_FAIL';

export const GET_CATALOGS_MODEL            = 'GET_CATALOGS_MODEL';
export const GET_CATALOGS_MODEL_SUCCESS    = 'GET_CATALOGS_MODEL_SUCCESS';
export const GET_CATALOGS_MODEL_FAIL       = 'GET_CATALOGS_MODEL_FAIL';

export const GET_PARAMS_SET            = 'GET_PARAMS_SET';
export const GET_PARAMS_SET_SUCCESS    = 'GET_PARAMS_SET_SUCCESS';
export const GET_PARAMS_SET_FAIL       = 'GET_PARAMS_SET_FAIL';

export const GET_MODIFICATION            = 'GET_MODIFICATION';
export const GET_MODIFICATION_SUCCESS    = 'GET_MODIFICATION_SUCCESS';
export const GET_MODIFICATION_FAIL       = 'GET_MODIFICATION_FAIL';

export const GET_PART_GROUPS            = 'GET_PART_GROUPS';
export const GET_PART_GROUPS_SUCCESS    = 'GET_PART_GROUPS_SUCCESS';
export const GET_PART_GROUPS_FAIL       = 'GET_PART_GROUPS_FAIL';

export const GET_TREE            = 'GET_TREE';
export const GET_TREE_SUCCESS    = 'GET_TREE_SUCCESS';
export const GET_TREE_FAIL       = 'GET_TREE_FAIL';

export const SEARCH_TREE_NODE            = 'SEARCH_TREE_NODE';
export const SEARCH_TREE_NODE_SUCCESS    = 'SEARCH_TREE_NODE_SUCCESS';
export const SEARCH_TREE_NODE_FAIL       = 'SEARCH_TREE_NODE_FAIL';

export const GET_TREE_NODE_PARTS            = 'GET_TREE_NODE_PARTS';
export const GET_TREE_NODE_PARTS_SUCCESS    = 'GET_TREE_NODE_PARTS_SUCCESS';
export const GET_TREE_NODE_PARTS_FAIL       = 'GET_TREE_NODE_PARTS_FAIL';


export const POST_SENDMESAGE_CONTACTS        =   'POST_SENDMESAGE_CONTACTS';