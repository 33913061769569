import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Rating from '../../HelperComponents/Rating/Rating';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import {YMaps, Map, Placemark} from 'react-yandex-maps';
import {days, isArray} from "../../../helpers/functions";
import InfoContacts from '../../Orders/OrderDetail/InfoContacts/InfoContacts';

import './Supplier.scss';

class Supplier extends Component {

    componentDidMount = () => {
        setTimeout(() => {
            const first = document.querySelector('.button-supplier');
            if (first !== null) {first.click()}
        }, 500);
    };


    render() {
        const {supplier_info, mapOptions, open, toggleOpen} = this.props;
        return (
            <Fragment>
                <div>
                    <div className="reviews-wrapper">
                        <div className="rating-block">
                            <div className="name review-name">&laquo;{supplier_info.company}&raquo;</div>
                            <Rating value={supplier_info.company_rating} rating_info/>
                            <div className="commends review-commends" onClick={this.props.handleClick}>
                                {supplier_info.total_reviews} отзыв{supplier_info.total_reviews === 1 ? null : supplier_info.total_reviews > 1 && supplier_info.total_reviews < 5 ? "a" : "ов"}
                            </div>
                        </div>
                    </div>

                    <InfoContacts phones={supplier_info.phones}/>

                </div>
                <Fragment>
                    <span className="block_label">Представительства</span>
                    {isArray(supplier_info.addresses) ?
                        supplier_info.addresses.map(({id, coordinates, workingtime}) => {
                            let idx = mapOptions.findIndex(el => el.id === id);
                            return (
                                <div className={`expansion-block ${open.includes(id) ? "expansion-block-active" : ""} `} key={id} >
                                    <div className="expansion-title title-supplier flex-center" >
                                        <p className="button-supplier" onClick={() => toggleOpen(id)}>
                                            {coordinates}
                                            <IconButton color="inherit">
                                                {open.includes(id)
                                                    ? <ExpandLessIcon/>
                                                    : <ExpandMoreIcon/>
                                                }
                                            </IconButton>
                                        </p>
                                    </div>
                                    <div className="expansion-body flex-center"
                                         style={{display: 'flex'}}>
                                        <div className="schedule">
                                            <span className="block_label">График работы</span>
                                            {workingtime.map(({working_day, from_hour, to_hour, is_enabled}, i) => (
                                                <div
                                                    className={`schedule-time ${!is_enabled ? "schedule-time_inactive" : null}`}
                                                    key={i}>
                                                    <span className="time_day">
                                                        {days.filter(el => el.name === working_day)[0].label}
                                                    </span>
                                                            <span className="time_value">
                                                        {is_enabled
                                                            ? `${from_hour} - ${to_hour}`
                                                            : 'Выходной'
                                                        }
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="schedule_map_wrapper">
                                            <YMaps>
                                                <Map
                                                    width={675}
                                                    height={265}
                                                    state={mapOptions[idx]}
                                                >
                                                    <Placemark geometry={mapOptions[idx] && mapOptions[idx].center}/>
                                                </Map>
                                            </YMaps>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        :
                        <p className="info_section_no_items block_value">У вас нет добавленых представительств</p>
                    }
                </Fragment>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        orders: state.orders
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //login
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
