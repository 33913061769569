import React from 'react';

import LofInIcon from '../../../assets/image/log_in.svg';

import './LogInToAccess.scss';

const LogInToAccess = ({ history }) => (
    <div className="dashboard-catalog">
        <div className="title-wrapper">Каталоги автозапчастей</div>
        <div className="login_to_access_wrapper">
            <img src={LofInIcon} alt="log in"/>
            <div className="title">Войдите в аккаунт</div>
            <div className="description">Пожалуйста, <span onClick={() => history.push("/auth")}>авторизуйтесь</span> или <span onClick={() => history.push("/auth/register")}>зарегистрируйтесь</span> для доступа к каталогу</div>
        </div>
    </div>
);

export default LogInToAccess;