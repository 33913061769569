import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import DatePicker from "react-datepicker";
import moment from "moment";
import OrderItem from "./OrderItem/OrderItem";
import Loader from "../HelperComponents/Loader/Loader";
import Pagination from "../HelperComponents/Pagination/Pagination";
import NoItems from "../HelperComponents/NoItems/NoItems";

import { isArray } from "../../helpers/functions";
import { getOrders } from "../../actions/ordersActions";

import "react-datepicker/dist/react-datepicker.css";
import "./Orders.scss";
import OrdersIcon from "../../assets/image/no_orders.png";
import "../Search/Search.scss";

import Preloader from "../HelperComponents/Preloader/Preloader";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import { SuccessMessage } from "../Buttons/BuyButton/BuyButton";

class Orders extends Component {
    state = {
        loading: true,
        status: "all",
        valueSearch: "",
        activePage: 1,
        dateFrom: null,
        dateTo: null,
        projects_list: [
            // { label: "Все", value: "all" },
            { label: "Новый", value: "new" },
            { label: "Готов к выдаче", value: "processing" },
            { label: "Выполнен", value: "complete" },
            { label: "Отказ", value: "denied" },
        ],
        success_buy: false,
    };

    componentDidMount() {
        const { history } = this.props;
        let status = "all",
            search = "",
            from = null,
            to = null,
            page = 1;
        setTimeout(() => {
            this.doRequest(status, search, from, to, page);
        }, 0);

        if (history.location.state !== undefined) {
            this.setState({
                success_buy: history.location.state.success_buy,
            });
        }
    }

    /*handleChange = name => event => {
          const {valueSearch, dateFrom, dateTo} = this.state;
          this.setState({ [name]: event.target.value });
          this.doRequest( event.target.value, valueSearch, dateFrom, dateTo, 1);
      };*/
    handleChange = (name) => (event) => {
        const { valueSearch, dateFrom, dateTo } = this.state;
        this.setState({ [name]: event });
        this.doRequest(
            event !== null ? event : "all",
            valueSearch,
            dateFrom,
            dateTo,
            1
        );
    };

    changeValueSearch = (e) => {
        this.setState({ valueSearch: e.target.value });
    };

    handleSearch = (event) => {
        const {
            status,
            valueSearch,
            dateFrom,
            dateTo,
            activePage,
        } = this.state;
        if (event.keyCode === 13) {
            this.setState({ activePage: 1 });
            this.doRequest(status, valueSearch, dateFrom, dateTo, activePage);
        }
    };

    changePage = (pageNumber) => {
        const { status, valueSearch, dateFrom, dateTo } = this.state;
        this.setState({ activePage: pageNumber.selected + 1 });
        this.doRequest(
            status,
            valueSearch,
            dateFrom,
            dateTo,
            pageNumber.selected + 1
        );
    };

    handleDateChange = (date, id) => {
        const { status, valueSearch, dateFrom, dateTo } = this.state;
        let obj = {};
        obj[`${id}`] = date;
        this.setState(obj);
        this.doRequest(
            status,
            valueSearch,
            id === "dateFrom" ? date : dateFrom,
            id === "dateTo" ? date : dateTo,
            1
        );
    };

    doRequest = (status, search, from, to, page) => {
        const { getOrders, history } = this.props;
        let arr = [];
        console.log(status);
        arr.push(`page=${page}`);
        arr.push(`page_size=12`);
        if (status !== "all" && status !== null) {
            arr.push(`filter=${status.value}`);
        }
        if (search.length > 0) {
            arr.push(`query=${search}`);
        }
        if (from !== null) {
            arr.push(`from=${moment(from).format("YYYY-MM-DD")}`);
        }
        if (to !== null) {
            arr.push(`to=${moment(to).format("YYYY-MM-DD")}`);
        }
        getOrders(arr).then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                if (res.payload.data.results.length === 0) {
                    this.setState({ empty: true });
                } else {
                    this.setState({ empty: false });
                }
            }
            if (res.error && res.error.response.status === 401) {
                localStorage.clear();
                history.push("/main");
            }
        });
    };

    stateBuyFalse = () => {
        const { history } = this.props;
        this.setState((prevState) => ({ success_buy: false }));
        history.replace(history.location.pathname, {});
    };

    months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ];
    days = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    locale = {
        localize: {
            month: (n) => this.months[n],
            day: (n) => this.days[n],
        },
        formatLong: {},
    };

    render() {
        const {
            orders: { all_orders },
            history,
        } = this.props;
        const {
            status,
            valueSearch,
            activePage,
            dateFrom,
            dateTo,
            projects_list,
            success_buy,
        } = this.state;

        let count_buy = null;
        if (history.location.state !== undefined) {
            count_buy = history.location.state.count_buy;
        }

        let countAllOrders = all_orders.count;
        if (!all_orders.results) return <Preloader />;

        return (
            <main className="container orders-main-block">
                {success_buy ? (
                    <SuccessMessage
                        close={this.stateBuyFalse}
                        message={
                            count_buy
                                ? "Заказы успешно оформлены"
                                : "Заказ успешно оформлен"
                        }
                    />
                ) : null}
                <div className="title-block">
                    <h1>Мои заказы</h1>
                </div>
                <div className="flex-center-btw">
                    <div className="flex-center-start">
                        <div className="page_options_wrapper">
                            <div className="search_wrapper search_wrapper_small">
                                <InputBase
                                    placeholder="Поиск…"
                                    onKeyDown={this.handleSearch}
                                    onChange={this.changeValueSearch}
                                    value={valueSearch}
                                    className="search_settings"
                                    classes={{
                                        input: "search_input",
                                    }}
                                />
                                <div className="search_input_icon">
                                    <SearchIcon color="disabled" />
                                </div>
                            </div>
                        </div>
                        <FormControl className="select_wrapper">
                            <SelectComponent
                                value={status}
                                options={projects_list}
                                loading={!isArray(projects_list)}
                                change={this.handleChange("status")}
                                placeholder="Статусы"
                            />
                        </FormControl>
                    </div>
                    <div className="date_picker_wrapper">
                        <div className="date_picker_inner">
                            <p>От</p>
                            <DatePicker
                                selected={dateFrom}
                                selectsStart
                                startDate={dateFrom}
                                endDate={dateTo}
                                dateFormat="dd.MM.yyyy"
                                onChange={(e) =>
                                    this.handleDateChange(e, "dateFrom")
                                }
                                className="myDatePicker"
                                locale={this.locale}
                            />
                        </div>
                        <div className="date_picker_inner">
                            <p>До</p>
                            <DatePicker
                                selected={dateTo}
                                selectsEnd
                                startDate={dateFrom}
                                endDate={dateTo}
                                dateFormat="dd.MM.yyyy"
                                minDate={dateFrom}
                                onChange={(e) =>
                                    this.handleDateChange(e, "dateTo")
                                }
                                className="myDatePicker"
                                locale={this.locale}
                            />
                        </div>
                    </div>
                </div>

                <div className="dashboard-section_content">
                    {!all_orders || !all_orders.results ? (
                        <div className="info_company_loader_wrapper">
                            <Loader />
                        </div>
                    ) : all_orders.results.length === 0 ? (
                        <NoItems>
                            <div className="box-img">
                                <img src={OrdersIcon} alt="edit icon" />
                            </div>
                            <div className="title">
                                {" "}
                                Пока нет ни одного заказа
                            </div>
                            <div className="descriptions">
                                После оформления первого заказа Вы увидите его
                                здесь
                            </div>
                        </NoItems>
                    ) : (
                        all_orders.results.map((order) => (
                            <OrderItem
                                data={order}
                                history={history}
                                key={"order" + order.id}
                                setURL={this.setURL}
                            />
                        ))
                    )}
                </div>
                <div className="orders-pagination">
                    {countAllOrders && countAllOrders > 10 ? (
                        <Pagination
                            active={activePage}
                            pageItemsCount={12}
                            pageTotalCount={countAllOrders}
                            onChange={this.changePage}
                        />
                    ) : null}
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    return {
        orders: state.orders,
        basket: { ...state.basket },
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getOrders,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Orders);
