import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from "react-redux";

import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import RenderField from "../RenderField/RenderField";


import './DialogFavorites.scss';

class NameMileageForm extends Component {

    componentWillUnmount() {
        this.props.reset("NameAndMileageForm");
    }

    render() {
        const { user: { error_text }, openDialogFavorites, onSubmitAction, handleSubmit, valid, submitting, loading } = this.props;
        return(
            <form className="" onSubmit={handleSubmit(onSubmitAction)}>
                <div className="block-info">
                    <div className="textarea-block">
                        <Field name="name" type="text" component={RenderField} label="Название автомобиля"/>
                        <Field name="mileage" type="text" component={RenderField} label="Пробег"/>
                    </div>
                </div>
                <div className="dialog-button-wrapper">
                    <DefaultButton
                        variant="outlined"
                        classes="cancel_btn"
                        onClick={openDialogFavorites}
                    >
                        ОТМЕНА
                    </DefaultButton>
                    <DefaultButton
                        variant="contained"
                        formAction
                        disabled={!valid || submitting}
                        loading={loading}
                    >
                        ДОБАВИТЬ
                    </DefaultButton>
                    {!!error_text && !!error_text.name ?
                        <span className="error-text">{error_text.name[0]}</span> : null
                    }
                </div>
            </form>
        )
    }
}

const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Обязательное поле'
    }
    return errors
};

NameMileageForm = reduxForm({
    form: 'NameAndMileageForm',
    validate,
    enableReinitialize: true
})(NameMileageForm);

const mapStateToProps = (state, props) => {
    return{
        user: state.user,
        initialValues: {
            name: props.name || "",
            vin: props.vin || ""
        }
    }
};

export default connect(mapStateToProps)(NameMileageForm);