import React, { Component } from "react";
import { connect } from "react-redux";
import { withYMaps, YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";
import TemplateProvider from "./Templates/TemplateProvider";

import Loader from "../HelperComponents/Loader/Loader";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";

import Close from "../../assets/image/Mask.png";
import analog_label from "./img/blue_label.png";
import origin_label from "./img/green_label.png";
import icon_analog from "./img/blue.png";
import icon_analog_order from "./img/blue_opacity.png";
import icon_origin from "./img/green.png";
import icon_origin_order from "./img/green_opacity.png";
import circle_marker from "./img/circle_marker.png";
import Shape from "../../assets/image/Shape.png";

import "./ShowMap.scss";

const classes = {
    root: "show-map_dialog_root",
    paper: "paper_custom_dialog",
};

const ConnectedTemplateProvider = withYMaps(TemplateProvider, true, [
    "templateLayoutFactory",
]);

class ShowMap extends Component {
    state = {
        open_modal: false,
        template: null,
        center: [],
        load: false,
    };

    userLocation = () => {
        this.setState({
            center: [
                JSON.parse(localStorage.location).latitude,
                JSON.parse(localStorage.location).longitude,
            ],
        });
        // navigator.geolocation.getCurrentPosition(
        //   (position) => this.setState({center: [position.coords.latitude, position.coords.longitude]}),
        //   (error) => this.setState({center: [55.751244, 37.618423]}),
        // );
    };

    componentDidMount() {
        const {
            user: { profile },
        } = this.props;
        if (!!profile && !!profile.location)
            this.setState({
                center: [profile.location.latitude, profile.location.longitude],
            });
        else this.userLocation();
    }

    componentWillUnmount = () => {};

    stateModal = () =>
        this.setState((prevState) => ({ open_modal: !prevState.open_modal }));

    handleMapAvaliable = (map) => {
        if (!!map) this.load(true);
    };

    load = (stateLoad) => this.setState({ load: stateLoad });

    render() {
        const {
            offer_products,
            analog_products,
            name,
            article_number,
            producer_name,
        } = this.props.main_data;
        const special_data = { name, article_number, producer_name };
        return (
            <React.Fragment>
                <DialogComponent
                    open={this.state.open_modal}
                    close={this.stateModal}
                    classes={classes}
                >
                    <div className="show-map">
                        <div className="close" onClick={this.stateModal}>
                            <img src={Close} alt="close" />
                        </div>
                        <h2>На карте</h2>
                        {!this.state.load ? (
                            <Loader size={80} thickness={2.5} />
                        ) : null}
                        <YMaps>
                            <ConnectedTemplateProvider>
                                {({
                                    templateIcon,
                                    templateBalloon,
                                    templateClustererIcon,
                                    load,
                                    ymaps,
                                }) => (
                                    <Map
                                        onLoad={(map) =>
                                            this.handleMapAvaliable(map)
                                        }
                                        width={1100}
                                        height={620}
                                        className="wrapper_baloon"
                                        state={{
                                            center: this.state.center,
                                            zoom: 9,
                                        }}
                                        modules={["templateLayoutFactory"]}
                                    >
                                        <Clusterer
                                            options={{
                                                groupByCoordinates: false,
                                                clusterDisableClickZoom: true,
                                                clusterHideIconOnBalloonOpen: false,
                                                geoObjectHideIconOnBalloonOpen: false,
                                                clusterIcons: [
                                                    {
                                                        href: circle_marker,
                                                        size: [55, 60],
                                                        offset: [-24, -24],
                                                    },
                                                ],
                                                clusterIconContentLayout: templateClustererIcon,
                                                clusterBalloonContentLayoutHeight: 150,
                                                clusterBalloonContentLayoutWidth: 600,
                                                clusterBalloonItemContentLayout: ymaps.templateLayoutFactory.createClass(
                                                    "{{ properties.balloonContentBody|raw }}"
                                                ),
                                            }}
                                            modules={[
                                                "clusterer.addon.balloon",
                                                "layout.ImageWithContent",
                                            ]}
                                        >
                                            {offer_products.map(
                                                (value, index) => {
                                                    return (
                                                        <PointsMark
                                                            key={index}
                                                            data={value}
                                                            infoToShow={value}
                                                            special_data={
                                                                special_data
                                                            }
                                                            type={"origin"}
                                                            templateBalloon={
                                                                templateBalloon
                                                            }
                                                            templateIcon={
                                                                templateIcon
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                            {analog_products.map((val) => {
                                                return val.offer_products.map(
                                                    (value, index) => {
                                                        return (
                                                            <PointsMark
                                                                key={index}
                                                                data={value}
                                                                infoToShow={val}
                                                                special_data={
                                                                    special_data
                                                                }
                                                                type={"analog"}
                                                                templateBalloon={
                                                                    templateBalloon
                                                                }
                                                                templateIcon={
                                                                    templateIcon
                                                                }
                                                            />
                                                        );
                                                    }
                                                );
                                            })}
                                        </Clusterer>
                                    </Map>
                                )}
                            </ConnectedTemplateProvider>
                        </YMaps>
                    </div>
                </DialogComponent>
                <DefaultButton
                    variant="outlined"
                    classes="default_button_contained"
                    onClick={this.stateModal}
                >
                    Показать на карте
                </DefaultButton>
            </React.Fragment>
        );
    }
}

function renderBody(data, type, special_data, infoToShow) {
    let text = type === "origin" ? "ОРИГИНАЛ" : "АНАЛОГ";
    let underOreder = data.amount
        ? `<div>
                    В наличии
                    <span style="height: 17px; width: 36px; color: #334150;	font-family: Akrobat; font-size: 14px; line-height: 17px;">
                        ${data.amount} шт
                    </span>
                  </div>`
        : `<div></div>`;

    // let deliveryTime = data.delivery_time !== '' ?  `${data.delivery_time}` : '0';
    let deliveryTime =
        data.delivery_time !== ""
            ? `срок доставки <span style="	height: 17px;	width: 36px;	color: #334150;	font-family: Akrobat;	font-size: 14px;	line-height: 17px;">
                      ${data.delivery_time} дн.
                    </span>`
            : "";

    let srcImg = type === "origin" ? origin_label : analog_label;
    let num =
        data.avg_company_rating !== null ? data.avg_company_rating * 20 : 0;
    let formRating = "width:" + num + "%";
    return `<div class="render_body">
               <img  src="${srcImg}" alt="${text}" style="position: absolute; top: -5px; right: 20px;" />
              <div style="display: flex; justify-content: space-between; align-items: flex-end;">
                <div style="display: flex; align-items: baseline; justify-content: space-between; font-family: Akrobat;">
                  <a href="/main/supplier-information/${
                      data.company_id
                  }?offers=${
        document.location.pathname.split("/")[3]
    }" style="color: #0253B3; font-size: 16px; font-family: Akrobat; max-width: 82px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">${
        data.company
    }</a>
                  <div style="display: flex; align-items: inherit;">
                       <div class="star-ratings-css">
                          <div class="star-ratings-css-top" style=${formRating}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
                           <div class="star-ratings-css-bottom"><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
                        </div>
                      <span style="margin-left: 10px; color: #7F8FA4;	font-family: Akrobat;	font-size: 12px;	font-weight: 300;	line-height: 15px;">${
                          data.company_reviews
                      } отзыв</span>
                  </div>
                </div>
                <div style="height: 15px;	width: 48px; color: #334150; font-size: 12px;	line-height: 15px; margin-right: 25px; 	font-family: Akrobat;">
                    ${text}
                </div>
              </div>
              <div style="display: flex; flex-flow: column wrap; color: #7F8FA4; font-size: 14px; font-family: Akrobat; margin-top: 20px;">
                <div style="color: #7F8FA4;	font-family: Akrobat;	font-size: 14px;	font-weight: 300;	line-height: 18px;">
                  <span style="color: #334150; font-weight: 600; margin-right: 7px; font-family: Akrobat;	font-size: 14px;">${
                      type === "origin"
                          ? special_data.producer_name
                          : infoToShow.producer_name
                  }</span>
                  ${
                      type === "origin"
                          ? special_data.article_number
                          : infoToShow.article_number
                  }
                </div>
                <div style="color: #7F8FA4; font-family: Akrobat;	font-size: 14px;	font-weight: 300;	line-height: 18px;">${
                    data.name
                }</div>
              </div>
              <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-top: 10px;">
                <div style="display: flex; justify-content: space-between;	height: 15px;
                            color: #7F8FA4;	font-family: Akrobat;	font-size: 12px;	font-weight: 300;	line-height: 15px;">
                  ${underOreder}
                  <div style="margin-left: 40px;">
                    ${deliveryTime}
                  </div>
                </div>
                <div style="height: 29px !important;	color: #334150; font-size: 23px !important;	line-height: 34px; font-family: Akrobat;">
                  ${data.price} ₽
                </div>
                <button 
                    onclick="findAndBuyOffer('${data.id}')"
                    style="font-family: Akrobat; color: #FFFFFF; font-size: 13px; height: 36px; width: 120px; border-radius: 3px; background-color: #16D761; box-shadow: 0 2px 4px 0 rgba(139, 139, 139, 0.5); line-height: 16px; cursor: pointer;"
                >
                    <img src="${Shape}" alt="cart" style="margin-right: 4px;" /> КУПИТЬ
                </button>
              </div>
              </div>`;
}

const PointsMark = ({
    data,
    type,
    special_data,
    templateBalloon,
    templateIcon,
    infoToShow,
}) => {
    const { location, amount } = data;
    const { producer_name } = infoToShow;
    const icon =
        type === "origin"
            ? !amount
                ? icon_origin_order
                : icon_origin
            : !amount
            ? icon_analog_order
            : icon_analog;
    let coord = [];
    if (location) coord = [location.latitude, location.longitude];
    else return false;
    // let rating = (avg_company_rating) ? avg_company_rating.toFixed(1) : null;
    // let deliveryTime = (delivery_day) ? delivery_day :
    //     (delivery_time) ? new Date(parseInt(delivery_time)).getDay() : null;
    return (
        <Placemark
            geometry={coord}
            properties={{
                balloonContentBody: renderBody(
                    data,
                    type,
                    special_data,
                    infoToShow
                ),
                clusterCaption: `<strong>${
                    type === "origin"
                        ? special_data.producer_name
                        : producer_name
                }</strong>`,
                price: data.price,
            }}
            options={{
                // balloonContentLayout: YMaps.templateLayoutFactory.createClass('<p>$[[options.contentBodyLayout]]</p>'),
                balloonContentOffset: [-150, -150],
                iconLayout: "default#imageWithContent",
                iconImageHref: icon,
                iconImageSize: [110, 50],
                iconImageOffset: [-25, -25],
                iconContentOffset: [1, 10],
                iconContentLayout: templateIcon,
            }}
            modules={["geoObject.addon.balloon", "layout.ImageWithContent"]}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.user,
    };
};

export default connect(mapStateToProps)(ShowMap);
