import * as types from './constants.jsx';

export function getTotalCount(){
  const data = Number((!!localStorage.getItem('total_count')) ? localStorage.getItem('total_count') : null);
  return {
    type: 'GET_TOTAL_COUNT',
    payload: data
  }
}

export function getBasket() {
    return {
        type: types.GET_BASKET,
        payload: {
            client: 'default',
            request: {
                url: `/customer/set-cart/`,
                method: "get",
            }
        }
    };
}

export function showBasket(obj) {
    return {
        type: types.GET_BASKET,
        payload: {
            client: 'default',
            request: {
                url: `/customer/cart/?cart=${obj}`,
                method: "get"
            }
        }
    };
}

export function showOrderBasket(obj) {
    return {
        type: types.GET_ORDER_BASKET,
        payload: {
            client: 'default',
            request: {
                url: `/customer/cart/?cart=${obj}`,
                method: "get"
            }
        }
    };
}

export function updateBasket(obj) {
  const data = {data: obj}
    return {
        type: types.GET_BASKET,
        payload: {
            client: 'default',
            request: {
                url: `/customer/set-cart/`,
                method: "post",
                data
            }
        }
    };
}

export function updateOffert(obj) {
  const data = {data: obj}
    return {
        type: types.UPDATE_OFFERT,
        payload: {
            client: 'default',
            request: {
                url: `/customer/set-cart/`,
                method: "put",
                data
            }
        }
    };
}

export function buyOrderBasket(id) {
    return {
        type: types.BUY_ALL_BASKET,
        payload: {
            client: 'default',
            request: {
                url: `/customer/cart/buy/${id}/`,
                method: "post"
            }
        }
    };
}

export function buyAllBasket() {
    return {
        type: types.BUY_ALL_BASKET,
        payload: {
            client: 'default',
            request: {
                url: `/customer/cart/buy/`,
                method: "post"
            }
        }
    };
}

export function deleteAllBasket() {
    return {
        type: types.DELETE_ALL_BASKET,
        payload: {
            client: 'default',
            request: {
                url: `/customer/cart/buy/`,
                method: "delete"
            }
        }
    };
}
