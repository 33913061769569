import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {updateOffert} from "../../actions/basketActions";

import DialogComponent from '../HelperComponents/DialogComponent/DialogComponent';
import {ConfirmModal} from './DialogModalRender';

import MinusIcon from '../../assets/image/minus.png';
import PlusIcon from '../../assets/image/plus.png';

const classes = {
    root: 'favorites_dialog_root',
    paper:'paper_custom_dialog',
}

class BasketOffers extends Component {
    state = {
        open_modal: false,
        id: null,
        newCount: this.props.offer.quantity,
        newPrice: this.props.offer.offer_price,
        updateCount: false,
        success_update: null,
    };

    componentDidUpdate = (prevProps) => {
      if(prevProps.offer !== this.props.offer) this.setState({
        newCount: this.props.offer.quantity, newPrice: this.props.offer.price,
      })
    }

    stateModal = (id = null) => this.setState(prevState => ({open_modal: !prevState.open_modal, id: id}));

    confirm = () => {
        if(!!this.props.profile.id) this.props.updateOffert({[this.state.id]: 0});
        else this.writingLocal(this.state.id, 0);
        this.stateModal(null);
    }

    writingLocal = (id, count) => {
      let result = JSON.parse(localStorage.getItem("parts_cart"));
      if(count === 0) delete result[id];
      else result[id] = count;
      localStorage.setItem("parts_cart", JSON.stringify(result));
      this.props.localUpdate();
    }

    stateCount = (id, value) => {
      const {newCount, newPrice} = this.state;
      const {price} = this.props.offer;
      if(value === 'update') this.props.updateData('update');
      if(newCount + value === 0) this.stateModal(id)
      else if(newCount + value <= this.props.offer.amount){
        const update_price = newPrice + (price * value);
        this.setState({newCount: newCount + value, newPrice: update_price, updateCount: true});
        this.props.updateData(value, price * value);
      }
    }

    onMouseLeaveHandler(id) {
      if(this.state.updateCount){
        if(!!this.props.profile.id) {
          this.props.loader();
          this.props.updateOffert({[id]: this.state.newCount});
        }
        else this.writingLocal(id, this.state.newCount);
        this.setState({updateCount: false});
      }
    }

    render(){
        const {offer: {id, name, article_number, producer, price}} = this.props;
        const { newCount } = this.state;
        return(
            <div className="basket-items-wrapper">
                <DialogComponent open={this.state.open_modal} close={this.stateModal} classes={classes} style={{height: '375px'}}>
                    <ConfirmModal onClose={this.stateModal} confirm={this.confirm} />
                </DialogComponent>
                <div className="product-block-wrapper">
                  <div className="name-product">
                      <div className="info">Сведения о товаре</div>
                      <div className="name-info">
                          <div>{name}</div>
                          <p>•</p>
                          <span>{article_number}</span>
                      </div>
                      <div className="firms">{producer}</div>
                  </div>
                  <div className="info-item-block">
                    <div className="quantity-block" onMouseLeave={() => this.onMouseLeaveHandler(id)}>
                        <p>Количество</p>
                        <div className="quantity">
                            <button onClick={() => this.stateCount(id, -1)}>
                                <img src={MinusIcon} alt="clock"/>
                            </button>
                            <span>{newCount}</span>
                            <button onClick={() => this.stateCount(id, +1)}>
                                <img src={PlusIcon} alt="clock"/>
                            </button>
                        </div>
                    </div>
                    <div className="price">{price * newCount} ₽</div>
                  </div>
                </div>
                <div>
                    <button className="delete-item" onClick={() => this.stateModal(id)}>Удалить</button>
                </div>
            </div>
        )
    }
}

function mapStateToProps({basket, user}) {
    return{
        ...basket, ...user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateOffert
    }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(BasketOffers);
