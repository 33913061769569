import * as types from "../actions/constants";

const INITIAL_STATE = {
    notifications_list: {load: false, list: []},
    error: {}
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.GET_NOTIFICATIONS_SUCCESS :
            return {...state, notifications_list : {load: true, list: action.payload.data} };
        case types.GET_NOTIFICATIONS_FAIL :
            return {...state, error : action.error.response.data};
        default:
            return state;
    }
}