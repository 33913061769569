import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const configureSentry = ({ dsn, dev, prod }) => {
    const host = window.location.host;
    let environment;
    if (host.includes(dev)) environment = "development";
    if (host.includes(prod)) environment = "production";

    if (!host.includes("localhost")) {
        return Sentry.init({
            dsn,
            autoSessionTracking: true,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
            environment
        });
    }
};

export function getRating(rating) {
    switch (rating) {
        case 1:
            return "Очень плохо";
        case 2:
            return "Плохо";
        case 3:
            return "Неплохо";
        case 4:
            return "Хорошо";
        case 5:
            return "Отлично";
        default:
            return null
    }
}

export function isArray(arr) {
    return arr && arr.length && arr.length > 0;
}

export function getStatus(status) {
    switch (status) {
        case 'new':
            return "Новый";
        case 'processing':
            return "Готов к выдаче";
        case 'complete':
            return "Выполнен";
        case 'denied':
            return "Отказ";
        default:
            return null
    }
}

export function getStatusColor(status) {
    switch (status) {
        case 'new':
            return "#0253B3";
        case 'processing':
            return "#249AF3";
        case 'complete':
            return "#16D761";
        case 'denied':
            return "#D0021B";
        default:
            return null
    }
}

export const days = [
    {name: 'monday', label: 'Пн'},
    {name: 'tuesday', label: 'Вт'},
    {name: 'wednesday', label: 'Ср'},
    {name: 'thursday', label: 'Чт'},
    {name: 'friday', label: 'Пт'},
    {name: 'saturday', label: 'Сб'},
    {name: 'sunday', label: 'Вс'}
];

export function hasExtension(fileName, exts) {
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
}