import * as types from './constants.jsx';

//auth actions

export function postRegister(data) {
    return {
        type: types.REGISTER,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/register/`,
                method: "post",
                data
            }
        }
    };
}

export function postLogin(data) {
    return {
        type: types.LOGIN,
        payload: {
            client: 'default',
            request: {
                url: `/customer/login/`,
                method: "post",
                data
            }
        }
    };
}

export function postLoginGoogle(data) {
    return {
        type: types.LOGIN_GOOGLE,
        payload: {
            client: 'default',
            request: {
                url: `/customer/google-login/`,
                method: "post",
                data
            }
        }
    };
}

export function postActivation(data) {
    return {
        type: types.ACTIVATION,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/verify-registration/`,
                method: "post",
                data
            }
        }
    };
}

export function postResetPasswordFirst(data) {
    return {
        type: types.RESET_PASSWORD_FIRST,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/send-reset-password-link/`,
                method: "post",
                data
            }
        }
    };
}

export function postResetPasswordSecond(data) {
    return {
        type: types.RESET_PASSWORD_SECOND,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/reset-password/`,
                method: "post",
                data
            }
        }
    };
}

export function patchChangePassword(data) {
    return {
        type: types.CHANGE_PASSWORD,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/change_password`,
                method: "patch",
                data
            }
        }
    };
}