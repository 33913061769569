import React, { Component } from 'react';
import { connect } from "react-redux";
import PartsComponent from "./PartsComponent";
import InputBase from '@material-ui/core/InputBase';
import RenderCollapse from '../../HelperComponents/CollapsibleComponent/Collapsible';

import SearchIcon from '../../../assets/image/search_grey.png';

import './PartsOfCar.scss';

class TreeComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            valueSearch: '',
            path: '',
            leaf: 0,
            node: props.catalog.tree.tree,
            nodeName: '',
            openedItem: '',
            previousOpenedItem: '',
            oldOpenedItem: ''
        };
    }

    changeValueSearch = (e) => {
        this.setState({valueSearch: e.target.value});
        const collapsibleElements = document.getElementsByClassName('Collapsible');
        const leafElements = document.getElementsByClassName('leaf-style');
        const elements = [...collapsibleElements, ...leafElements];

        for (let i = 0, len = elements.length; i < len;  ++i) {
            if (!elements[i].textContent.toLowerCase().includes(e.target.value.toLowerCase()))
            {elements[i].style.display = "none"}
            else {
                elements[i].style.display = "block";
            }
        }
    };

    handleClick = (itemName) => {
        this.setState(({openedItem, previousOpenedItem}) => ({
            openedItem: itemName,
            previousOpenedItem: openedItem,
            oldOpenedItem: previousOpenedItem
        }));
    };

    handleTreeClick = (path, leaf, node, nodeName) => {
        this.setState({path: path, leaf: leaf, nodeName: nodeName});
        if (node !== []) {
            this.setState({node})
        }
    };


    render(){
        const { catalog:{ tree }, ...restProps } = this.props;
        const { valueSearch, ...restState } = this.state;

        return (
            <div className="tree-wrapper">
                <div className="tree-catalog">

                    <div className="search_wrapper_catalog">
                        <InputBase
                            placeholder="Введите название детали"
                            onChange={this.changeValueSearch}
                            value={valueSearch}
                            className="search_settings"
                            classes={{
                                input: 'search_input',
                            }}
                        />
                        <div className="search_input_icon">
                            <img src={SearchIcon} alt="SearchIcon"/>
                        </div>
                    </div>

                    <div className="tree-view">
                        {Object.entries(tree.tree).map((treeItem, idItem) =>
                            <RenderCollapse
                                key={idItem}
                                state={this.state}
                                data={treeItem}
                                id={idItem}
                                params={[treeItem[0]]}
                                onCollapseClick={this.handleTreeClick}
                            />
                        )}
                    </div>

                </div>

                <PartsComponent
                    {...restProps}
                    {...restState}
                    handleClick = {this.handleClick}
                />

            </div>
        );
    }
}

const mapStateToProps = ({catalog}) => ({
    catalog
});

export default connect(mapStateToProps)(TreeComponent);

