import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import TreeComponent from "./TreeComponent";
import Preloader from "../../HelperComponents/Preloader/Preloader";

import { getCatalogModel, getModification, getTree } from "../../../actions/catalogActions";

import './PartsOfCar.scss';

class PartsOfCar extends Component {

    state ={
        loadingTree: true,
        loadingModification: true,
        valueSearch: ''
    };

    componentDidMount() {
        this.doRequest();
    }

    doRequest = () => {
        const { getModification, getTree, match:{ params }} = this.props;
        const ssd = params.ssd;
        const link = params.modelLink;
        getModification(ssd).then(res => {
            if ( res.payload && res.payload.status && res.payload.status === 200 ) {
                this.setState({loadingModification: false});
            }
        });
        getTree(link, ssd).then(res => {
            if ( res.payload && res.payload.status && res.payload.status === 200 ) {
                this.setState({loadingTree: false});
            }
        });
    };

    render(){
        const { match:{ params }, catalog, match } = this.props;
        const { loadingModification, loadingTree } = this.state;
        const id = params.catalog_code;
        const type = params.tab;
        const encodedSeria = params.seria;
        const encodedIndex = params.modifModel;
        const encodedName = params.name;
        const ssd = params.ssd;
        const modelLink = params.modelLink;
        const seria = decodeURIComponent(encodedSeria);
        const index = decodeURIComponent(encodedIndex);
        const name = decodeURIComponent(encodedName);

        if ( loadingModification || loadingTree) return <Preloader/>;

        return (
            <div className="catalog-fourth-page">
                <div className="catalog-hierarchy">
                    <Link
                        to="/main"
                        className="catalog-link"
                    >
                        Каталог
                    </Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}`}
                        className="catalog-link">
                        {name}
                    </Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}/${encodedSeria}`}
                        className="catalog-link"
                    >
                        {seria.replace(name, '')}
                    </Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}/${encodedSeria}/${encodedIndex}`}
                        className="catalog-link"
                    >
                        {index}
                    </Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    {name} {seria.replace(name, '')} [{index}]
                </div>
                <div className="title-wrapper">{name} {seria.replace(name, '')} {index}</div>
                <div className="model-info-wrapper">
                    {Object.values(catalog.tree.model_info).map((info, idInfo) => (
                        <Fragment key={idInfo}>
                            {info !== name && info !== seria && info !== index && info !== "" && info !== undefined ?
                                <Fragment>
                                    <div className="model-info"> {info} </div>
                                    <span>•</span>
                                </Fragment>
                                : null
                            }
                        </Fragment>
                    ))}
                </div>
                <hr className="hr-line"/>

                <TreeComponent
                    match = {match}
                    catalog = {catalog}
                    ssd = {ssd}
                    modelLink = {modelLink}
                    type = {type}
                    id = {id}
                    encodedSeria = {encodedSeria}
                    encodedIndex = {encodedIndex}
                    encodedName = {encodedName}
                />

            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        catalog: state.catalog
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCatalogModel,
        getModification,
        getTree
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PartsOfCar);