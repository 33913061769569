import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import FormControl from '@material-ui/core/FormControl';

import Pagination from '../../HelperComponents/Pagination/Pagination';
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import Preloader from "../../HelperComponents/Preloader/Preloader";

import { getCatalogModel, getParamsSet, getModification } from "../../../actions/catalogActions";

import './ListOfModels.scss';
import {isArray} from "../../../helpers/functions";

class ListOfModels extends Component {

    state ={
        loading: true,
        status: 'all',
        activePage: 1,
        elements: [],
        offset: 0
    };

    componentDidMount() {
        const { getCatalogModel, getParamsSet, getModification,  match:{ params }} = this.props;
        const id = params.catalog_code;
        const type = params.tab;
        const encodedFamily = params.seria;
        const encodedModel = params.modifModel;
        const family = decodeURIComponent(encodedFamily);
        const model = decodeURIComponent(encodedModel);
        let  paramsModel = `&param=`;

        getCatalogModel(id, type);
        getParamsSet(id, family, model, paramsModel).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200 ) {
                let ssd = res.payload.data.client.ssd;
                getModification(ssd).then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 200) {
                        this.setState({ loading: false });
                        this.setElementsForCurrentPage(0);
                    }
                })
            }
        });
    }

    doRequest = (name, event) => {
        const { getParamsSet, getModification, match:{ params }, catalog: { params_set }} = this.props;
        const id = params.catalog_code;
        const encodedFamily = params.seria;
        const encodedModel = params.modifModel;
        const family = decodeURIComponent(encodedFamily);
        const model = decodeURIComponent(encodedModel);

        let paramArray = [];
        Object.entries(params_set.params).map((paramFilter, idFilter) => {
            if (this.state[paramFilter[0]] !== undefined && this.state[paramFilter[0]] !== null) {
                return (paramArray.push(`${paramFilter[0]}:${this.state[paramFilter[0]].label}`))
            } else {return null}
        });
        let paramsModel = `&param=`;
        if (event && event !== null && paramArray.length === 0) {
            let paramsString = `${name}:${event.label}`;
            paramsModel = encodeURIComponent(`&param=${paramsString}`);
        } else if (event && event !== null) {
            let paramsString = `${name}:${event.label}`;
            paramArray.push(paramsString);
            paramsModel = encodeURIComponent(`&param=${paramArray.join(';')}`);
        } else if (event === null) {
            paramsModel = encodeURIComponent(`&param=${paramArray.filter(el => el.indexOf(name) === -1).join(';')}`);
        }
        getParamsSet(id, family, model, paramsModel).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200 ) {
                let ssd = res.payload.data.client.ssd;
                getModification(ssd).then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 200) {
                        this.setElementsForCurrentPage(0);
                    }
                })
            }})
    };


    handleChange = name => event => {
        this.setState({ [name]: event });
        this.doRequest(name, event);
    };


    setElementsForCurrentPage = (offset) => {
        const { catalog: { modification }} = this.props;
        let elements = modification.modifications.slice(offset, offset + 10);
        this.setState({elements: elements});
    };

    changePage = (pageNumber) => {
        const offset = pageNumber.selected * 10;
        this.setState({ activePage: pageNumber.selected + 1, offset: offset });
        this.setElementsForCurrentPage(offset);
    };


    render(){
        const { match:{ params }, catalog: { families, params_set, modification }} = this.props;
        const { loading, activePage, elements } = this.state;
        const id = params.catalog_code;
        const type = params.tab;
        const encodedSeria = params.seria;
        const encodedIndex = params.modifModel;
        const seria = decodeURIComponent(encodedSeria);
        const index = decodeURIComponent(encodedIndex);

        if (loading) return <Preloader/>;

        const ssd = params_set.client.ssd;
        const name = families.catalog.name;
        const encodedName = encodeURIComponent(name);

        return (
            <div className="catalog-third-page">
                <div className="catalog-hierarchy">
                    <Link
                        to="/main"
                        className="catalog-link"
                    >
                        Каталог
                    </Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}`}
                        className="catalog-link"
                    >
                        {name}
                    </Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <Link
                        to={`/main/catalogs/${type}/${id}/${encodedSeria}`}
                        className="catalog-link"
                    >
                        {seria.replace(name, '')}
                    </Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    {index}
                </div>
                <div className="title-wrapper"> {name} {seria.replace(name, '')} {index} </div>
                <div className="catalog-flex-center-start" >
                    {Object.entries(params_set.params).map((paramFilter, idFilter) => {
                        let arrayParams = [];
                        Object.keys(paramFilter[1].values).map((item, idItem) => {
                            return (arrayParams.push({ label: item, value: idItem}))
                        });
                        return (
                            <FormControl className="catalog-select-wrapper" key={idFilter}>
                                <SelectComponent
                                    value={this.state[paramFilter[0]]}
                                    options={arrayParams}
                                    loading={!isArray(paramFilter)}
                                    change={this.handleChange(paramFilter[0])}
                                    placeholder={`${paramFilter[1].name}`}
                                />
                            </FormControl>
                        )
                    })}
                    <div className="transactions_columns">
                        <div className="table_header">
                            <div className="table_row">
                                {Object.keys(modification.modifications[0]).map((label, idLabel) => {
                                    if(label !== 'link') {return (<div className="row_item" key={idLabel}>{label}</div>)}
                                    else {return null}
                                })}
                            </div>
                        </div>
                        <Fragment>
                            <div className="table_body">
                                {elements.map((modif, idModif) => (
                                    <Link
                                        className="table_row"
                                        to={`/main/catalogs/${type}/${id}/${seria}/${index}/${ssd}/${modif.link}/${encodedName}`}
                                        key={idModif}>
                                        {Object.values(modif).map((value, idValue) => {
                                            if(value.length < 80) {return (<div className="row_item" key={idValue}>{value}</div>)}
                                            else {return null}
                                        })}
                                    </Link>
                                ))}
                            </div>
                        </Fragment>
                    </div>
                    <div className="catalog-pagination">
                        {modification.modifications && modification.modifications.length > 10 ?
                            <Pagination
                                active={activePage}
                                pageItemsCount={10}
                                pageTotalCount={modification.modifications.length}
                                onChange={this.changePage}
                            />
                            : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({catalog}) => ({
    catalog
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCatalogModel,
        getParamsSet,
        getModification
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOfModels);