import React, {Component} from 'react';
import { connect } from 'react-redux';
import DefaultButton from '../Buttons/DefaultButton/DefaultButton';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import PopperInfo from '../HelperComponents/PopperInfo/PopperInfo';
import AddRepresentation from './AddRepresentation';

import { postSetLocation } from "../../actions/userActions";

import LocationIcon from '../../assets/image/location.png';
import ArrowIcon from '../../assets/image/arrow-right.png';

import './LocationBlock.scss';

class LocationBlock extends Component {
    state = {
        open: false,
        anchorEl: null,
        city: ''
    };

    componentDidMount() {
        const { authorized, user:{profile} } = this.props;
        if(!authorized) {
            if(localStorage.location) {
                this.getCurrentLocation(JSON.parse(localStorage.location));
            } else {
                this.setState({city: 'Москва'});
                localStorage.location = JSON.stringify({
                    latitude: 55.75585,
                    longitude: 37.6164632
                });
                this.timeoutShow = setTimeout(() => {
                    this.setState({anchorEl: document.getElementsByClassName('location_btn')[0].firstChild});
                }, 1000);
            }
        } else {
            if(profile.location === null || profile.location.latitude === 55.75585) {
                this.setState({city: 'Москва'});
                this.timeoutShow = setTimeout(() => {
                    this.setState({anchorEl: document.getElementsByClassName('location_btn')[0].firstChild});
                }, 1000);
            } else {
                this.getCurrentLocation(profile.location);
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutShow);
    }

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    openPopper = (e) => {
        this.setState({
            anchorEl: e.currentTarget.firstChild
        });
    };

    closePopper = () => {
        this.setState({
            anchorEl: null
        });
    };

    getCurrentLocation = (location) => {
        window.ymaps.ready(() => {
            window.ymaps.geocode([location.latitude, location.longitude]).then(res => {
                let firstGeoObject = res.geoObjects.get(0);
                this.setState({city: firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities()[0] : firstGeoObject.getAdministrativeAreas()[0]});
            });
        });
    };

    render(){
        const { authorized } = this.props;
        const { open, anchorEl, city } = this.state;
        return (
            <div className="location-block">
                <button
                    className="location_btn"
                    onClick={this.openPopper}
                >
                    <img src={LocationIcon} alt="location"/>
                    <span>{city}</span>
                    <img src={ArrowIcon} alt="Arrow"/>
                </button>

                <PopperInfo
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    clickAway={this.closePopper}
                    classes="location_popper"
                >
                    <div className="location_popper_inner">
                        <DefaultButton
                            variant="contained"
                            onClick={this.toggleDialog}
                        >
                            Укажите адрес
                        </DefaultButton>
                        <p>Результаты поиска будут зависеть от выбранного Вами адреса.</p>
                    </div>
                </PopperInfo>

                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <AddRepresentation
                        form={`Representation`}
                        toggleDialog={this.toggleDialog}
                        authorized={authorized}
                        getCurrentLocation={this.getCurrentLocation}
                    />
                </DialogComponent>

            </div>
        );
    }
}

const mapStateToProps = ({user}) => {
    return{
        user
    }
};

const mapDispatchToProps = {
    postSetLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationBlock);
