import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';
import RenderAutocomplete from "../HelperComponents/RenderAutocomplete/RenderAutocomplete";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import IconButton from '@material-ui/core/IconButton';
import Loader from '../HelperComponents/Loader/Loader';

import { postSetLocation } from "../../actions/userActions";

import CloseDialogIcon from '../../assets/image/Mask.png';

class AddRepresentation extends Component {
    constructor(props) {
        super(props);
        let mapOptions = {center: [55.753215, 37.622504], zoom: 12};
        if(!!localStorage.token && props.user.profile.location !== null) {
            const { location } = props.user.profile;
            mapOptions = {center: [location.latitude, location.longitude], zoom: 12}
        }
        this.state = {
            loading: false,
            mapOptions,
            mapLoaded: false
        };
    }

    componentDidMount() {
        const { change } = this.props;
        const { mapOptions } = this.state;
        window.ymaps.ready(() => {
            window.ymaps.geocode(mapOptions.center).then(res => {
                let firstGeoObject = res.geoObjects.get(0);
                change('address', firstGeoObject.getAddressLine());
            });
        });
    }

    submitForm = () => {
        const { postSetLocation, toggleDialog, authorized, getCurrentLocation } = this.props;
        const { mapOptions } = this.state;
        let obj = {
            location: {
                latitude: mapOptions.center[0],
                longitude: mapOptions.center[1]
            }
        };
        if(authorized) {
            this.setState({loading: true});
            postSetLocation(obj).then(res => {
                if(res.payload && res.payload.status && res.payload.status === 200) {
                    getCurrentLocation(obj.location);
                    toggleDialog();
                } else {
                    this.setState({loading: false});
                }
            });
        } else {
            localStorage.location = JSON.stringify(obj.location);
            getCurrentLocation(obj.location);
            toggleDialog();
        }
    };

    onLoadMap = () => {
        this.setState({mapLoaded: true});
    };

    onSelect = (e, name) => {
        const { change } = this.props;
        let value = e.get('item').value;
        change(name, value);

        window.ymaps.geocode(value).then(res => {
            this.setState({
                mapOptions: {
                    center: res.geoObjects.get(0).geometry.getCoordinates(),
                    zoom : 12
                }
            });
        });
    };

    clickOnMap = (e) => {
        const { change } = this.props;
        let coords = e.get('coords');
        window.ymaps.geocode(coords).then(res => {
            let firstGeoObject = res.geoObjects.get(0);
            change('address', firstGeoObject.getAddressLine());
        });

        this.setState({
            mapOptions: {
                center: coords,
                zoom : 12
            }
        });
    };

    render(){
        const { handleSubmit, submitting, pristine, valid, toggleDialog } = this.props;
        const { loading, mapOptions, mapLoaded } = this.state;
        return (
            <div>
                <div className="title-dialog">
                    Ваш адрес
                </div>
                <form className="dialog-form" onSubmit={handleSubmit(this.submitForm)}>
                    <div className="dialog-field-representation">
                        <Field id="suggest_add" name="address" type="text" component={RenderAutocomplete} onSelect={this.onSelect} label="Введите свой адрес"/>
                    </div>
                    <p className="location_info_description">…или выберите на карте</p>
                    <div className="map">
                        <YMaps>
                            <div className="map_wrapper">
                                {!mapLoaded ? <Loader/> : null}
                                <Map
                                    width={1100}
                                    height={426}
                                    state={mapOptions}
                                    onClick={this.clickOnMap}
                                    onLoad={this.onLoadMap}
                                >
                                    <Placemark geometry={mapOptions.center} />
                                </Map>
                            </div>
                        </YMaps>
                    </div>
                    <div className="btn-wrapper">
                        <DefaultButton
                            onClick={() => toggleDialog()}
                            variant="outlined"
                            classes="cancel_btn"
                        >
                            Отмена
                        </DefaultButton>
                        <DefaultButton
                            variant="contained"
                            disabled={submitting || pristine || !valid}
                            loading={loading}
                            formAction
                        >
                            Сохранить
                        </DefaultButton>
                    </div>
                </form>
                <IconButton
                    classes={{
                        root: "dialog_close_button"
                    }}
                    onClick={() => toggleDialog()}
                >
                    <img src={CloseDialogIcon} alt="close icon"/>
                </IconButton>
            </div>
        );
    }
}

AddRepresentation = reduxForm({
    enableReinitialize: true
})(AddRepresentation);

const mapStateToProps = (state, props) => {
    return{
        user: state.user,
        initialValues: {
            address: props.address ? props.address : '',
        }
    }
};

const mapDispatchToProps = {
    postSetLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRepresentation);