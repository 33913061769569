import * as types from './constants.jsx';

export function cleanError(data) {
    return {
        type: types.CLEAN_ERROR,
        data
    }
}

export function CurrentURL(data) {
    return {
        type: types.CURRENT_URL,
        data
    }
}
