import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import BackLink from '../BackLink/BackLink';
import InfoContacts from './InfoContacts/InfoContacts';
import InfoRepresentation from '../CustomerPage/InfoRepresentation/InfoRepresentation';
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import Rating from '../HelperComponents/Rating/Rating';

import ClockIcon from '../../assets/image/time.png';



class CustomerPage extends Component {


    // setMapOption = (option) => {
    //     this.setState(({mapOptions}) => ({
    //         mapOptions: mapOptions.map(el => el.id === option.id ? option : el)
    //     }));
    // };


    render(){
        return (
            <div className="container">
                <div className="title-block">
                    <BackLink/>
                    <h1>Заказ #18237</h1>
                </div>
                <div className="customer-page">
                    <div className="flex-center-btw">
                        <p className="date_wrapper">
                            <img src={ClockIcon} alt="clock"/>
                            11.04.2019 11:19
                        </p>
                        <p className="status_title"><span style={{backgroundColor: '#000'}}></span>Готов к выдаче</p>
                    </div>
                    <div className="descriptions-wrapper">
                        Поставщик
                    </div>
                    <div className="reviews-wrapper">
                        <p>Компания</p>
                        <div className="rating-block">
                            <div className="name">ОллАвто</div>
                            <Rating/>
                            <div className="commends">
                                441 отзыв
                            </div>
                        </div>

                    </div>
                    <InfoContacts/>
                    <InfoRepresentation/>
                    <div className="product-block">
                        <div className="title">Товар(ы)</div>
                        <div className="product-info">
                            <div className="info">
                                <div className="name-product">
                                    <div>Свеча зажигания</div>
                                    <p>•</p>
                                    <span>#90919-01164</span>
                                </div>
                                <div className="price-info">
                                    <span>3 x</span>
                                    <div>163 ₽</div>
                                </div>
                            </div>
                            <div className="firms">Toyota</div>
                        </div>
                        <div className="all-product-wrapper">
                            <div className="product">
                                <div>Всего:</div>
                                <span>3 товара</span>
                            </div>
                            <div className="all-price">489.00 ₽</div>
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <DefaultButton
                            variant="outlined"
                            classes="delete_btn"
                        >
                            Отказаться
                        </DefaultButton>
                    </div>
                </div>
                <div className="customer-comments">
                    <div className="title">Комментарии</div>
                    <button className="all-product">• • •  показать все</button>
                    <div className="info-comments">
                        <div className="name">«ОллАвто»</div>
                        <div className="time">11.04.2019 11:21</div>
                    </div>
                    <div className="descriptions">Добрый день! Спасибо, что выбрали нас! Ожидайте посылку в течении трех рабочих дней.</div>
                    <textarea placeholder="asd"></textarea>
                    <div className="btn-wrapper">
                        <DefaultButton
                            variant="contained"
                        >
                            Ответить
                        </DefaultButton>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        //name: state.name
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //login
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);