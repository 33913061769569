import * as types from "../actions/constants";

const INITIAL_STATE = {
    all_orders: {},
    order: {},
    supplier_info: {},
    supplier_reviews: {},
    error: {}
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.GET_ORDERS_SUCCESS :
            return {...state, all_orders : action.payload.data};
        case types.GET_ORDER_SUCCESS :
            return {...state, order : action.payload.data};
        case types.GET_SUPPLIER_INFO_SUCCESS :
            return {...state, supplier_info : action.payload.data};
        case types.GET_SUPPLIER_REVIEWS_SUCCESS :
            return {...state, supplier_reviews : action.payload.data};

        case types.POST_ORDER_COMMENT_SUCCESS :
            let stateOrder = state.order;
            let objReview = {
                "comment": action.payload.data.comment,
                "date": action.payload.data.date,
                "name":  action.payload.data.name
            };
            stateOrder.orderreviews.push(objReview);
            return {...state, order : stateOrder};

        case types.CANCEL_ORDER_FAIL :
            return {...state, error : action.error.response.data};
        case types.GET_ORDERS_FAIL :
            return {...state, error : action.error.response.data};
        // case types.GET_ORDER_FAIL :
        //     return {...state, error : action.error.response.data};
        default:
            return state;
    }
}
