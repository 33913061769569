import React from 'react';
import {Field, reduxForm} from 'redux-form';

import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import RenderField from "../RenderField/RenderField";

import './DialogFavorites.scss';

let VinFrameForm = ({ submitVinForm, handleSubmit, valid, submitting, pristine, openDialogFavorites, notFoundByVin, loading }) => (
    <form className="" onSubmit={handleSubmit(submitVinForm)}>
        <div className="block-info">
            <div>
                <Field name="vin" type="text" component={RenderField} label="VIN/FRAME-номер автомобиля"/>
            </div>
        </div>
        <div className="dialog-button-wrapper">
            <DefaultButton
                variant="outlined"
                classes="cancel_btn"
                onClick={openDialogFavorites}
            >
                ОТМЕНА
            </DefaultButton>
            <DefaultButton
                variant="contained"
                formAction
                disabled={!valid || submitting || pristine}
                loading={loading}
            >
                ПРОДОЛЖИТЬ
            </DefaultButton>
            {!!notFoundByVin ?
                <span className="error-text">Не удалось найти модель</span> : null
            }
        </div>
    </form>
);

const validate = values => {
    const errors = {};
    if (!values.vin) {
        errors.vin = 'Обязательное поле'
    }
    return errors
};

VinFrameForm = reduxForm({
    form: 'VinOrFrameForm',
    validate
})(VinFrameForm);

export default VinFrameForm;