import * as types from "../actions/constants";

const INITIAL_STATE = {
    info_company: {},
    profile: {},
    chosen_list: {load: false, list: []},
    search_results: {},
    favorite_lists: [],
    favorites: [],
    chosen_favorites_list: [],
    main_search: {},
    load: true,
    error_user: {},
    error_text: null,
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.INFO_SUCCESS :
            return {...state, info_company : action.payload.data};
        case types.CHANGE_INFO_SUCCESS :
            return {...state, info_company : action.payload.data};
        case types.GET_PROFILE_SUCCESS :
            return {...state, profile : action.payload.data};
        case types.SET_LOCATION_SUCCESS :
            return {...state, profile: {...state.profile, ...action.payload.data}};
        case types.GET_PART_DETAIL_SUCCESS :
            return {...state, main_search: action.payload.data};


        // ***************   FAVORITE LISTS   ***************
        case types.GET_MAIN_PAGE_CHOSEN_LISTS_SUCCESS:
            return {...state, chosen_list : {load: true, list: action.payload.data} };
        case types.GET_LISTNAME_CHOSEN_PRODUCTS_SUCCESS:
            return {...state, favorite_lists : action.payload.data};
        case types.POST_LISTNAME_CHOSEN_PRODUCTS_SUCCESS:
            let listProducts = state.favorite_lists;
            let objList = {
                "name": action.payload.data.name,
                "mileage":  action.payload.data.mileage
            };
            listProducts.pop(objList);
            return {...state, favorite_lists : listProducts};
        case types.CHANGE_LISTNAME_CHOSEN_PRODUCTS_SUCCESS:
            /*
            let changedFavoriteListObj = state.favorite_lists;
            changedFavoriteListObj[changedFavoriteListObj.findIndex(x => x.id === action.payload.data.id)] = action.payload.data;
            */
            return {...state, favorite_lists : action.payload.data, error_text: null};
        case types.CHANGE_LISTNAME_CHOSEN_PRODUCTS_FAIL:
            return {...state, error_text: action.error.response.data};
        case types.DELETE_LISTNAME_CHOSEN_PRODUCTS_SUCCESS:
            //let id2 = Number(action.meta.previousAction.payload.request.url.split('/')[3]);
            return {...state};


        // ***************   CHOSEN PRODUCTS   ***************
        case types.INIT_FAVOURITES_LIST:
            return {...state, chosen_favorites_list: action.payload.data};
        case types.GET_CHOSEN_PRODUCTS_SUCCESS:
            return {...state, favorites : action.payload.data, load: true};
        case types.POST_NEW_CHOSEN_PRODUCT_SUCCESS:
            let id = parseInt(action.payload.config.reduxSourceAction.payload.request.url.split("/").filter(val => val.length !== 0).slice(-1)[0]);
            let newList = state.chosen_favorites_list;
            newList.push(id);
            return {...state, main_search: action.payload.data, error_text: null};
        case types.POST_NEW_CHOSEN_PRODUCT_FAIL:
            return {...state, error_text: action.error.response.data};
        case types.CHANGE_COMMENT_SUCCESS:
            let changeCommentObj = state.favorites;
            changeCommentObj.results[changeCommentObj.results.findIndex(x => x.id === action.payload.data.id)].comment = action.payload.data.comment;
            return {...state, favorites : changeCommentObj, error_text: null};
        case types.CHANGE_COMMENT_FAIL:
            return {...state, error_text: action.error.response.data};
        case types.DELETE_SELECTED_ITEM_FROM_FAVORITE_SUCCESS:
            let idx = Number(action.meta.previousAction.payload.request.url.split('/')[3]);
            let result = {...state.favorites, results: state.favorites.results.filter(el => el.id !== idx)};
            return {...state, favorites : result};
        case types.DELETE_ITEMS_FROM_FAVORITE_SUCCESS:
            let indexes = action.meta.previousAction.payload.request.url.split('/')[3].split('=')[1].split(',').map(Number);
            let new_result = {...state.favorites, results: state.favorites.results.filter(el => !indexes.includes(el.id))};
            return {...state, favorites: new_result};
        case types.DELETE_OFFER_FROM_FAVORITE_SUCCESS:
            id = parseInt(action.payload.config.reduxSourceAction.payload.request.url.split("/").filter(val => val.length !== 0).slice(-1)[0]);
            newList = state.chosen_favorites_list.filter(val => val !== id);
            return {...state, chosen_favorites_list: newList};

        case types.GET_SEARCH_PARTS:
            return {...state, search_results : {load: true, list: {}}};
        case types.GET_SEARCH_PARTS_SUCCESS:
            return {...state, search_results : {load: false, list: action.payload.data}};
        case types.SEARCH_BY_VIN_SUCCESS:
            return {...state, search_results : {load: false, list: action.payload.data}};


        // ***************   ERRORS   ***************
        case types.CHANGE_PASSWORD_PROFILE_FAIL :
            return {...state, error_user : action.error.response.data};
        case types.INFO_FAIL :
            return {...state, error_user : action.error.response.data};
        case types.CHANGE_INFO_FAIL :
            return {...state, error_user : action.error.response.data};
        default:
            return state;
    }
}
