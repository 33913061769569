import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardCatalog from '../Dashboard/DashboardCatalog/DashboardCatalog';
import DashboardFooter from '../Dashboard/DashboardFooter/DashboardFooter';
import DashboardFavorites from '../Dashboard/DashboardFavorites/DashboardFavorites';

import { updateBasket } from "../../actions/basketActions";

import './Dashboard.scss';


class Dashboard extends Component {

    componentDidMount () {
        const { updateBasket } = this.props;
        const cart = localStorage.getItem('parts_cart');
        if (cart && localStorage.token) {
            updateBasket(JSON.parse(cart)).then(() => {
                localStorage.removeItem("parts_cart");
                localStorage.removeItem("total_count");
            })
        }
    }

    handleChange = event => {
        this.setState({ type: event.target.value });
    };

    render(){
        return (
            <main>
                <section className="">
                    <DashboardFavorites/>
                    <DashboardCatalog history={this.props.history} />
                    <DashboardFooter/>
                </section>
            </main>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateBasket
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(Dashboard);