import React  from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import {getSupplierInfo} from '../../actions/ordersActions';
import { days } from "../../helpers/functions";
import {YMaps, Map, Placemark} from 'react-yandex-maps';

import Loader from '../HelperComponents/Loader/Loader';
import { Link } from 'react-router-dom';
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import Close from '../../assets/image/Mask.png';
import './ModalPage.scss';

const text_modal = (type, count) => {
    switch(type){
        case 'buy_all':
            return(
                <React.Fragment>
                    <h1>Подтвердить заказ</h1>
                    <span>Будет создано {count} заказа. Вы уверены?</span>
                </React.Fragment>
            );
        case 'delete_all':
            return(
                <React.Fragment>
                    <h1>Удалить все товары</h1>
                    <span>Вы уверены, что хотите удалить все товары из корзины?</span>
                </React.Fragment>
            );
        case 'delete':
            return(
                <React.Fragment>
                    <h1>Удалить товар</h1>
                    <span>Вы уверены?</span>
                </React.Fragment>
            );
        default:
        return true;
    }
}

export const ModalAutorization = ({onClose}) => {
    return(
        <div className="modal-container">
            <span className="close" onClick={() => onClose()}><img src={Close} alt="close"/></span>
            <div className="main-block">
            <h1>Войдите в аккаунт</h1>
            <p>Для завершения покупки нужно <Link to="/auth/">войти в аккаунт</Link> или <Link to="/auth/register">зарегистрироваться</Link>.</p>
            </div>
        </div>
    );
}

export const ConfirmModal = ({confirm, onClose, count = 0, type = 'delete'}) => {
    const button_text = (type === 'buy_all') ? 'Подтвердить' : 'Удалить';
    return(
        <div className="modal-container confirm">
            <span className="close" onClick={onClose}><img src={Close} alt="close"/></span>
            <div className="main-block">
                {text_modal(type, count)}
            </div>
            <div className="list-button">
                <DefaultButton
                    variant="outlined"
                    classes="cancel_btn"
                    onClick={onClose}
                >Отмена</DefaultButton>
                <DefaultButton
                    variant="outlined"
                    classes="default_button_contained"
                    onClick={confirm}
                >
                 {button_text}
                </DefaultButton>
            </div>
        </div>
    );
}

class ModalMap extends React.Component{
    state = {
        mapOptions: {},
        workingtime: []
    };

    componentDidMount() {
        const { getSupplierInfo, address, id} = this.props;

        window.ymaps.ready(() => {
            window.ymaps.geocode(address).then(response => {
                let obj = {
                    center: response.geoObjects.get(0).geometry.getCoordinates(),
                    zoom: 12
                };
                this.setState({mapOptions: obj});
            });
        });

        getSupplierInfo(id).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                const infoCompany = res.payload.data.addresses.filter(value => address === value.coordinates);
                this.setState({workingtime: infoCompany[0].workingtime});
            }
        });
    }

    render(){
        const {onClose, address} = this.props;
        const { mapOptions, workingtime } = this.state;
        return(
            <div className="modal-container map">
                <span className="close" onClick={onClose}><img src={Close} alt="close"/></span>
                  <div className="main-block">
                      <h1>Пункт выдачи</h1>
                      <span className="text">Адрес</span>
                      <h2>{address}</h2>
                      <div className="data-provider">
                          <Schedule workingtime={workingtime} />
                          {(!!mapOptions.center) ? <LocationProvider mapOptions={mapOptions}/> : <Loader size={50} thickness={2.5} />}
                      </div>
                  </div>
            </div>
        );
    }
}

const Schedule = ({workingtime}) => {
    return(
        <div className="schedule">
            <span className="block_label">График работы</span>
            {workingtime.map(({working_day, from_hour, to_hour, is_enabled}, i) => (
            <div  key={i} className={`schedule-time ${!is_enabled ? "schedule-time_inactive" : null}`}>
                <span className="time_day">
                    {days.filter(el => el.name === working_day)[0].label}
                </span>
                <span className="time_value">
                    {is_enabled ? `${from_hour} - ${to_hour}`: 'Выходной'}
                </span>
            </div>
            ))}
         </div>
    );
}

const LocationProvider = ({mapOptions}) => {
    return(
        <div className="schedule_map_wrapper">
            <YMaps>
                <Map
                    width={590}
                    height={340}
                    state={mapOptions}>
                    <Placemark geometry={mapOptions && mapOptions.center}/>
                </Map>
            </YMaps>
        </div>
    )
}

function mapStateToProps(state) {
    return{
        orders: state.orders
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getSupplierInfo
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMap);
