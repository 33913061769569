import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CheckOff from '../../../assets/image/checkbox_off.png';
import CheckOn from '../../../assets/image/checkbox_on.png';

import './RenderCheckField.scss';

const RenderCheckField = ({ input, placeholder, type, id, label, disabled, meta: {touched, error} }) => {
    return (
        <div className="check_field_wrapper">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={input.value}
                        onChange={input.onChange}
                        disabled={disabled}
                        value={input.name}
                        checkedIcon={<img src={CheckOn} alt="check on"/>}
                        icon={<img src={CheckOff} alt="check off"/>}
                        classes={{
                            root: 'custom_check'
                        }}
                    />
                }
                label={label}
            />
        </div>
    );
};

export default RenderCheckField;
