import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock';
import RenderCheckField from '../HelperComponents/RenderCheckField/RenderCheckField';
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import ChangePassword from './ChangePassword';
import SnackBar from '../HelperComponents/SnackBar/SnackBar';
import Button from '@material-ui/core/Button';

import Loader from '../HelperComponents/Loader/Loader';

import {getProfile, putProfile} from "../../actions/userActions";

import './Profile.scss';
// import ClockIcon from "../../assets/image/time.png";
import SuccessIcon from '../../assets/image/success_icon.png';
import RenderField from "../HelperComponents/RenderField/RenderField";


class Profile extends Component {

    state = {
        openDialog: false,
        openDialogPassword: false,
        loader: true,
        successEmailChange: false,
        changeEmail: {},
        emailPassword: '',
        loadingButton: false,
        success: false,
        open: false,
        successPass: false
    };

    componentDidMount() {
        const { getProfile, history } = this.props;
        getProfile().then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({loader: false});
            }
            if(res.error && res.error.response.status === 401) {
                localStorage.clear();
                history.push('/main');
            }
        })
    }

    submitForm = data => {
        const { putProfile } = this.props;
        this.setState({loading: true});
        return putProfile(data).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({loading: false, success: true});
            } else {
                this.setState({loading: false});
            }
        });
    };

    handleClose = () => {
        this.setState({openDialog: false});
    };

    toggleSnack = () => {
        this.setState(({success}) => ({
            success: !success
        }));
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open,
            successPass: false
        }));
        this.props.user.error_user={};
    };

    handleSuccessPass = () => {
        this.setState({successPass: true});
    };

    render(){
        const { handleSubmit } = this.props;
        const {loader, loadingButton, success,open, successPass} = this.state;

        if (loader) return <div className="loader-in-container-wrapper"><Loader /></div>;

        return (
            <TransitionedBlock>
                <div className="container container-profile">
                    <div className="title-block">
                        <h1>Настройки профиля</h1>
                    </div>
                    <div className="customer-page">
                        <form className="" onSubmit={handleSubmit(this.submitForm)}>
                            <div className="reviews-wrapper">
                                <div className="descriptions-wrapper">
                                    Общие данные
                                </div>
                                <div className="fields-inline">
                                    <Field name="first_name" type="text" component={RenderField} label="Имя"/>
                                    <Field name="last_name" type="text" component={RenderField} label="Фамилия"/>
                                </div>
                                <Button
                                    onClick={this.toggleDialog}
                                    disableRipple ={true}
                                    classes={{
                                        root: 'add_btn string_link'
                                    }}
                                >
                                    Сменить пароль
                                </Button>
                            </div>

                            <div className="reviews-wrapper product-block">
                                <div className="descriptions-wrapper descriptions-wrapper-contact">
                                    Контактные данные
                                </div>
                                <div className="fields-inline">
                                    <Field name="email" type="text" component={RenderField} disabled={true} label="Электронный адрес"/>
                                    <Field name="phone" type="text" component={RenderField} label="Телефон"/>
                                </div>
                                <div className="check_wrapper">
                                    <Field name="comment_notification" component={RenderCheckField} label="Уведомлять о новых комментариях" />
                                    <Field name="status_notification" component={RenderCheckField} label="Уведомлять об изменении статуса заказа" />
                                </div>
                            </div>
                            <div className="btn-wrapper">
                                <DefaultButton
                                    variant="contained"
                                    loading={loadingButton}
                                    formAction
                                >
                                    Сохранить
                                </DefaultButton>
                            </div>

                        </form>
                        <ChangePassword  open={open} toggleDialog={this.toggleDialog} successPass={successPass} handleSuccessPass={this.handleSuccessPass} />
                    </div>
                </div>


                <SnackBar
                    open={success}
                    onClose={this.toggleSnack}
                    classes="success"
                >
                    <img src={SuccessIcon} alt="success icon"/>
                    Изменения успешно сохранены
                </SnackBar>
            </TransitionedBlock>

        );
    }
}



const validate = values => {
    const errors = {};
    if (!values.login) {
        errors.login = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.login)) {
        errors.login = 'Неверный адрес электронной почты'
    }
    if (!values.password) {
        errors.password = 'Required'
    } else if (values.password.length < 8) {
        errors.password = 'Должно быть 8 и больше символов'
    }
    if (!values.first_name) {
        errors.first_name = 'Required'
    } else if (values.first_name.length > 20 || values.first_name.length < 3) {
        errors.first_name = 'Должно быть не меньше 3 и не больше 20 символов'
    }
    if (!values.last_name) {
        errors.last_name = 'Required'
    } else if (values.last_name.length > 20 || values.last_name.length < 3) {
        errors.last_name = 'Должно быть не меньше 3 и не больше 20 символов'
    }

    if (!values.phone) {
        errors.phone = 'Required'
    } else if (isNaN(Number(values.phone))) {
        errors.phone = 'Необходимо вводить цифры'
    } else if (values.phone.length > 30 || values.phone.length < 3) {
        errors.phone = 'Должно быть не меньше 3 и не больше 20 символов'
    }
    return errors
};


Profile = reduxForm({
    form: 'Profile',
    enableReinitialize: true,
    validate
})(Profile);


function mapStateToProps(state) {
    return{
        user: state.user,
        initialValues: {
            first_name: state.user.profile.first_name || '',
            last_name: state.user.profile.last_name || '',
            email: state.user.profile.email || '',
            phone: state.user.profile.phone || '',
            status_notification: state.user.profile.status_notification || false,
            comment_notification: state.user.profile.comment_notification || false
        }
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getProfile,
        putProfile
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);