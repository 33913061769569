import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import { days } from "../../../../helpers/functions";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import './InfoRepresentation.scss';

class InfoRepresentation extends Component {
    state = {
        open: false,
        mapOptions: null
    };

    componentDidMount() {
        const { order } = this.props;
        window.ymaps.ready(() => {
            window.ymaps.geocode(order.address).then(res => {
                let obj = {
                    center: res.geoObjects.get(0).geometry.getCoordinates(),
                    zoom: 12
                };
                this.setState({mapOptions:  obj });
            });
        });
    }

    toggleOpen = () => {
        const {open} = this.state;
        this.setState({open: !open});
    };

    render(){
        const { order } = this.props;
        const { open, mapOptions } = this.state;
        if (!mapOptions) return null;
        return (
            <div>
                {!!order.address ?
                    <div>
                        <div className={`expnasion-block-D ${open ? "expansion-block-D-active" : ""}`}>
                        {/*<div className="expnasion-block-D expansion-block-D-active">*/}
                            <div className="block-label-D">Место выдачи</div>
                            <div className="expansion-title flex-center">
                                <p onClick={this.toggleOpen}>
                                    {order.address}
                                    <IconButton>
                                        { open ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
                                    </IconButton>
                                </p>
                            </div>
                            <div className="expansion-body flex-center" style={{display: open ? 'flex' : 'flex'}}>
                                <div className="schedule">
                                    <span className="block_label">График работы</span>
                                    {order.workingtime.map(({working_day, from_hour, to_hour, is_enabled}, i) => (
                                        <div className={`schedule-time ${!is_enabled ? "schedule-time_inactive" : null}`} key={i}>
                                            <span className="time_day">
                                                {days.filter(el => el.name === working_day)[0].label}
                                            </span>
                                            <span className="time_value">
                                                {is_enabled
                                                    ? `${from_hour} - ${to_hour}`
                                                    : 'Выходной'
                                                }
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div className="schedule_map_wrapper">
                                    <YMaps>
                                        <Map
                                            width={675}
                                            height={265}
                                            state={mapOptions}
                                        >
                                            <Placemark geometry={mapOptions && mapOptions.center} />
                                        </Map>
                                    </YMaps>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <p className="info_section_no_items block_value">У поставщика нет добавленых представительств</p>
                }
            </div>
        );
    }
}

export default InfoRepresentation;
