import React, {Component} from 'react';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import RenderCheckField from '../HelperComponents/RenderCheckField/RenderCheckField';
import {Link} from "react-router-dom";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import {DeleteAllItems} from './FavoritesPage';
import Pagination from '../HelperComponents/Pagination/Pagination';
import Preloader from "../HelperComponents/Preloader/Preloader";

import {deleteItemsFromFavorite, getChosenProducts, searchByVin} from "../../actions/userActions";

import './FavoritesPage.scss';

class AllList extends Component {
    state = {
        checkbox: false,
        open: false,
        count: 0,
        idList: null,
        activePage: 1,
        load: false
    };

    submitForm = () => {
        const { deleteItemsFromFavorite, formStates } = this.props;
        let ids = Object.keys(formStates).filter(key => formStates[key] && key !== 'all_products');
        let string = `?delete_id=${ids.join(',')}`;
        deleteItemsFromFavorite(string).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 204) {
                this.setState({open: false, checkbox: false});
                this.update();
            }
        });
    };

    componentDidMount = () => {
        this.update();
    };

    componentDidUpdate = (prevProps, prevState) => {
        const {user: {favorites}, formStates, activeList} = this.props;
        if(prevProps.activeList !== activeList) this.update();
        if(!!prevProps.favorites && prevProps.favorites !== favorites) this.update();
        if(prevProps.updateList !== this.props.updateList) this.update();
        if(prevProps.formStates !== formStates) this.updateCheckbox();
    };

    update = (pageNumber) => {
        const { activePage } = this.state;
        const { activeList } = this.props;
        let activeOne = activeList;
        if (localStorage.idList) {
            activeOne = localStorage.idList;
        }
        this.setState({load: false});
        if (!pageNumber) {
        this.props.getChosenProducts(activePage, activeOne).then((res) => {
            if(res.payload.data.results.length !== 0) this.initForms();
            this.setState({load: true});
            localStorage.removeItem("idList");
        })
        } else {
            this.props.getChosenProducts(pageNumber, activeOne).then((res) => {
                if(res.payload.data.results.length !== 0) this.initForms();
                this.setState({load: true});
            })
        }
    };

    initForms = () => {
        const {initialize, user: {favorites}} = this.props;
        let newlist = {};
        favorites.results.map(({id}) => newlist[id] = false);
        newlist['all_products'] = false;
        initialize(newlist);
    };

    setCheckbox = () => {
        const {formStates, change} = this.props;
        Object.keys(formStates).map((key) => (
            change(key, !formStates.all_products)
        ))
    };

    updateCheckbox = () => {
        const {formStates, change} = this.props;
        const count = Object.keys(formStates).length;
        const checkbox = Object.keys(formStates).filter(val => formStates[val]);
        (checkbox.length !== 0) ? this.setState({checkbox: true}) : this.setState({checkbox: false});
        if(checkbox.length === (count - 1) && !formStates.all_products) change('all_products', true);
        else if(checkbox.length === (count - 1) && formStates.all_products) change('all_products', false);
    };

    updateForms = () => {
        let active = false;
        active = Object.keys(this.props.formStates[this.props.activeList]).some(key => this.props.formStates[this.props.activeList][key] && key !== 'all_products');
        if(active) this.setState({checkbox: true});
        else this.setState({checkbox: false});
    };

    counter = () => {
        const {formStates} = this.props;
        const obj = Object.keys(formStates);
        const count = (formStates.all_products) ? --obj.length : obj.filter(val => formStates[val]).length;
        return {count: count, id: obj.filter(val => formStates[val])};
    };

    close = () => this.setState({open: false});

    open = () => {
      const count = this.counter();
      if(count.count === 1) {
        const {results} = this.props.user.favorites;
        let product = {};
        product = results.filter(val => val.id === parseInt(count.id))[0];
        this.props.toggleDialog(product);
      } else this.setState({count: count.count, open: true});
    }

    changePage = (pageNumber) => {
        let page = pageNumber.selected + 1;
        this.setState({activePage: page});
        this.update(page);
    };

    handlerPrice = (price) => {
      if(!Array.isArray(price)) return price + ' ₽';
      else if(price[0].price__min === price[1].price__max) return price[0].price__min+ ' ₽';
      else return price[0].price__min + ' ₽ - ' + price[1].price__max + ' ₽';
    }

    goToCatalog = vin => {
        const { searchByVin, history } = this.props;
        searchByVin(vin).then((res) => {
            if(res.payload && res.payload.data && res.payload.data.models && res.payload.data.models.length === 1) {
                const path = res.payload.data.client;
                this.setState({ loading: false });
                history.push(`/main/catalogs/0/${path.catalog_code}/${encodeURIComponent(path.family)}/${encodeURIComponent(path.model)}/${path.ssd}/${res.payload.data.models[0].link}/${encodeURIComponent(path.mark)}`);
            }
            else {
                this.setState({ loading: false });
                history.push("/main/search");
            }
        });
    };

    render(){
      const {handleSubmit, reset, user, toggleDialog, toggleListsDialog, openDialogComment, openDialogChangeFavoriteList, additionalInfo, page} = this.props;
      const { checkbox, open, count, load, activePage} = this.state;
      if(!load) return <Preloader/>;
      const vin = additionalInfo.vin; //TODO
      return (
        <div className="favorites-all">
            <DeleteAllItems open={open} count={count} close={this.close} confirm={this.submitForm} />
            <form className="" onSubmit={handleSubmit(this.open)} >
                {!additionalInfo ||  page === 0 ? null :
                    <div className="favorites-items">
                        <div>
                            <div className="name-items">
                                <div>{additionalInfo.name}</div>
                                <p>{vin}</p>
                            </div>
                            <div className="info">{!!additionalInfo.mileage ? additionalInfo.mileage + ' км' : null}</div>
                        </div>
                        <div className="favorites-table-button">
                            {vin && (<button type="button" className="btn-blue" onClick={() => this.goToCatalog(vin)}>Перейти в каталог</button>)}
                            <button type="button" className="btn-blue" onClick={openDialogChangeFavoriteList}>Изменить</button>
                            <button type="button" className="btn-red" onClick={toggleListsDialog}>Удалить</button>
                        </div>
                    </div>
                }
                {!user.favorites.results || user.favorites.results.length !== 0 ?
                    <div className="favorites-table">
                        <div className="table_header">
                            <div className="table_row">
                                <div className="row_item">
                                    <Field name="all_products" component={RenderCheckField} label="Производитель" onChange={this.setCheckbox} />
                                </div>
                                <div className="row_item">Артикул</div>
                                <div className="row_item">Название товара</div>
                                <div className="row_item">Цена</div>
                                <div className="row_item">Комментарий</div>
                                <div className="row_item"></div>
                            </div>
                        </div>
                        <div className="table_body">
                            {!user.favorites.results ? null :
                                user.favorites.results.map(( product, i ) => (
                                        <div className="table_row" key = {"favorites" + i}>
                                            <div className="row_item">
                                                <Field name={`${product.id}`} component={RenderCheckField} label={`${product.producer}`}/>
                                            </div>
                                            <div className="row_item">
                                                <Link to={'/main/offers/' + product.product_id} className="name">
                                                    {product.article_number}
                                                </Link>
                                            </div>
                                            <div className="row_item">{(product.name.length > 25) ? `${product.name.slice(0,25)} ...` :`${product.name}`}</div>
                                            <div className="row_item">
                                                {(!!product.price) ? this.handlerPrice(product.price) : ''}
                                            </div>
                                            <div className="row_item">{(product.comment.length > 35) ? `${product.comment.slice(0,32)} ...` : `${product.comment}`}</div>
                                            <div className="row_item">
                                                <div className="favorites-table-button">
                                                    <button type="button" className="btn-blue" onClick={() => openDialogComment(product)}> Изменить</button>
                                                    <button type="button" className="btn-red" onClick={() => toggleDialog(product)}>Удалить</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div> :
                    <div className="empty-favorites-list">
                        <p>
                            Здесь пока пусто. Воспользуйтесь поиском, чтобы найти нужные запчасти, и добавьте их в избранное, нажав на иконку «♡».
                            Вы также можете создавать новые списки избранного и добавлять товары в созданные списки.
                        </p>
                    </div>
                }
                {user.favorites.count > 10 ?
                    <div className="all-list-pagination">
                        <Pagination
                            active={activePage}
                            pageItemsCount={10}
                            pageTotalCount={user.favorites.count}
                            onChange={this.changePage}
                        />
                    </div>
                    :
                    null
                }
                {(!checkbox) ? null :
                    <div className="favorites-form-button">
                        <DefaultButton
                            variant="outlined"
                            classes="grey_btn"
                            type="button"
                            onClick={reset}
                        >
                            СНЯТЬ ВЫДЕЛЕНИЕ
                        </DefaultButton>
                        <DefaultButton
                            variant="outlined"
                            classes="delete_btn"
                            onClick={this.open}
                        >
                            УДАЛИТЬ
                        </DefaultButton>
                    </div>
                }
            </form>
        </div>
      );
    }
}

AllList = reduxForm({
    form: 'AllList',
    enableReinitialize: true,
})(AllList);



function mapStateToProps(state) {
    return{
        user: state.user,
        formStates: getFormValues('AllList')(state)
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        deleteItemsFromFavorite,
        getChosenProducts,
        searchByVin
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AllList);
