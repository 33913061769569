import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import BackLink from '../BackLink/BackLink';
import DashboardFooter from '../Dashboard/DashboardFooter/DashboardFooter';
import {Field, reduxForm} from 'redux-form';
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import RenderField from "../HelperComponents/RenderField/RenderField";

import './ContactDetails.scss';
import RenderTextarea from "../HelperComponents/RenderTextarea/RenderTextarea";
import {contactsData} from '../../actions/userActions';
import {SuccessMessage} from '../Buttons/BuyButton/BuyButton';


class ContactDetails extends Component {
    state = {
        isSendMessage: false
    };


    submitForm = data => {
        const {contactsData, reset} = this.props;
        let obj = { "name": data.name, "email": data.email, "msg": data.message };
        contactsData(obj).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 201) {
                this.setState({
                    isSendMessage: true
                });
                reset();
            }
        })
    };

    stateBuyFalse = () => this.setState(prevState => ({ isSendMessage: false }));

    render(){
        const {handleSubmit, pristine,  submitting} = this.props;

        return (
            <main className="contact_details">
                {this.state.isSendMessage ? <SuccessMessage close={this.stateBuyFalse} message={"Ваше сообщение успешно отправлено!"} /> : null}
                <div className="container">
                    <div className="title-block">
                        <BackLink to="/main"/>
                        <h1>Контактные данные</h1>
                    </div>
                    <div className="contact_details_wrapper">
                        <div className="title">Если у Вас возникли вопросы, напишите нам на почту <b>info@mexcar.ru</b> или заполните форму обратной связи.</div>
                        <form className="contact_details_form" onSubmit={handleSubmit(this.submitForm)}>
                            <div className="">
                               <div className="block_field">
                                   <Field name="name" type="text" component={RenderField} label="Имя"/>
                                   <Field name="email" type="text" component={RenderField} label="Электронный адрес"/>
                               </div>
                               <div>
                                   <Field name="message" type="text" component={RenderTextarea} placeholder="Ваше сообщение..."/>
                               </div>
                            </div>
                            <div className="btn-wrapper">
                                <DefaultButton
                                    variant="contained"
                                    // loading={loadingButton}
                                    formAction
                                    disabled={pristine || submitting}
                                >
                                    Отправить
                                </DefaultButton>
                            </div>

                        </form>
                    </div>
                </div>
                <DashboardFooter/>
            </main>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Обязательное поле'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = 'Неверный адрес электронной почты'
    }else if (values.email.length > 40){
        errors.email = 'Не больше 40 символов'
    }

    if (!values.name) {
        errors.name = 'Обязательное поле'
    } else if(values.name.length > 20){
        errors.name = 'Не больше 20 символов'
    }

    if (!values.message) {
        errors.message = 'Обязательное поле'
    }
    return errors
};


ContactDetails = reduxForm({
    form: 'ContactDetails',
    enableReinitialize: true,
    validate
})(ContactDetails);


function mapStateToProps(state) {
    return{
        //name: state.name
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //login
        contactsData
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);