import React, {Component} from 'react';
import {withRouter } from 'react-router-dom';

import BackIcon from '../../assets/image/back.png';

class BackLink extends Component {
    handleBack = () => {
        const { to, history } = this.props;
        if (to) {
            history.push(to);
        } else {
            history.goBack()
        }
    };


    render() {
        return (
            <div className="app-back_link">
                <img
                    src={BackIcon}
                    alt="back icon"
                    onClick={this.handleBack}
                />
            </div>
        )
    }
}

export default withRouter(BackLink);


