import React, {Component, Fragment} from 'react';
import TelegramIcon from '../../../../assets/image/telegram.png';
import ViberIcon from '../../../../assets/image/viber.png';
import WhatsAppIcon from '../../../../assets/image/whatsapp.png';

import './InfoContacts.scss';
import Preloader from "../../../HelperComponents/Preloader/Preloader";

class InfoContacts extends Component {


    render() {
        const {phones} = this.props;

        if (!phones) return <Preloader/>;


        return (
            <Fragment>
                <div className="phones">
                    <div className="phones-label">Телефон(ы)</div>
                    {phones.map((item) => (
                        <div className="contacts" key={item.id}>
                            <div className="contacts-info">
                                <div className="info">
                                    {!!item.phone_number && item.phone_number.length > 0 ?
                                        item.phone_number.substring(0, 1) + "-" + item.phone_number.substring(1, 4) + "-" + item.phone_number.substring(4) :
                                        "-"
                                    }
                                </div>
                                <div className="info-social">
                                    {item.telegram ? <img src={TelegramIcon} alt="Telegram"/> : null}
                                    {item.viber ? <img src={ViberIcon} alt="Viber"/> : null}
                                    {item.whats_app ? <img src={WhatsAppIcon} alt="WhatsApp"/> : null}
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </Fragment>
        );
    }
}


export default InfoContacts;
