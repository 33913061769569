import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

import { getMainPageChosenLists, postListnameChosenProducts } from "../../../actions/userActions";
import { isArray } from "../../../helpers/functions";
import DialogFavorites from '../../HelperComponents/DialogFavorites/DialogFavorites';
import Preloader from "../../HelperComponents/Preloader/Preloader";

import './DashboardFavorites.scss';
import Button from "@material-ui/core/Button";
import AddIcon from "../../../assets/image/add.png";


class DashboardFavorites extends Component {

    state = {
        loading: false,
        openFavorites: false,
    };

    componentDidMount() {
        if (!!localStorage.token) {
            this.handleLists();
        }
    }

    openDialogFavorites = () => {
        this.setState(({openFavorites}) => ({
            openFavorites: !openFavorites
        }));
    };

    handleLists = () => {
        const { getMainPageChosenLists } = this.props;
        getMainPageChosenLists();
    };


    handleClick = (id, number, length) => {
        localStorage.idList = id;
        if (length) {
            if (number === 0) {
                localStorage.listNumber = length - 3
            }
            if (number === 1) {
                localStorage.listNumber = length - 2
            }
            if (number === 2) {
                localStorage.listNumber = length - 1
            }
        } else {
            localStorage.listNumber = number
        }
    };

    submitForm = data => {
        const { postListnameChosenProducts, getMainPageChosenLists } = this.props;
        this.setState({ loading: true });
        let obj = {
            name: data.name,
            mileage: data.mileage,
            vin: data.vin //TODO
        };
        postListnameChosenProducts(obj).then(res => {
            if ( res.payload && res.payload.status && res.payload.status === 201 ) {
                this.openDialogFavorites();
                getMainPageChosenLists();
            }
            this.setState({ loading: false });
        });
    };

    render(){
        const { chosen_list } = this.props.user;
        const { openFavorites, loading } = this.state;
        if ( !localStorage.token ) return null;
        if (!isArray(chosen_list.list)) return <Preloader />;
        return (
            <div className="dashboard-favorites">
                {chosen_list.load ?
                    <div className="container">
                        <div className="title-block">
                            <div className="title-wrapper">Избранное</div>
                            <div>
                                <Button
                                    onClick={this.openDialogFavorites}
                                    disableRipple ={true}
                                    classes={{
                                        root: 'add_btn'
                                    }}
                                >
                                    Добавить
                                    <img src={AddIcon} alt="edit icon"/>
                                </Button>
                                <DialogFavorites
                                    openFavorites={openFavorites}
                                    openDialogFavorites={this.openDialogFavorites}
                                    onSubmitAction={this.submitForm}
                                    loading={loading}
                                />
                            </div>
                        </div>
                        <div className="favorites-block">
                            {chosen_list.list.length > 4 ?
                                <Fragment>
                                    <Link to={`/main/favorites`} className="info-block parts">
                                        <h3>{chosen_list.list[0].name}</h3>
                                        <span>{`${chosen_list.list[0].count_prod} запчаст${chosen_list.list[0].count_prod === 1
                                            ? "ь" : chosen_list.list[0].count_prod > 1 && chosen_list.list[0].count_prod < 5 ? "и" : "ей" }`}</span>
                                    </Link>
                                    {chosen_list.list.slice(-3).map((item, i) => (
                                        <Link to={`/main/favorites`} className="info-block parts" key={item.id} onClick={() => this.handleClick(item.id, i, chosen_list.list.length)}>
                                            <h3>{item.name}</h3>
                                            <span>{`${item.count_prod} запчаст${item.count_prod === 1 ? "ь" : item.count_prod > 1 && item.count_prod < 5 ? "и" : "ей" }`}</span>
                                        </Link>

                                    ))}
                                </Fragment>
                                :
                                <Fragment>
                                    {chosen_list.list.map((item, i) => (
                                        <Link to={`/main/favorites`} className="info-block parts" key={item.id} onClick={() => this.handleClick(item.id, i)}>
                                            <h3>{item.name}</h3>
                                            <span>{`${item.count_prod} запчаст${item.count_prod === 1 ? "ь" : item.count_prod > 1 && item.count_prod < 5 ? "и" : "ей" }`}</span>
                                        </Link>

                                    ))}
                                </Fragment>
                            }
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        user: state.user
    }
};

const mapDispatchToProps = {
    getMainPageChosenLists,
    postListnameChosenProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFavorites);