import React, {Component} from 'react';
import {connect} from 'react-redux';

// import { bindActionCreators } from 'redux';
// import {Field, FieldArray, SubmissionError} from 'redux-form';
// import RenderField from '../../HelperComponents/RenderField/RenderField';
// import RenderOrderProducts from '../../HelperComponents/RenderOrderProducts/RenderOrderProducts';
// import SelectComponent from '../../HelperComponents/SelectComponent/SelectComponent';
// import FormControl from '@material-ui/core/FormControl';

import {Link} from 'react-router-dom';
import moment from "moment";

import BackLink from '../../BackLink/BackLink';
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import CommentOrders from './CommentOrders/CommentOrders';
import Preloader from '../../HelperComponents/Preloader/Preloader';
import TransitionedBlock from '../../HelperComponents/TransitionedBlock/TransitionedBlock';

import { CurrentURL } from "../../../actions/updateRedux";
import { getNotifications } from "../../../actions/notificationsActions";

// import PopoverComponent from '../../HelperComponents/PopoverComponent/PopoverComponent';

import { getStatusColor, getStatus} from "../../../helpers/functions";
import { getOrder, cancelOrder } from "../../../actions/ordersActions";
import Rating from '../../HelperComponents/Rating/Rating';

import InfoContacts from './InfoContacts/InfoContacts';
import InfoRepresentation from './InfoRepresentation/InfoRepresentation';
import './OrderDetail.scss';

import IconButton from '@material-ui/core/IconButton';
import ClockIcon from '../../../assets/image/time.png';
import CloseDialogIcon from '../../../assets/image/Mask.png';


class OrderDetail extends Component {

    state = {
        status: {},
        open: false
    };

    componentDidMount() {
        const { getOrder, match:{params}, history } = this.props;
        getOrder(params.id).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200){
                let s = res.payload.data.status, obj = {};
                if (s === 'new') {obj = {label: 'Новый', value: 'new'} }
                else if (s === 'processing') {obj = {label: 'Готов к выдаче', value: 'processing'}}
                else if (s === 'complete') {obj = {label: 'Выполнен', value: 'complete'}}
                else if (s === 'denied') {obj = {label: 'Отказ', value: 'denied'}}

                this.setState({ status: obj });

            }
            if(res.error && res.error.response && res.error.response.status && res.error.response.status === 404) {
                history.push('/main/orders');
            }
            if(res.error && res.error.response.status === 401) {
                localStorage.clear();
                history.push('/main');
            }
        });
    }

    componentWillUnmount() {
        this.props.orders.order = {};
    }

    handleClose = () => {
        this.setState({openDialog: false});
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    deleteOrder = () => {
        const { cancelOrder, match:{params}, history } = this.props;
        let obj = {"status": "denied"};
        cancelOrder(obj, params.id).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200){
                history.push('/main/orders');
            }
        });
    };

    setURL = (url) => {
        const { CurrentURL} = this.props;
        CurrentURL(url);
    };

    render(){
        const { orders:{ order } } = this.props;
        const { open } = this.state;

        if( !order.id ) return <Preloader/>;
        let color = getStatusColor( order.status );

        return (
            <TransitionedBlock>
                <main className="container">
                    <div className="title-block">
                        <BackLink to="/main/orders"/>
                        <h1>Заказ { order.id }</h1>
                    </div>
                    <div className="customer-page">
                        <div className="flex-center-btw">
                            <p className="date_wrapper">
                                <img src={ ClockIcon } alt="clock"/>
                                { moment( order.date ).format( 'DD.MM.YYYY HH:mm' )}

                            </p>
                            <p className="status_title"><span style={{ backgroundColor: color }}></span>{ getStatus( order.status )}</p>
                        </div>
                        <div className="descriptions-wrapper">
                            Поставщик
                        </div>
                        <div className="reviews-wrapper">
                            <p>Компания</p>
                            <div className="rating-block">
                                <Link className="name"
                                      to={`/main/supplier-information/${order.company_id}`}
                                      onClick={this.setURL(window.location.pathname)}>
                                    <div>{ order.company }</div>
                                </Link>
                                <Rating  value={ order.company_rating } rating_info />
                                <div className="order-commends">
                                    {order.total_reviews} отзыв{order.total_reviews===1 ? null : order.total_reviews>1 && order.total_reviews<5 ? "a" : "ов" }
                                </div>
                            </div>
                        </div>

                        <InfoContacts phones={order.phones}/>
                        <InfoRepresentation order={order}/>

                        <div className="product-block">
                            <div className="title">Товар(ы)</div>
                            {order.orderproducts.map(( item, i ) => (
                                <div className="product-info" key={ "orderproducts"+i }>
                                    <div className="info">
                                        <div className="name-product">
                                            <span className="name-name">{ item.name }</span>
                                            <p>•</p>
                                            <span>{item.article_number}</span>
                                        </div>
                                        <div className="price-info">
                                            <span>{item.quantity} x</span>
                                            <div>{item.price} ₽</div>
                                        </div>
                                    </div>
                                    <div className="firms">{item.producer}</div>
                                </div>
                            ))}
                            <div className="all-product-wrapper">
                                <div className="all-price">
                                    <span>Всего:
                                        <span className="cost-block">{order.orderproducts.length} товар{order.orderproducts.length===1 ? null : order.orderproducts.length>1 && order.orderproducts.length<5 ? "a" : "ов" }</span>
                                    </span>
                                        <span className="t-b"><span className="total-block"> { order.total } </span> ₽ </span>
                                </div>
                            </div>
                        </div>
                        { order.status === "denied" || order.status === "complete" ? null :
                        <div className="btn-wrapper">
                            <DefaultButton
                                variant="outlined"
                                classes="delete_btn"
                                type="button"
                                onClick={this.toggleDialog}
                            >
                                Отказаться
                            </DefaultButton>
                        </div>}
                    </div>
                    <CommentOrders id = {order.id}/>
                </main>

                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="title-dialog">
                        Отказаться от заказа
                    </div>
                    <div className="block-change-info">
                        <div className="text-info">
                            Вы уверены ?
                        </div>
                        <div className="btn-wrapper">

                            <DefaultButton
                                variant="outlined"
                                classes="cancel_btn"
                                onClick={this.toggleDialog}
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                onClick={this.deleteOrder}
                            >
                                Отказаться
                            </DefaultButton>
                        </div>
                    </div>


                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
            </TransitionedBlock>
        )
    }
}

function mapStateToProps(state) {
    return{
        orders: state.orders
    }
}

const mapDispatchToProps = {
    getOrder,
    cancelOrder,
    CurrentURL,
    getNotifications
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
