import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
//import Slider, { Range } from 'rc-slider';
import Search from '../../../assets/image/search_grey.png';
import 'rc-slider/assets/index.css';
import ShowMap from  '../../ShowMap/ShowMap';
import './FiltersOffers.scss';
import Preloader from "../../HelperComponents/Preloader/Preloader";

const Slider = require('rc-slider');
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);


class FiltersOffers extends Component {

    state = {
        value: '',
        title: {
          price: 'Цена, ₽', delivery_day: 'Срок поставки, дн.', amount: 'Наличие, шт.',
        }
    };

    onChange = (e) => {
        let value = e.target.value;
        this.setState({value});
        this.props.search(value)
    };

    // onKeyUp = (e) => {
    //     const { getProducts } = this.props;
    //     let value = e.target.value;
    //     /*if(value.indexOf(" ") === -1){
    //         getProducts(value);
    //     }*/
    // };

    componentDidMount = () => {}

    componentDidUpdate = (prevProps, prevState) => {}

    render(){
        const {user, dataFilter, setMaxMinFilter, filteredMainSearch} = this.props;
        const { value, title} = this.state;
        if (!filteredMainSearch) return <Preloader/>;

        return (
            <div className="filters-offers-wrapper">
                <div className="filters-offers">
                    <div className="title">Фильтры</div>
                    <div className="filters-search">
                        <input
                            type="text"
                            placeholder="Поиск"
                            value={value}
                            onChange={this.onChange}
                            onKeyUp={this.onKeyUp}
                        />
                        <button className="btn_search"><img src={Search} alt="Search"/></button>
                    </div>
                    {
                      Object.keys(dataFilter).map((name, index) => {
                        return <ElementFilter key={index} title={title[name]} filter={name}
                                                options={dataFilter[name]} setValue={setMaxMinFilter}/>
                      })
                    }
                </div>
                <ShowMap user={user} main_data={filteredMainSearch}/>
            </div>

        );
    }
}

class ElementFilter extends Component {
  state = {
    min: 0,
    max: 0,
    update: false
  }

  componentDidMount = () => this.setState({min: this.props.options.setMin, max: this.props.options.setMax});

  componentDidUpdate = (prevProps, prevState) => {
    const {min, max} = this.state;
    if(prevProps !== this.props && !this.state.update) {
      const min = (this.props.options.setMin == null) ? 0 : this.props.options.setMin;
      this.setState({min: min, max: this.props.options.setMax});
    }
    if(prevState !== this.state && this.state.update) this.props.setValue(this.props.filter, {min, max});
  }

  handlerRange = (value) => {
    this.setState({min: value[0], max: value[1], update: true})
  }

  setMin = (e) => this.setState({min: e.target.value});

  setMax = (e) => this.setState({max: e.target.value});

  render(){
    const {title, options} = this.props;
    const {min, max} = this.state;
    return(
      <div className="filters-field">
          <div className="descriptions">{title}</div>
          <div className="filters-input">
              <input type="text" value={this.state.min} placeholder="0" onChange={(e) => this.setMin(e)}/>
              <div className="arrow"></div>
              <input type="text" value={this.state.max} placeholder="0" onChange={(e) => this.setMax(e)}/>
          </div>
          <Range value={[min, max]} min={options.setMin} max={options.setMax} onChange={(value) => this.handlerRange(value)}/>
      </div>
    )
  }
}

function mapStateToProps(state) {
    return{
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //login
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersOffers);
