import * as types from "../actions/constants";

const INITIAL_STATE = {
    url: "/main"
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.CURRENT_URL :
            return {...state, url : action.data};

        default:
            return state;
    }
}