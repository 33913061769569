import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Reviews from '../Reviews/Reviews';
import Supplier from '../SupplierInformation/Supplier/Supplier';
// import Preloader from '../../HelperComponents/Preloader/Preloader';


import {getSupplierInfo, getSupplierReviews} from '../../actions/ordersActions';

import './SupplierInformation.scss';
import Preloader from "../HelperComponents/Preloader/Preloader";
import {getNotifications} from "../../actions/notificationsActions";
import {ref} from '../Reviews/Reviews';
import BackIcon from "../../assets/image/back.png";

class SupplierInformation extends Component {


    state = {
        open: [],
        mapOptions: [],
        activePage: 1
    };


    componentDidMount() {
        this.doRequest();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.match.params !== this.props.match.params) {
            this.doRequest();
        }
    }

    doRequest = () => {
        const { getSupplierInfo, getSupplierReviews, match: {params}, history } = this.props;
        const {activePage} = this.state;


        getSupplierInfo(params.idProvider).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                res.payload.data.addresses.forEach((el) => {
                    window.ymaps.ready(() => {
                        window.ymaps.geocode(el.coordinates).then(response => {
                            let obj = {
                                id: el.id,
                                center: response.geoObjects.get(0).geometry.getCoordinates(),
                                zoom: 12
                            };
                            this.setState(({mapOptions}) => ({mapOptions: [...mapOptions, obj]}));
                        });
                    });
                });
                getNotifications();
            }
            if(res.error && res.error.response.status === 401) {
                localStorage.clear();
                history.push('/main');
            }
        });
        getSupplierReviews(params.idProvider, activePage);
    };


    toggleOpen = id => {
        const {open} = this.state;
        if (open.includes(id)) {
            this.setState(({open}) => ({
                open: open.filter(el => el !== id)
            }));
        } else {
            this.setState(({open}) => ({
                open: [...open, id]
            }));
        }
    };

    changePage = (pageNumber) => {
        const {getSupplierReviews, match: {params}} = this.props;
        this.setState({activePage: pageNumber.selected + 1});
        getSupplierReviews(params.idProvider, pageNumber.selected + 1);
    };

    handleClick = () => ref.current.scrollIntoView( {
        behavior: 'smooth',
        block: 'start',
    });

    handleBack = () => {
        const { location: { search }, history } = this.props;
        const params = new URLSearchParams(search.substring(1));
        const offersId = params.get('offers');
        history.push(offersId ? `/main/offers/${offersId}` : "/main");
    };


    render() {
        const {orders: {supplier_info}, orders: {supplier_reviews}, match: {params}} = this.props;
        const {open, mapOptions, activePage} = this.state;

        if (!supplier_info.id || !supplier_reviews.results || !mapOptions.length) return <Preloader/>;

        return (
            <div className="container">
                <div className="title-block">
                    <div className="app-back_link">
                        <img
                            src={BackIcon}
                            alt="back icon"
                            onClick={this.handleBack}
                        />
                    </div>
                    {/*<BackLink/>*/}
                    <h1>Информация о поставщике</h1>
                    <div className="customer-page">
                        <Supplier
                            supplier_info={supplier_info}
                            mapOptions={mapOptions}
                            open={open}
                            toggleOpen={this.toggleOpen}
                            handleClick={this.handleClick}
                        />
                    </div>
                    <Reviews
                        supplier_reviews={supplier_reviews}
                        changePage={this.changePage}
                        activePage={activePage}
                        idProvider={params.idProvider}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        orders: state.orders
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getSupplierInfo,
        getSupplierReviews,
        getNotifications
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierInformation);
