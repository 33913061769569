import * as types from './constants.jsx';

// notifications actions

export function getNotifications() {
    return {
        type: types.GET_NOTIFICATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/notification/client/`,
                method: "get"
            }
        }
    };
}
