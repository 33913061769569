import React from 'react';

import './NoItems.scss';

const NoItems =(props)=> {

    return (
        <div className="no-items">
            <div className="no-orders item-block">
                {props.children}
            </div>
        </div>
    );

};


export default NoItems;