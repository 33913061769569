import * as types from "../actions/constants";

const INITIAL_STATE = {
    families: {},
    params_set: {},
    modification: {},
    part_group: {},
    tree: {},
    tree_node: {},
    tree_node_parts: {},

    error: {}
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.GET_CATALOGS_MODEL_SUCCESS :
            return {...state, families: action.payload.data};
        case types.GET_PARAMS_SET_SUCCESS :
            return {...state, params_set: action.payload.data};
        case types.GET_MODIFICATION_SUCCESS :
            return {...state, modification: action.payload.data};
        case types.GET_PART_GROUPS_SUCCESS :
            return {...state, part_group: action.payload.data};
        case types.GET_TREE_SUCCESS :
            return {...state, tree: action.payload.data};
        case types.SEARCH_TREE_NODE_SUCCESS :
            return {...state, tree_node: action.payload.data};
        case types.GET_TREE_NODE_PARTS_SUCCESS :
            return {...state, tree_node_parts: action.payload.data};

        default:
            return state;
    }
}
