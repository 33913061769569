import * as types from './constants.jsx';


export function getCatalogsAuto(tab) {
    return {
        type: types.GET_CATALOGS_AUTO,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=CatalogsListGet&type=0`,
                method: "get"
            }
        }
    };
}

export function getCatalogsTruck(tab) {
    return {
        type: types.GET_CATALOGS_TRUCK,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=CatalogsListGet&type=1`,
                method: "get"
            }
        }
    };
}

export function getCatalogsMoto(tab) {
    return {
        type: types.GET_CATALOGS_MOTO,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=CatalogsListGet&type=2`,
                method: "get"
            }
        }
    };
}

export function getCatalogModel(id, type) {
    return {
        type: types.GET_CATALOGS_MODEL,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=ModelsListGet2&catalog_code=${id}&type=${type}`,
                method: "get"
            }
        }
    };
}

export function getParamsSet(id, family, model, paramsModel) {
    return {
        type: types.GET_PARAMS_SET,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=VehicleParamsSet&lang=ru&catalog_code=${id}&family=${family}&model=${model}${paramsModel}`,
                method: "get"
            }
        }
    };
}

export function getModification(ssd) {
    return {
        type: types.GET_MODIFICATION,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=VehicleModificationsGet&lang=ru&ssd=${ssd}`,
                method: "get"
            }
        }
    };
}

export function getPartGroups(ssd, link) {
    return {
        type: types.GET_PART_GROUPS,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=PartGroupsGet&lang=ru&ssd=${ssd}&link=${link}`,
                method: "get"
            }
        }
    };
}

export function getTree(link, ssd) {
    return {
        type: types.GET_TREE,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=TreeGet&lang=ru&link=${link}&ssd=${ssd}`,
                method: "get"
            }
        }
    };
}

export function searchTreeNode(link, ssd, path) {
    return {
        type: types.SEARCH_TREE_NODE,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=TreeNodeSearch&lang=ru&link=${link}&ssd=${ssd}&path=${path}`,
                method: "get"
            }
        }
    };
}

export function getTreeNodeParts(link, ssd, group) {
    return {
        type: types.GET_TREE_NODE_PARTS,
        payload: {
            client: 'default',
            request: {
                url: `/catalog/?method=TreeNodePartsGet&lang=ru&link=${link}&ssd=${ssd}&group=${group}`,
                method: "get"
            }
        }
    };
}



