import React, {Fragment} from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import AuthHead from '../../components/Auth/AuthHead/AuthHead';
import Login from "../../components/Auth/Login/Login";
import Register from "../../components/Auth/Register/Register";
import Activation from "../../components/Auth/Activation/Activation";
import FirstStep from "../../components/Auth/PasswordRecovery/FirstStep/FirstStep";
import SecondStep from "../../components/Auth/PasswordRecovery/SecondStep/SecondStep";

import LogoLarge from '../../assets/image/logo_large.png';

import './AuthContainer.scss';
import NotFound from "../../components/NotFound/NotFound";

const AuthContainer = (props) => {
    const { location, match } = props;
    if(!!localStorage.token) return <Redirect to="/main" />;
    return (
        <Fragment>
            <AuthHead location={location}/>
            <main className="auth_container">
                <div className="auth-box">
                    <div className="auth_bg">
                        <div className="auth_logo">
                            <img src={LogoLarge} alt="logo"/>
                        </div>
                        <p>
                            Почувствуйте разницу в обслуживании любимого
                            автомобиля - экономьте свое время и деньги вместе с нами
                        </p>
                    </div>
                    <div className="auth_content">
                        <Switch>
                            <Route path={match.url} exact component={Login} />
                            <Route path={`${match.url}/register`} exact component={Register} />
                            <Route path={`${match.url}/activation`} exact component={Activation} />
                            <Route path={`${match.url}/password-recovery`} exact component={FirstStep} />
                            <Route path={`${match.url}/password-recovery/approve`} exact component={SecondStep} />
                            <Route path={`${match.url}/no-match`} component={NotFound} />
                            <Route render={() =>  <Redirect to="/main/no-match" push /> } />
                            {/*<Route render={()=>(<p>Not found</p>)} />*/}
                        </Switch>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default AuthContainer;