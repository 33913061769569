import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Preloader from "../HelperComponents/Preloader/Preloader";
import LogoFavourites2 from "../../assets/image/favourites2.png";
import LogoFavourites from "../../assets/image/favourites.png";
import {
  postNewChosenProduct,
  getChosenProducts,
  initialFavouritesList,
  getSearchParts,
} from "../../actions/userActions";

import "./SearchPage.scss";

import ModalFavorites from "./ModalFavorites";

class SearchPage extends Component {
  state = {
    open_modal: false,
    id: null,
    chosenList: [],
  };

  // componentDidMount() {
  //     const {
  //         match: {
  //             params: { article },
  //         },
  //         getSearchParts,
  //     } = this.props;
  //     console.log(article);
  //     getSearchParts(article);
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      !!this.props.user.search_results &&
      prevProps.user.search_results !== this.props.user.search_results
    ) {
      if (!this.props.user.search_results.load)
        this.initChosen(this.props.user.search_results.list);
    }
  }

  initChosen = (data) => {
    let chosenList = [];
    if (!!data.genuine)
      chosenList = data.genuine
        .filter((val) => val.chosen)
        .map((val) => val.id);
    if (!!data.analog)
      chosenList = data.analog.filter((val) => val.chosen).map((val) => val.id);
    this.props.initialFavouritesList(chosenList);
  };

  addFavoriteProduct = (e, id) => {
    e.stopPropagation();
    this.stateModal(id);
  };

  stateModal = (id) =>
    this.setState((prevState) => ({
      open_modal: !prevState.open_modal,
      id: id,
    }));

  render() {
    const { user } = this.props;
    const { id } = this.state;
    // const type = 'offers_list';

    return (
      <div className="container">
        {!!user.profile.id ? (
          <ModalFavorites
            open={this.state.open_modal}
            stateModal={this.stateModal}
            id={id}
            chosen={user.chosen_favorites_list.indexOf(id) !== -1}
          />
        ) : null}
        {user.search_results &&
        user.search_results.list &&
        user.search_results.list.models &&
        user.search_results.list.models.length > 1 ? (
          <div className="search-page">
            <p className="title-wrapper"> Выберите модификацию </p>
            <div className="transactions_columns_vin">
              <div className="table_header">
                <div className="table_row">
                  {Object.keys(user.search_results.list.models[0]).map(
                    (title, idTitle) => {
                      if (title !== "link")
                        return (
                          <div className="row_item" key={idTitle}>
                            {title}
                          </div>
                        );
                      else return null;
                    }
                  )}
                </div>
              </div>
              <div className="table_body">
                {user.search_results.list.models.map((model, idModel) => {
                  const path = user.search_results.list.client;
                  return (
                    <Link
                      className="table_row"
                      to={`/main/catalogs/0/${
                        path.catalog_code
                      }/${encodeURIComponent(path.family)}/${encodeURIComponent(
                        path.model
                      )}/${path.ssd}/${model.link}/${encodeURIComponent(
                        path.mark
                      )}`}
                      key={idModel}
                    >
                      {Object.values(model).map((value, idValue) => {
                        if (value.length < 80)
                          return (
                            <div className="row_item" key={idValue}>
                              {value}
                            </div>
                          );
                        else return null;
                      })}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        ) : user.search_results && !!user.search_results.list ? (
          user.search_results && user.search_results.load ? (
            <Preloader />
          ) : (!!user.search_results.list.genuine &&
              user.search_results.list.genuine.length > 0) ||
            (!!user.search_results.list.analog &&
              user.search_results.list.analog.length > 0) ? (
            <div className="search-page">
              <p className="title-wrapper">
                {!!user.search_results.list.genuine
                  ? "Найдено несколько совпадений"
                  : "Оригинальных товаров по заданному артикулу не найдено"}
              </p>
              <div className="table_container transactions_columns">
                <div className="table_header">
                  <div className="table_row">
                    <div className="row_item">Производитель</div>
                    <div className="row_item">Артикул</div>
                    <div className="row_item">Запчасть</div>
                    <div className="row_item">Цена</div>
                    {!!user.profile.id ? <div className="row_item" /> : null}
                  </div>
                </div>
                <div className="table_body table_body_starting_search">
                  {!!user.search_results.list.genuine &&
                  user.search_results.list.genuine.length > 0 ? (
                    user.search_results.list.genuine.map((product, index) => (
                      <div
                        className="table_row"
                        key={index}
                        onClick={() =>
                          this.props.history.push(`/main/offers/${product.id}`)
                        }
                      >
                        <div className="row_item">{product.producer_name}</div>
                        <div className="row_item">{product.article_number}</div>
                        <div className="row_item">{product.name}</div>
                        <div className="row_item">
                          от <p>{product.price} ₽</p>
                        </div>
                        {!!user.profile.id ? (
                          <div className="row_item">
                            <button
                              onClick={(e) =>
                                this.addFavoriteProduct(e, product.id)
                              }
                            >
                              <img
                                className="focus-LogoFavourites"
                                src={LogoFavourites}
                                alt="LogoFavourites2"
                              />
                              <img
                                className="not-focus"
                                src={
                                  user.chosen_favorites_list.indexOf(
                                    product.id
                                  ) !== -1
                                    ? LogoFavourites
                                    : LogoFavourites2
                                }
                                alt="LogoFavourites2"
                              />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    ))
                  ) : !!user.search_results.list.analog &&
                    user.search_results.list.analog.length > 0 ? (
                    user.search_results.list.analog.map((product, index) => (
                      <div
                        className="table_row"
                        key={index}
                        onClick={() =>
                          this.props.history.push(`/main/offers/${product.id}`)
                        }
                      >
                        <div className="row_item">{product.producer_name}</div>
                        <div className="row_item">{product.article_number}</div>
                        <div className="row_item">{product.name}</div>
                        <div className="row_item">
                          от <p>{product.price} ₽</p>
                        </div>
                        <div className="row_item">
                          <button
                            onClick={(e) =>
                              this.addFavoriteProduct(e, product.id)
                            }
                          >
                            <img
                              className="focus-LogoFavourites"
                              src={LogoFavourites}
                              alt="LogoFavourites2"
                            />
                            <img
                              className="not-focus"
                              src={
                                user.chosen_favorites_list.indexOf(
                                  product.id
                                ) !== -1
                                  ? LogoFavourites
                                  : LogoFavourites2
                              }
                              alt="LogoFavourites2"
                            />
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="search-page">
                      <p className="title-wrapper">Нет совпадений</p>
                      <div className="search-message">
                        По Вашему запросу ничего не найдено. Убедитесь в
                        правильности поискового запроса и повторите попытку.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="search-page">
              <p className="title-wrapper">Нет совпадений</p>
              <div className="search-message">
                По Вашему запросу ничего не найдено. Убедитесь в правильности
                поискового запроса и повторите попытку.
              </div>
            </div>
          )
        ) : (
          <div className="search-page">
            <p className="title-wrapper">Введите запрос</p>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      initialFavouritesList,
      postNewChosenProduct,
      getChosenProducts,
      getSearchParts,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPage);
