import React from 'react';
import ReactStars from 'react-stars';

import { getRating } from "../../../helpers/functions";

import './Rating.scss';

const Rating = ({value, rating_info, ratingSize}) => {
    return (
        <div className="rating_wrapper">
            <ReactStars
                count={5}
                size={ratingSize ? ratingSize : 18}
                value={value}
                edit={false}
                color1="#EFF2F4"
                color2="#F5A623"
            />
            {rating_info ? null : <span>{getRating(value)}</span>}
        </div>
    );
};

export default Rating;
