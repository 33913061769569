import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
// import Loader from '../HelperComponents/Loader/Loader';
import TelegramIcon from '../../../assets/image/telegram.png';
import ViberIcon from '../../../assets/image/viber.png';
import WhatsappIcon from '../../../assets/image/whatsapp.png';

import './InfoContacts.scss';


class InfoContacts extends Component {


    render(){
        return (
            <Fragment>
                <div>
                    <div className="contacts" >
                        <span className="block_label">Телефон(ы)</span>
                        <div className="contacts-info">
                            <div className="info">962-562-1372</div>
                            <div className="info-social">
                                <img src={TelegramIcon} alt="telegram icon"/>
                                <img src={ViberIcon} alt="viber icon"/>
                                <img src={WhatsappIcon} alt="whatsapp icon"/>
                            </div>
                        </div>
                    </div>

                    {/*<p className="info_section_no_items block_value">У вас нет добавленых контактов</p>*/}

                    {/*<div className="info_section_loader">*/}
                    {/*<Loader/>*/}
                    {/*</div>*/}

                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoContacts);
