import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleLogin from 'react-google-login';
import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from '../../Buttons/DefaultButton/DefaultButton';

import IconGoogle from '../../../assets/image/icon-google.png'

import { postRegister, postLoginGoogle } from "../../../actions/authActions";

class Register extends Component {

    state = {
        loading: false
    };

    signup = (response) => {
        const { postLoginGoogle, history } = this.props;
        let obj = {access_token: response.Zi.access_token};

        postLoginGoogle(obj).then((res) => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.token = res.payload.data.key;
                history.push('/main');
            } else {
                this.setState({loading: false});
            }
        });
    };

    submitForm = data => {
        const { postRegister, history } = this.props;
        this.setState({loading: true});
        let obj = data;
        obj.role = "customer";
        postRegister(obj).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 201) {
                history.push('/auth/activation');
            } else {
                this.setState({loading: false});
            }

        });
    };

    render(){
        const { handleSubmit, submitting, pristine, valid,  auth: { error_auth } } = this.props;
        const { loading } = this.state;
        const responseGoogle = (response) => {
            this.signup(response);
        };
        return (
            <form onSubmit={handleSubmit(this.submitForm)} className="auth_content_form">
                <h3 className="auth-block_head">Регистрация</h3>
                <Field name="first_name" type="text" component={RenderField} label="Имя"/>
                <Field name="last_name" type="text" component={RenderField} label="Фамилия"/>
                <Field name="phone" type="number" component={RenderField} label="Телефон"/>
                <Field name="email" type="text" component={RenderField} label="Электронный адрес"/>
                <Field name="password" type="password" component={RenderField} label="Пароль" password/>
                <DefaultButton
                    variant="contained"
                    classes="full_btn_width"
                    disabled={submitting || pristine || !valid}
                    loading={loading}
                    formAction

                >
                    Зарегистрироваться
                </DefaultButton>
                <div className="default_button_wrapper default_button_wrapper_relative">
                    <GoogleLogin
                        // clientId="404485370777-o5fvauqq0j5o5p3niohcuuno59252727.apps.googleusercontent.com"
                        clientId="507960300312-6v4bcqnba0i24lgpuuk7vd2h4dstjdb2.apps.googleusercontent.com"
                        render={renderProps => (
                            <button onClick={renderProps.onClick} className="default_button full_btn_width google-btn">
                                <img src={IconGoogle} alt="IconGoogle"/><span>Войти с помощью Google</span>
                            </button>
                        )}
                        buttonText="Войти с помощью Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />
                </div>
                {error_auth && Object.keys(error_auth).length !== 0  && error_auth.hasOwnProperty("error") ?
                    null
                    :
                    error_auth && Object.keys(error_auth).length !== 0 ?
                        <div className="auth_error error_after_button">
                            <span>{error_auth[Object.keys(error_auth)[0]]}</span>
                        </div>
                        :
                        null
                }
            </form>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.first_name) {
        errors.first_name = 'Обязательное поле'
    } else if (values.first_name.length < 3 || values.first_name.length > 15) {
        errors.first_name = 'Должно быть больше 2 и меньше 15 символов'
    }
    if (!values.last_name) {
        errors.last_name = 'Обязательное поле'
    } else if (values.last_name.length < 3 || values.last_name.length > 15) {
        errors.last_name = 'Должно быть больше 2 и меньше 15 символов'
    }
    if (!values.email) {
        errors.email = 'Обязательное поле'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = 'Неправильный email'
    }
    if (!values.password) {
        errors.password = 'Обязательное поле'
    } else if (values.password.length < 8) {
        errors.password = 'Должно быть 8 символов или больше'
    }
    if (!values.phone) {
        errors.phone = 'Обязательное поле'
    }

    return errors
};

Register = reduxForm({
    form: 'RegisterForm',
    validate
})(Register);

function mapStateToProps(state) {
    return{
        auth: state.auth
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postRegister,
        postLoginGoogle
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);