import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import Preloader from '../../HelperComponents/Preloader/Preloader';
import LogInToAccess from '../../HelperComponents/LogInToAccess/LogInToAccess';

import {getCatalogsAuto, getCatalogsTruck, getCatalogsMoto} from "../../../actions/catalogActions";

import './DashboardCatalog.scss';

class DashboardCatalog extends Component {

    state = {
        tab: 0,
        catalogsAuto: [],
        catalogsTruck: [],
        catalogsMoto: [],
        loading: true,
        allItems: false,
        type: 0,
        unauthorized: !localStorage.getItem("token")
    };

    componentDidMount() {
        const {getCatalogsAuto, getCatalogsTruck, getCatalogsMoto} = this.props;
        const { unauthorized } = this.state;
        if (!unauthorized) {
            getCatalogsAuto().then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200 ) {
                    this.setState({
                        catalogsAuto: res.payload.data.catalogs,
                        loading: false
                    });
                }
            });
            getCatalogsTruck().then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200 ) {
                    this.setState({
                        catalogsTruck: res.payload.data.catalogs
                    });
                }
            });
            getCatalogsMoto().then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200 ) {
                    this.setState({
                        catalogsMoto: res.payload.data.catalogs
                    });
                }
            });
        }
    }

    changeTab = tab => {
        this.setState({tab});
    };

    toggleAllItems = () => {
        this.setState(({allItems}) => ({
            allItems: !allItems
        }));
    };

    render() {
        const { history } = this.props;
        const { tab, catalogsAuto, catalogsTruck, catalogsMoto, allItems, loading, unauthorized } = this.state;
        let varChar = '';

        if (unauthorized) return <LogInToAccess history={history} />;

        if (loading) return <Preloader/>;

        return (
            <div className="dashboard-catalog">
                {!!catalogsAuto ?
                    <Fragment>
                        <div className="title-wrapper">Каталоги автозапчастей</div>
                        <div className="dashboard-tabs">
                            <div className="tab-button">
                                <button className={`${tab === 0 ? 'active' : ''}`}
                                        onClick={() => this.changeTab(0)}>Авто
                                </button>
                                <button className={`${tab === 1 ? 'active' : ''}`}
                                        onClick={() => this.changeTab(1)}>Грузовые
                                </button>
                                <button className={`${tab === 2 ? 'active' : ''}`}
                                        onClick={() => this.changeTab(2)}>Мото
                                </button>
                            </div>
                            {tab === 0 &&
                            <div className={`${!allItems ? "catalog" : "catalog catalog-large"}`}>
                                {!allItems ?
                                    catalogsAuto.map((item, i) => {
                                        if (item.name[0] !== varChar) {
                                            varChar = item.name[0];
                                            return (
                                                <Link className="catalog-info"
                                                      to={`/main/catalogs/${tab}/${item.catalog_code}`}
                                                      key={i}>
                                                    <p>{item.name}</p>
                                                    <div className="letter">{item.name[0]}</div>
                                                </Link>
                                            )
                                        } else {
                                            return (
                                                <Link className="catalog-info"
                                                      to={`/main/catalogs/${tab}/${item.catalog_code}`}
                                                      key={i}>
                                                    <p>{item.name}</p>
                                                </Link>
                                            )
                                        }
                                    })
                                    :
                                    catalogsAuto.map((item, i) => {
                                        if (item.name[0] !== varChar) {
                                            varChar = item.name[0];
                                            return (
                                                <Link className="catalog-info"
                                                      to={`/main/catalogs/${tab}/${item.catalog_code}`}
                                                      key={i}>
                                                    <p>{item.name}</p>
                                                    <div className="letter">{item.name[0]}</div>
                                                </Link>
                                            )
                                        } else {
                                            return (
                                                <Link className="catalog-info"
                                                      to={`/main/catalogs/${tab}/${item.catalog_code}`}
                                                      key={i}>
                                                    <p>{item.name}</p>
                                                </Link>
                                            )
                                        }
                                    })
                                }
                            </div>
                            }
                            {tab === 1 &&
                            <div className="catalog">
                                {catalogsTruck.map((item, i) => {
                                    if (item.name[0] !== varChar) {
                                        varChar = item.name[0];
                                        return (
                                            <Link className="catalog-info"
                                                  to={`/main/catalogs/${tab}/${item.catalog_code}`}
                                                  key={i}>
                                                <p>{item.name}</p>
                                                <div className="letter">{item.name[0]}</div>
                                            </Link>
                                        )
                                    } else {
                                        return (
                                            <Link className="catalog-info"
                                                  to={`/main/catalogs/${tab}/${item.catalog_code}`}
                                                  key={i}>
                                                <p>{item.name}</p>
                                            </Link>
                                        )
                                    }
                                })
                                }
                            </div>
                            }
                            {tab === 2 &&
                            <div className="catalog">
                                {catalogsMoto.map((item, i) => {
                                    if (item.name[0] !== varChar) {
                                        varChar = item.name[0];
                                        return (
                                            <Link className="catalog-info"
                                                  to={`/main/catalogs/${tab}/${item.catalog_code}`}
                                                  key={i}>
                                                <p>{item.name}</p>
                                                <div className="letter">{item.name[0]}</div>
                                            </Link>
                                        )
                                    } else {
                                        return (
                                            <Link className="catalog-info"
                                                  to={`/main/catalogs/${tab}/${item.catalog_code}`}
                                                  key={i}>
                                                <p>{item.name}</p>
                                            </Link>
                                        )
                                    }
                                })
                                }
                            </div>
                            }
                            {/*{catalogsAuto.length > 41 && tab === 0 ?*/}
                                {/*<div className="all-catalog">*/}
                                    {/*<button className="btn_hide" onClick={this.toggleAllItems}>*/}
                                        {/*{allItems ? "Скрыть" : "Показать все"}&nbsp;{!allItems ? <ExpandMoreIcon/> :*/}
                                        {/*<ExpandLessIcon/>}*/}
                                    {/*</button>*/}
                                {/*</div>*/}
                                {/*: null*/}
                            {/*}*/}
                        </div>
                    </Fragment>
                    :  <div className="title-wrapper">В данный момент каталог недоступен</div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        // catalogs: state.catalogs
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCatalogsAuto,
        getCatalogsTruck,
        getCatalogsMoto
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCatalog);