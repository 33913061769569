import React from 'react';
import TooltipMessage from "../TooltipMessage/TooltipMessage";
import ErrorIcon from "@material-ui/core/SvgIcon/SvgIcon";
import "./RenderTextarea.scss";

const RenderTextarea = ({ input, placeholder, autoFocus, symbol, name, disabled, meta: {touched, error} }) => {
    return (
        <div className="custom_area_field">
            <textarea
                {...input}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                name={name}
                style={touched && error ? {border: '1px solid red'} : null}
                className="area-contacts"
            />
            {
                touched && error ?
                    <TooltipMessage
                        text={error}
                        delay={200}
                        error
                    >
                        <ErrorIcon><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></ErrorIcon>
                    </TooltipMessage> :
                    null
            }

        </div>
    );

};

export default RenderTextarea;
