import React from "react";
import origin_label from "../img/green_label.png";
import analog_label from "../img/blue_label.png";

export default class TemplateProvider extends React.Component {
    constructor() {
        super();
        this.state = {templateBalloon: null, templateIcon: null};
    }

    componentDidMount() {
        this.setState({
            templateBalloon: this.props.ymaps.templateLayoutFactory.createClass(
                `<div style="display: flex; flex-flow: column wrap; justify-content: space-around; width: 375px; height: 150px; overflow: hidden;
          background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5); padding: 10px 10px;">
              <img {% if properties.type == 'origin' %} src="${origin_label}" alt="origin" {% else %} src="${analog_label}" alt="analog" {% endif %}
                    style="position: absolute; top: 0px; right: -7px;" />
              <div style="display: flex; justify-content: space-between; align-items: flex-end;">
                <div style="display: flex; align-items: baseline; justify-content: space-between; width: 165px; font-family: Akrobat;">
                  <a href="/main/supplier-information/{{properties.company_id}}" style="color: #0253B3; font-size: 16px; font-family: Akrobat;">{{properties.company}}</a>
                  <div>
                      <span style="margin-right: 3px; color: #7F8FA4;	font-family: Akrobat;	font-size: 12px;	font-weight: 300;	line-height: 15px;">рейтинг {{properties.rating}}</span>
                      <span style="margin-right: 10px; color: #7F8FA4;	font-family: Akrobat;	font-size: 12px;	font-weight: 300;	line-height: 15px;">{{properties.company_reviews}} отзыв</span>
                  </div>
                </div>
                <div style="height: 15px;	width: 48px; color: #334150; font-size: 12px;	line-height: 15px; margin-right: 25px; 	font-family: Akrobat;">
                {% if properties.type == 'origin' %}
                  ОРИГИНАЛ
                {% else %}
                  АНАЛОГ
                {% endif %}
                </div>
              </div>
              <div style="display: flex; flex-flow: column wrap; color: #7F8FA4; font-size: 14px; font-family: Akrobat; margin-top: 20px; ">
                <div style="color: #7F8FA4;	font-family: Akrobat;	font-size: 14px;	font-weight: 300;	line-height: 18px;">
                  <span style="color: #334150; font-weight: 600; margin-right: 7px; font-family: Akrobat;	font-size: 14px;">{{properties.producer_name}}</span>
                  #{{properties.article_number}}
                </div>
                <div style="color: #7F8FA4; font-family: Akrobat;	font-size: 14px;	font-weight: 300;	line-height: 18px;">{{properties.name}}</div>
              </div>
              <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-top: 10px;">
                <div style="display: flex; justify-content: space-between;	height: 15px;
                            color: #7F8FA4;	font-family: Akrobat;	font-size: 12px;	font-weight: 300;	line-height: 15px;">
                  {%if properties.amount %}
                  <div>
                    В наличии
                    <span style="	height: 17px;	width: 36px;	color: #334150;	font-family: Akrobat;	font-size: 14px;	line-height: 17px;">
                    {{properties.amount}} шт
                    </span>
                  </div>
                  {% else %}
                  <div>Под заказ</div>
                  {% endif %}
                  <div style="margin-left: 40px;">
                  {% if properties.deliveryTime != null %}
                    срок доставки
                    <span style="	height: 17px;	width: 36px;	color: #334150;	font-family: Akrobat;	font-size: 14px;	line-height: 17px;">
                      {{properties.deliveryTime}} дн.
                    </span>
                    {% endif %}
                  </div>
                </div>
                <div style="height: 29px;	color: #334150;	font-family: Roboto;	font-size: 27px; font-weight: 600;	line-height: 34px;">
                  {{properties.price}} ₽
                </div>
              </div>
              </div>`
            ),
            templateIcon: this.props.ymaps.templateLayoutFactory.createClass(
                '<div style="font-famaly: Roboto; font-size: 18px; color: #FFFFFF; font-weight: normal; width: 110px; margin: auto;">{{properties.price}} ₽</div>'
            ),
            templateClustererIcon: this.props.ymaps.templateLayoutFactory.createClass(
                '<div style="color: #FFFFFF; font-weight: bold; font-size: 26px; font-family: Acrobat; margin: auto; margin-bottom: 25px;">{{ properties.geoObjects.length }}</div>;'
            ),
        });
    }

    render() {
        return this.props.children({
            templateIcon: this.state.templateIcon,
            templateBalloon: this.state.templateBalloon,
            templateClustererIcon: this.state.templateClustererIcon,
            load: true,
            ymaps: this.props.ymaps,
        });
    }
}
