import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from '../HelperComponents/Pagination/Pagination';
import Rating from '../HelperComponents/Rating/Rating';
import ReactStars from 'react-stars';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import {Field, reduxForm} from 'redux-form';


import ClockIcon from '../../assets/image/time.png';
import AddIcon from '../../assets/image/add.png';
import NoReviewsItem from '../../assets/image/no_reviews.png';

import './Reviews.scss';
import Preloader from "../HelperComponents/Preloader/Preloader";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import RenderTextarea from "../HelperComponents/RenderTextarea/RenderTextarea";

import IconButton from "@material-ui/core/IconButton";
import CloseDialogIcon from "../../assets/image/Mask.png";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import {getRating} from "../../helpers/functions";
import {postSupplierReviews} from '../../actions/ordersActions';

export const ref = React.createRef();
const classes_def = {
    root: 'default_dialog_root',
    paper:'paper_custom_dialog review_dialog',
};
class Reviews extends Component {
    state = {
        open: false,
        ratingValue: 0,
    };



    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open,
            ratingValue: 0
        }));
        this.props.reset();
    };

    ratingChanged = (newRating) => {
        this.setState(({ratingValue}) => ({
            ratingValue: newRating
        }));
    };



    submitForm = data => {
        const { postSupplierReviews, idProvider, reset,changePage} = this.props;
        let obj = { "comment": data.response, "rating": this.state.ratingValue, "company": idProvider };

        postSupplierReviews(obj).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 201) {
                changePage({selected: 0});
                reset();
                this.setState({open: false, ratingValue: 0});
            }
        });
    };


    render(){
        const { supplier_reviews, changePage, activePage, handleSubmit, submitting, pristine} = this.props;
        const { ratingValue} = this.state;


        let countReviews = supplier_reviews.count;

        if(!supplier_reviews.results) return <Preloader/>;
        return (
            <div className="reviews" ref={ref}>
                <div className="title-block">
                    <div className="title-wrapper">Отзывы</div>
                    <div>
                        {
                            localStorage.token ?
                                <Button
                                    // component={Link}
                                    to=""
                                    disableRipple={true}
                                    classes={{
                                        root: 'add_btn'
                                    }}
                                    onClick={this.toggleDialog}
                                >
                                    Добавить
                                    <img src={AddIcon} alt="edit icon"/>
                                </Button>
                                :
                                null
                        }
                    </div>

                </div>


                {

                    supplier_reviews.results.length !== 0 ?
                        supplier_reviews.results.map(({ user, comment, date, response, rating }, i) => {
                            return (
                                <div className="review-item" key={"review"+ i }>
                                    <div className="time-block">
                                        <p className="date_wrapper">
                                            <img src={ClockIcon} alt="clock"/>
                                            { moment( date ).format( 'DD.MM.YYYY HH:mm' )}
                                        </p>
                                    </div>
                                    <div className="review_title">
                                        {user}
                                    </div>
                                    <Rating
                                        value={rating}
                                    />
                                    <div className="review_content">
                                        {comment}
                                    </div>
                                    {
                                        !!response
                                            ?
                                            <div className="descriptions">
                                                <p>Ответ компании: </p>
                                                <span>{response}</span>
                                            </div>
                                            :
                                            null

                                    }

                                </div>
                            )
                        })
                        :
                        <div className="no-reviews">
                            <div className="box-img">
                                <img src={NoReviewsItem} alt="edit icon"/>
                            </div>
                            <div className="title"> Пока нет ни одного отзыва</div>
                        </div>

                }

                {countReviews && countReviews > 10 ?
                    <Pagination
                        active={activePage}
                        pageItemsCount={10}
                        pageTotalCount={countReviews}
                        onChange={changePage}
                    />
                    :
                    null
                }

                <DialogComponent open={this.state.open} classes={classes_def}>
                    <div className="favorites-dialog">
                        <div className="title">Добавить отзыв</div>

                        {/*------------------------------ FORM -----------------------------*/}

                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <div className="block-info">
                                <div className="descriptions">Оценка компании
                                    <div className="container-rating">
                                        <ReactStars
                                            size={40}
                                            half={false}
                                            value={ratingValue}
                                            className="react-stars-wrapper"
                                            onChange={this.ratingChanged}
                                        />
                                        <span>{getRating(ratingValue)}</span>
                                    </div>
                                </div>
                                <div className="textarea-block">
                                    <span>Отзыв о компании</span>
                                    <Field name="response" component={RenderTextarea} placeholder="Ваше сообщение..." />
                                </div>
                            </div>
                            <div className="dialog-button-wrapper">
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={this.toggleDialog}
                                >
                                    ОТМЕНА
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    disabled={submitting || pristine || ratingValue === 0}
                                    formAction
                                >
                                    ДОБАВИТЬ
                                </DefaultButton>
                            </div>
                        </form>
                        {/*----------------------------- FORM -----------------------------*/}
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>

            </div>
        );
    }
}

Reviews = reduxForm ({
    form: 'login',
}) (Reviews);


function mapStateToProps(state) {
    return{
        // reviews: state.reviews
        orders: state.orders,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postSupplierReviews
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
