import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import LogoHeader from '../../assets/image/logo_header.png';
import DefaultButton from '../Buttons/DefaultButton/DefaultButton';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Notifications from '../Notifications/Notifications';
import LocationBlock from '../LocationBlock/LocationBlock';

import { getSearchParts, searchByVin } from '../../actions/userActions';
import {getBasket, getTotalCount} from '../../actions/basketActions'

import Arrow from '../../assets/image/arrow-right.png';
import Logo from '../../assets/image/logo.png';
import RadioOff from '../../assets/image/radiobox_off.png';
import RadioOn from '../../assets/image/radiobox_on.png';
import Search from '../../assets/image/search.png';
import BasketIcon from '../../assets/image/basket.png';

import './Head.scss';

class Head extends Component {
    state = {
        openDialog: false,
        selectedValue: 'b',
        classes: '',
        anchorEl: null,
        value: '',
        loading: false,
        holder: true
    };



    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
        if(!!this.props.user.profile.id) this.props.getBasket();
        else this.props.getTotalCount();
        // const { getProfile, profile } = this.props;
        // if (Object.keys(profile).length === 0) {
        //     getProfile();
        // }
    }

    componentDidUpdate (prevProps) {
        if(prevProps !== this.props){
            this.setState({ holder: true });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    // ********************* SEARCH ************************************

    onChange = (e) => {
        let value = e.target.value;
        this.setState({ value: value, holder: false });
    };

    onKeyDown = (e) => {
        if (e.keyCode === 13 ) {
            this.doSearch();
        }
    };

    searchPart = () => {
        this.doSearch();
    };

    doSearch = () => {
        const { value, selectedValue } = this.state;
        const { getSearchParts, searchByVin, history } = this.props;
        this.setState({ loading: true, holder: true });

        if ( value !== '' && value.trim().length !== 0) {
            if (selectedValue === 'b') {
                getSearchParts(value).then((res) => {
                    if(res.payload && res.payload.status && res.payload.status === 200) {
                        this.setState({ loading: false });
                        if(res.payload.data.genuine && res.payload.data.genuine.length === 1){
                            let productId = res.payload.data.genuine[0].id;
                            history.push(`/main/offers/${productId}`);
                        }else{
                            history.push("/main/search");
                        }
                    } else {
                        this.setState({ loading: false });
                    }
                });
                if(window.location.href.indexOf("search") === -1) {
                    history.push("/main/search");
                }
            } else {
                searchByVin(value).then((res) => {
                    if(res.payload && res.payload.data && res.payload.data.models && res.payload.data.models.length === 1) {
                        const path = res.payload.data.client;
                        this.setState({ loading: false });
                        history.push(`/main/catalogs/0/${path.catalog_code}/${encodeURIComponent(path.family)}/${encodeURIComponent(path.model)}/${path.ssd}/${res.payload.data.models[0].link}/${encodeURIComponent(path.mark)}`);
                    }
                    else {
                        this.setState({ loading: false });
                        history.push("/main/search");
                    }
                });
            }
        }
    };


    // ********************* END SEARCH ************************************

    handleScroll(e) {
        let letTimer;

        function timer() {
            letTimer = setTimeout(()=>(elem.classList.add('fixed-header-opacity')), 40)
        }

        function clearTimer() {
            clearTimeout(letTimer);
        }

        let elem = document.getElementById('header_container');
        let main_header = document.getElementById("main-header");
        if(window.scrollY > 168) {
            main_header.classList.add("fixed-header-wrapper");
            elem.classList.add('fixed-header');

            timer();
            setTimeout(()=>(clearTimer()), 50);
        } else {
            main_header.classList.remove("fixed-header-wrapper");
            elem.classList.remove('fixed-header');
            elem.classList.remove('fixed-header-opacity');
        }
    }

    //********************************  MENU  *************************

    logOut = () => {
        const { history } = this.props;
        this.setState({ anchorEl: null });
        localStorage.clear();
        window.location.reload();
        history.push('/main');
    };

    handleClickMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
        let elem = document.getElementById('header_container');
        elem.classList.add('fixed-header-menu-open');
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
        let elem = document.getElementById('header_container');
        elem.classList.remove('fixed-header-menu-open');
    };

    //******************************** END MENU  *************************

    handleChange = event => {
        this.setState({ selectedValue: event.target.value });
    };

    render(){
        const { location, profile, history, basket } = this.props;
        const { anchorEl, value, holder } = this.state;
        let headClasses = "header";
        let valueFromCatalog = '';
        if (location.pathname.split('/')[2] === "search" && !!location.pathname.split('/')[3] && holder) {
            valueFromCatalog = location.pathname.split('/')[3];
        }

        if(location.pathname === '/main') headClasses = "dashboard-head";
        return (
            <header>
                <div id="main-header" className={headClasses}>
                    <div id="header_container" className="container">
                        <div className="flex-center-btw header-wrapper">
                            <div className="flex-center">
                                <Link to="/main">
                                    <img src={LogoHeader} alt="logo header"/>
                                </Link>
                            </div>
                            <div className="app-head_options">
                                {!!localStorage.token ?
                                    <div className="app_head_options">

                                        <LocationBlock
                                            authorized={!!localStorage.token}
                                        />

                                        <Notifications
                                            location={location}
                                            history={history}
                                        />

                                        <div className="basket-btn">
                                            <Link
                                                to="/main/basket"
                                                className=""
                                            >
                                                <img src={BasketIcon} alt="Basket"/>
                                                {
                                                    (Object.keys(basket.basket_list).length === 0 || basket.basket_list.total_info.quantity === 0) ? null :
                                                        <span className="item-basket">{basket.basket_list.total_info.quantity}</span>
                                                }
                                            </Link>
                                        </div>


                                        <div>
                                            <Button
                                                aria-owns={anchorEl ? 'simple-menu' : undefined}
                                                aria-haspopup="true"
                                                onClick={this.handleClickMenu}
                                                classes={{root: 'button_header_wrapper', label: 'button_header_inner' }}
                                            >
                                                {profile.first_name}  {profile.last_name} &nbsp;&nbsp; <img src={Arrow} className="icon-arrow-down" alt="Arrow"/>
                                            </Button>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={this.handleCloseMenu}
                                                classes={{paper: 'button_header_paper' }}
                                                className="menu-item"
                                            >
                                                <MenuItem
                                                    onClick={()=>{this.props.history.push('/main/orders'); this.handleCloseMenu()}}
                                                >
                                                    Мои заказы
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={()=>{this.props.history.push('/main/favorites'); this.handleCloseMenu()}}
                                                >
                                                    Избранное
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={()=>{this.props.history.push('/main/profile'); this.handleCloseMenu()}}
                                                >
                                                    Настройки профиля
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={this.logOut}
                                                >
                                                    Выйти
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </div>
                                    :
                                    <Fragment>
                                        <LocationBlock/>

                                        <div className="basket-btn not-authorized">
                                            <Link
                                                to="/main/basket"
                                                className=""
                                            >
                                                <img src={BasketIcon} alt="Basket"/>
                                                {
                                                    (!!basket.total) ? <span className="item-basket">{basket.total}</span> : null
                                                }
                                            </Link>
                                        </div>

                                        <div className="account-wrapper">
                                            <button className="log-out" onClick={()=>this.props.history.push('/auth')}>
                                                Войти
                                            </button>
                                            <div>
                                                <DefaultButton
                                                    variant="outlined"
                                                    classes="cancel_btn"
                                                    onClick={()=>this.props.history.push('/auth/register')}

                                                >
                                                    Зарегистрироваться
                                                </DefaultButton>
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <div className="dashboard-head-content">
                            <div className="descriptions">
                                <img src={Logo} alt="logo"/>
                                Почувствуйте разницу в обслуживании любимого <br />
                                автомобиля - экономьте свое время и деньги <br />
                                вместе с нами
                            </div>

                            <div className="search_wrapper_dashboard">
                                <input
                                    type="text"
                                    placeholder="Поиск…"
                                    value={valueFromCatalog !== '' ? valueFromCatalog : value}
                                    onChange={this.onChange}
                                    onKeyDown={this.onKeyDown}
                                    autoComplete="off"
                                />
                                <button className="btn_search" onClick={this.searchPart}><img src={Search} alt="Search" /></button>
                            </div>

                            <div className="search-radio-box">
                                <FormControlLabel
                                    value="miss"
                                    control={
                                        <Radio
                                            checked={this.state.selectedValue === 'b'}
                                            onChange={this.handleChange}
                                            value="b"
                                            color="primary"
                                            checkedIcon={<img src={RadioOn} alt="radio on"/>}
                                            icon={<img src={RadioOff} alt="radio off"/>}
                                        />}
                                    classes={{
                                        root: 'custom_radio'
                                    }}
                                    label="По номеру детали"
                                />
                                <FormControlLabel
                                    value="miss"
                                    control={
                                        <Radio
                                            checked={this.state.selectedValue === 'a'}
                                            onChange={this.handleChange}
                                            value="a"
                                            color="primary"
                                            checkedIcon={<img src={RadioOn} alt="radio on"/>}
                                            icon={<img src={RadioOff} alt="radio off"/>}
                                        />}
                                    classes={{
                                        root: 'custom_radio'
                                    }}
                                    label="По VIN-номеру"
                                />
                            </div>

                        </div>
                        <div className="flex-center-fixed">
                            <Link to="/main">
                                <img src={LogoHeader} alt="logo header"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

function mapStateToProps(state) {
    return{
        user: state.user,
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getSearchParts,
        getBasket,
        getTotalCount,
        searchByVin
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Head));

