import React, {Component, Fragment} from 'react';
import {reduxForm} from "redux-form";


class DialogComponentForm extends Component {

    render () {
        const {children, onSubmitAction, handleSubmit} = this.props;
        return (
            <Fragment>
                <form className="" onSubmit={handleSubmit(onSubmitAction)}>
                    {children}
                </form>
            </Fragment>
        );
    }
}

DialogComponentForm = reduxForm({
    // enableReinitialize: true,
})(DialogComponentForm);

// function mapStateToProps(state) {
//     return{
//         initialValues: {
//             // obj_comment: state.user.objProduct.comment || 'in case..'
//         }
//     }
// }

// export default connect(null, mapStateToProps)(DialogComponentForm);

export default DialogComponentForm;
