import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { BasketContext } from './BasketPage';

import DialogComponent from '../HelperComponents/DialogComponent/DialogComponent';
import Rating from '../HelperComponents/Rating/Rating';
import Loader from '../HelperComponents/Loader/Loader';
import ModalMap from './DialogModalRender';
import BasketOffers from './BasketOffers';
import location from '../../assets/image/location_basket.png';

const classes = {
    root: 'favorites_dialog_root',
    paper:'paper_custom_dialog',
}

class BasketOrders extends Component{
  state = {
    count: this.props.orders.quantity_order,
    price: this.props.orders.order_price,
    load: false
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.load !== this.props.load){
      if(!this.props.load) this.setState({load: false});
    }
  }

  componentWillUnmount = () => {
    this.setState({load: false})
  }

  updateData = (newCount, newPrice) => {
    const {count, price} = this.state;
    if(newCount === 'update') this.props.updateData('update');
    this.setState({count: count + newCount, price: price + newPrice});
    this.props.updateData(newCount, newPrice);
  }

  loader = () => {
    this.setState({load: true});
    this.props.loader(this.props.orders.id);
  }

  itemBuy = (id) => {
    if(this.props.auth) this.loader();
    this.props.buy(id);
  }

  render(){
    const {id, user_id, company, address, web_site, total_comments, company_rating, offers} = this.props.orders;
    const {count, price} = this.state;
    return(
        <div className="basket-items">
          {(this.state.load) ? <Loader size={80} thickness={2.5}/> : null}
            <TitleBasketItem id={id} company={company} address={address} site={web_site} company_id={user_id}
                                comment={total_comments} rating={company_rating}/>
            {
                offers.map((element, index) => {
                    return <BasketOffers key={index} offer={element} updateData={this.updateData} localUpdate={this.props.localUpdate} loader={this.loader}/>
                })
            }
            <FooterBasket id={id} count={count} price={price} buy={this.itemBuy}/>
        </div>
    )
  }
}

class TitleBasketItem extends Component{
    state = {
        open_modal: false
    }

    stateModal = () => this.setState(prevState => ({open_modal: !prevState.open_modal}));

    render(){
        const { address, company_id} = this.props;
        return(
            <div className="item-name">
                <DialogComponent open={this.state.open_modal} close={this.stateModal} classes={classes} style={{height:'575px'}}>
                    <ModalMap id={company_id} address={address} onClose={this.stateModal} />
                </DialogComponent>
                <Link to={`/main/supplier-information/${company_id}`} className="name">
                    {this.props.company}
                </Link>
                <div className="reviews-wrapper">
                    <Rating value={this.props.rating}/>
                    <div className="commends">
                        {this.props.comment} отзыв{this.props.comment === 1 ? null : this.props.comment > 1 && this.props.comment < 5 ? "a" : "ов"}
                    </div>
                </div>
                <div className="address-block" onClick={this.stateModal}>
                    <img className="location" src={location} alt="location"/>
                    <span className="address-link">{this.props.address}</span>
                </div>
            </div>
        )
    }
}

const FooterBasket = ({id, count, price, buy}) => {
  const world = (count > 1) ? 'товара' : 'товар'
    return(
      <div>
        <div className="price-wrapper">
          <span>Всего: {count} {world}</span>
          <div>{price} <span>₽</span></div>
        </div>
        <div className="btn-wrapper-item">
            <button className="green-btn" onClick={() => buy(id)}>КУПИТЬ</button>
        </div>
      </div>
    )
}

export default BasketOrders;
