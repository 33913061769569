import * as types from './constants.jsx';

//user actions

export function getOrders(arr) {
    return {
        type: types.GET_ORDERS,
        payload: {
            client: 'default',
            request: {
                url: `/clientorders${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`,
                method: "get"
            }
        }
    };
}

export function getOrder(id) {
    return {
        type: types.GET_ORDER,
        payload: {
            client: 'default',
            request: {
                url: `/clientorder/${id}`,
                method: "get"
            }
        }
    };
}

export function patchInfo(data) {
    return {
        type: types.CHANGE_INFO,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/totalinfo/`,
                method: "patch",
                data
            }
        }
    };
}

export function getSupplierInfo(id) {
    return {
        type: types.GET_SUPPLIER_INFO,
        payload: {
            client: 'default',
            request: {
                url: `/producer/${id}`,
                method: "get",
            }
        }
    };
}

export function getSupplierReviews(id, page) {
    return {
        type: types.GET_SUPPLIER_REVIEWS,
        payload: {
            client: 'default',
            request: {
                url: `/producer/review/${id}?page=${page}`,
                method: "get",
            }
        }
    };
}

export function postSupplierReviews(data) {
    return {
        type: types.POST_SUPPLIER_REVIEWS,
        payload: {
            client: 'default',
            request: {
                url: `/reviews/`,
                method: "post",
                data
            }
        }
    };
}

export function cancelOrder(data, id) {
    return {
        type: types.CANCEL_ORDER,
        payload: {
            client: 'default',
            request: {
                url: `/clientorder/${id}`,
                method: "patch",
                data
            }
        }
    };
}

export function postOrderComment(data) {
    return {
        type: types.POST_ORDER_COMMENT,
        payload: {
            client: 'default',
            request: {
                url: `/orders/comments/`,
                method: "post",
                data
            }
        }
    };
}
