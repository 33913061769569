import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import BackLink from '../BackLink/BackLink';
import DashboardFooter from '../Dashboard/DashboardFooter/DashboardFooter';
import logo_blue from '../../assets/image/logo_blue.png';
import logotype_blue from '../../assets/image/logotype_blue.png';

import './AboutProject.scss';


class AboutProject extends Component {
    render(){
        return (
            <main className="about_project">
                <div className="container">
                    <div className="title-block">
                        <BackLink to="/main"/>
                        <h1>О проекте</h1>
                    </div>
                    <div className="about_project_wrapper">
                        <div className="text_info">
                            <b>MEXCAR.RU</b> – это маркетплейс автозапчастей, который объединяет конечных потребителей с реальными продавцами, что обеспечивает максимальную прозрачность и выгоду каждому участнику рынка.
                            <br/>
                            <br/>
                            Покупатели получают низкие цены напрямую от поставщиков, без посредников, гарантированную информацию о наличии деталей в онлайн режиме, удобный и интуитивно понятный интерфейс, фильтрацию и режим отображения «на карте» с указанием цен для каждой из геометок.
                        </div>
                        <div className="logo_wrapper_block">
                            <div><img src={logo_blue} alt="logo_blue"/></div>
                            <div> <img src={logotype_blue} alt="logotype_blue"/></div>
                        </div>
                    </div>

                </div>
                <DashboardFooter/>

            </main>
        );
    }
}

function mapStateToProps(state) {
    return{
        //name: state.name
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        //login
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutProject);