import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import Preloader from "../../HelperComponents/Preloader/Preloader";

import { getCatalogModel } from "../../../actions/catalogActions";

import './ChoosingFamily.scss';

class ChoosingFamily extends Component {

    state ={
        loading: true
    };

    componentDidMount() {
        const { getCatalogModel, match:{ params }} = this.props;
        const id = params.catalog_code;
        const type = params.tab;
        getCatalogModel(id, type).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200 ) {
                this.setState({ loading: false });
            }
        })
    }

    render(){
        const { match:{ params }, catalog:{ families }} = this.props;
        const { loading } = this.state;
        const id = params.catalog_code;
        const type = params.tab;
        let varChar = '';

        if (loading) return <Preloader/>;
        const name = families.catalog.name;

        return (
            <div className="catalog-first-page" >
                <div className="catalog-hierarchy">
                    <Link to="/main" className="catalog-link">Каталог</Link> &nbsp;&nbsp;/&nbsp;&nbsp; {name}
                </div>
                <div className="title-wrapper">Выберите серию</div>
                {name !== 'Audi' ?
                    <div className={`${Object.keys(families.families).length < 50 ? "catalog-fp" : "catalog-fp-big"}`}>
                        {Object.keys(families.families).map((model, i) => {
                            const encodedModel = encodeURIComponent(model);
                            if(model.charAt(0) !== varChar){
                                varChar = model.charAt(0);
                                return (
                                    <Link className="catalog-info" to={`/main/catalogs/${type}/${id}/${encodedModel}`} key={i}>
                                        <p>{model}</p>
                                        <div className="letter">{model.charAt(0)}</div>
                                    </Link>
                                )
                            }else{
                                return (
                                    <Link className="catalog-info" to={`/main/catalogs/${type}/${id}/${encodedModel}`} key={i}>
                                        <p>{model}</p>
                                    </Link>
                                )
                            }
                        })
                        }
                    </div>
                    :
                    <div className="catalog-fp-medium">
                        {Object.keys(families.families).map((model, i) => {
                            const encodedModel = encodeURIComponent(model);
                            if(model.replace(name, '').charAt(1) !== varChar){
                                varChar = model.replace(name, '').charAt(1);
                                return (
                                    <Link className="catalog-info" to={`/main/catalogs/${type}/${id}/${encodedModel}`} key={i}>
                                        <p>{model.replace(name, '')}</p>
                                        <div className="letter">{model.replace(name, '').charAt(1)}</div>
                                    </Link>
                                )
                            }else{
                                return (
                                    <Link className="catalog-info" to={`/main/catalogs/${type}/${id}/${encodedModel}`} key={i}>
                                        <p>{model.replace(name, '')}</p>
                                    </Link>
                                )
                            }
                        })
                        }
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        catalog: state.catalog
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCatalogModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoosingFamily);