import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import './DialogComponent.scss';

const classes_def = {
    root: 'default_dialog_root',
    paper:'paper_custom_dialog',
};

const DialogComponent = ({open, onClose, children, classes = classes_def, style, fullWidth, maxWidth}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={classes}
            scroll={'body'}
            style={style}
            fullWidth = {fullWidth}
            maxWidth = {maxWidth}
            paper="true"
        >
            <div className="dialog_wrapper">
                {children}
            </div>
        </Dialog>
    );
};

export default DialogComponent;
