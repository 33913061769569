import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import {ContextModalFavorite} from '../OffersPage/TableOffers/TableOffers';

import { postNewChosenProduct, getListnameChosenProducts, deleteOfferFromFavorite } from '../../actions/userActions';

import Mask from '../../assets/image/Mask.png'
import main from '../../assets/image/Group 9.png'
import additional from '../../assets/image/Group 8.png'
import cheked from '../../assets/image/Path.png'

import './ModalFavorites.scss';

class ModalFavorites extends Component{

  render(){
    const {chosen} = this.props;
    return(
        <ContextModalFavorite.Consumer>
        {
          (value) => (
            (!chosen) ? <ModalAddFavorites {...this.props} /> : <ModalDeleteFavorites  {...this.props} deleteChosen={value}/>
          )
        }
        </ContextModalFavorite.Consumer>
    );
  }
}

const ItemList = ({data, chosenId, chosen, count}) => {
  const {id, name} = data;
  return(
    <div className="item-list-favorites" onClick={() => chosen(id)}>
      {(id === chosenId) ? <span><img src={cheked} alt="cheked" /></span> : null }
      {(name === 'Общий список') ? <img src={main} alt="main" /> : <img src={additional} alt="additional" />}
      <div className="name-list-favorites">
        <h4>{name}</h4>
        <p>
          {(count === 0) ? 'Пока нет ни одного товара' : `${count} товара`}
        </p>
      </div>
    </div>
  );
}

class ModalAddFavorites extends Component{
  state = {
    chosenId: null,
    comment: '',
    error_value: false
  };

  componentDidMount = () => {
    this.setState({comment: ''})
    // this.getData();
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.open !== this.props.open && this.props.open) this.getData();
  }

  getData = () => {
    const {users: {profile}, getListnameChosenProducts} = this.props;
    if(!!profile.id){
      getListnameChosenProducts();
    }
  };

  chosenList = (id) => this.setState({chosenId: id});

  handlerComment = (e) => {
    if(e.target.value.length > 127) this.setState({error_value: true});
    else this.setState({comment: e.target.value, error_value: false});
  }

  addProductInList = () => {
    const {users: {favorite_lists}, id, postNewChosenProduct, stateModal} = this.props;
    const {chosenId, comment} = this.state;
    const  data = {'list_name_id': (!!chosenId) ? chosenId : favorite_lists[0].id, 'comment': comment};
    postNewChosenProduct(id, data);
    stateModal();
  }

  render(){
    const {stateModal, users: {favorite_lists}, open} = this.props;
    const classes = {
        root: 'favorites_dialog_root',
        paper:'paper_custom_dialog'
    }
    return(
      <DialogComponent open={open} onClose={stateModal} classes={classes} style={{height: '735px'}}>
        <div className="modal-favorites">
          <header>
            <span onClick={stateModal}><img src={Mask} alt="close" /></span>
            <h1>Добавить в избранное</h1>
            <h4>Выберите список избранного для данного товара.</h4>
          </header>
          <div className="main-block">
            <div className="list-favourites scrollbar scrollbar-primary">
            {
              favorite_lists.map((value, index) => {
                return <ItemList key={index} data={value} count={value.total} chosenId={this.state.chosenId} chosen={this.chosenList}/>
              })
            }
            </div>
            <div className="comment-favourites">
              <span>Комментарий</span>
              {this.state.error_value ? <span className="error-value">Превышено допустимое количество символов</span> : null}
              <textarea className="comments" type="text" value={this.state.comment} placeholder="Ваше сообщение..." onChange={(e) => this.handlerComment(e)}/>
            </div>
          </div>
          <footer>
            <DefaultButton
                variant="outlined"
                classes="cancel_btn"
                onClick={stateModal}
            >Отмена</DefaultButton>
            <DefaultButton
                variant="outlined"
                classes="default_button_contained"
                onClick={this.addProductInList}
            >
             Добавить
            </DefaultButton>
          </footer>
        </div>
      </DialogComponent>
    )
  }
}

class ModalDeleteFavorites extends Component{
  deleteItem = () => {
    this.props.deleteOfferFromFavorite(this.props.id);
    if(this.props.type === 'offers_list') this.props.deleteChosen(this.props.id);
    this.props.stateModal();
  }

  render(){
    const {open, stateModal} = this.props;
    const classes = {
        root: 'favorites_dialog_root',
        paper:'paper_custom_dialog'
    }
    return(
      <DialogComponent open={open} onClose={stateModal} classes={classes} style={{height: '365px'}}>
      <div className="dialog-delete-wrapper">
        <span className="close" onClick={stateModal}><img src={Mask} alt="close" /></span>
          <div className="title">Удалить товар</div>
          <div className="descriptions">Вы уверены?</div>
          <div className="dialog-button-wrapper">
              <DefaultButton
                  variant="outlined"
                  classes="cancel_btn"
                  onClick={stateModal}
              >
                  ОТМЕНА
              </DefaultButton>
              <DefaultButton
                  variant="contained"
                  onClick = {this.deleteItem}
              >
                  УДАЛИТЬ
              </DefaultButton>

          </div>
      </div>
      </DialogComponent>
    )
  }
}

function mapStateToProps(state) {
    return{
        users: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postNewChosenProduct,
        getListnameChosenProducts,
        deleteOfferFromFavorite
    }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ModalFavorites);

//export const ModalDeleteFavorites connect(mapStateToProps,mapDispatchToProps)(ModalDeleteFavorites);
