import React, {Component, Fragment} from "react";
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from "react-redux";

import Dashboard from '../../components/Dashboard/Dashboard';
import SearchPage from '../../components/SearchPage/SearchPage';
import ChoosingFamily from '../../components/Catalog/ChoosingFamily/ChoosingFamily';
import ChoosingModification from '../../components/Catalog/ChoosingModification/ChoosingModification';
import ListOfModels from '../../components/Catalog/ListOfModels/ListOfModels';
import PartsOfCar from '../../components/Catalog/PartsOfCar/PartsOfCar';
import SpecificPart from '../../components/Catalog/SpecificPart/SpecificPart';
import OffersPage from '../../components/OffersPage/OffersPage';
import Orders from '../../components/Orders/Orders';
import OrderDetail from '../../components/Orders/OrderDetail/OrderDetail';
import Profile from '../../components/Profile/Profile';
import CustomerPage from '../../components/CustomerPage/CustomerPage';
import AboutProject from '../../components/AboutProject/AboutProject';
import ContactDetails from '../../components/ContactDetails/ContactDetails';
import FavoritesPage from '../../components/FavoritesPage/FavoritesPage';
import SupplierInformation from '../../components/SupplierInformation/SupplierInformation';
import NotFound from '../../components/NotFound/NotFound';

import BasketPage from '../../components/BasketPage/BasketPage';
import Head from '../../components/Head/Head';

/*Test page */
import ShowMap from '../../components/ShowMap/ShowMap';

import Loader from '../../components/HelperComponents/Loader/Loader';

import {getProfile} from "../../actions/userActions";

class Container extends Component {

    componentDidMount() {
        const {getProfile, history} = this.props;
        if (localStorage.token) {
            getProfile().then(res => {
                if (res.error && res.error.response.status === 401) {
                    localStorage.clear();
                    history.push('/auth');
                }
            })
        }
    }
    render() {
        const {match, location, user: {profile}} = this.props;
        if (!!localStorage.token && !profile.first_name) return <div className="preloader_full_wrapper"><Loader/></div>;
        return (
            <Fragment>
                <Head
                    location={location}
                    profile={profile}
                />
                <Switch>
                    <Route path={match.url} exact component={Dashboard}/>
                    <Route path={`${match.url}/show-map`} component={ShowMap}/>
                    <Route path={`${match.url}/search/`} exact component={SearchPage}/>
                    <Route path={`${match.url}/search/:article`} exact component={SearchPage}/>
                    <Route path={`${match.url}/orders`} component={Orders}/>
                    <Route path={`${match.url}/order/:id`} exact component={OrderDetail}/>
                    <Route path={`${match.url}/offers/:articul`} component={OffersPage}/>
                    <Route path={`${match.url}/customer`} component={CustomerPage}/>
                    <Route path={`${match.url}/contact-details`} component={ContactDetails}/>
                    <Route path={`${match.url}/basket`} component={BasketPage}/>
                    <Route path={`${match.url}/about-project`} component={AboutProject}/>
                    <Route path={`${match.url}/profile`} component={Profile}/>
                    <Route path={`${match.url}/favorites`} component={FavoritesPage}/>
                    <Route path={`${match.url}/supplier-information/:idProvider`} component={SupplierInformation}/>

                    {/*Catalog's routes*/}
                    <Route path={`${match.url}/catalogs/:tab/:catalog_code`} exact component={ChoosingFamily}/>
                    <Route path={`${match.url}/catalogs/:tab/:catalog_code/:seria`} exact component={ChoosingModification}/>
                    <Route path={`${match.url}/catalogs/:tab/:catalog_code/:seria/:modifModel`} exact component={ListOfModels}/>
                    <Route path={`${match.url}/catalogs/:tab/:catalog_code/:seria/:modifModel/:ssd/:modelLink/:name`} exact component={PartsOfCar}/>
                    <Route path={`${match.url}/catalogs/:tab/:catalog_code/:seria/:modifModel/:ssd/:modelLink/:name/:group`} exact component={SpecificPart}/>

                    <Route path={`${match.url}/no-match`} component={NotFound}/>
                    <Route render={() => <Redirect to="/main/no-match" push/>}/>
                </Switch>
            </Fragment>

        );
    }
}

const mapStateToProps = ({user}) => {
    return {
        user
    };
};

const mapDispatchToProps = {
    getProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
