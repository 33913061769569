import React from 'react';
import Collapsible from 'react-collapsible';

import './Collapsible.scss';

const RenderCollapse = ({data, id, params, children, state, onCollapseClick}) => {
    const [ key, obj ] = data;
    const { valueSearch, openedItem, previousOpenedItem, oldOpenedItem} = state;
    const isOpen = valueSearch !== '' || openedItem === obj.name || previousOpenedItem === obj.name || oldOpenedItem === obj.name;
    const classNames = Object.keys(obj.data).length > 0 ? "Collapsible" : "leaf-style";
    return (
        <Collapsible
            id={key}
            trigger={obj.name}
            key={id}
            open={isOpen}
            onOpening={() => onCollapseClick(params.join('->'), obj.leaf, obj.data, obj.name)}
            onClosing={() => onCollapseClick(params.join('->'), obj.leaf, obj.data, obj.name)}
            classParentString={classNames}
        >
            {Object.keys(obj.data).length > 0 ?
                Object.entries(obj.data).map((treeNext, idTreeNext) => (
                    <RenderCollapse
                        key={idTreeNext}
                        state={state}
                        data={treeNext}
                        id={idTreeNext}
                        params={[...params, treeNext[0]]}
                        onCollapseClick={onCollapseClick}
                    />
                ))
                : null
            }
        </Collapsible>
    );
};

export default RenderCollapse;