import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './Loader.scss';

const Loader = (props) => {
    const { size = 50, thickness = 3.6 } = props;
    return (
        <div className="loader_wrapper">
            <CircularProgress size={size} thickness={thickness}/>
        </div>
    );
};

export default Loader;