import React from 'react';
import './NotFound.scss';

const NotFound = ({text = 'Страница не найдена'}) => {
    return (
        <div className="page_not_found">
            <h1>{text}</h1>
        </div>
    );
};

export default NotFound;